import {Pipe} from '@angular/core';
import {EnumToNameModelPipe} from '../model/pipes/enum-to-name.model.pipe';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'EnumToNamePipe'
})
export class EnumToNamePipe extends EnumToNameModelPipe {
  constructor(public translateService: TranslateService) {
    super([], translateService);
  }

  transform(value: any, enumArray: {}[]): any {
    this.enumArray = enumArray;
    return super.transform(value);
  }
}
