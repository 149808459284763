import {Component, ComponentFactoryResolver, EventEmitter, Input, OnInit, Optional, Output, Self} from '@angular/core';
import {ControlValueAccessor, NgControl} from '@angular/forms';

import {hasRequiredField} from '../input-field/input-field.component';
import {ModalService} from '../../../core/services/modal.service';
import {AdItem} from '../../model/ad-item';


@Component({
  selector: 'app-input-modal-form',
  templateUrl: './input-modal-form.component.html',
  styleUrls: ['./input-modal-form.component.scss']
})
export class InputModalFormComponent implements ControlValueAccessor, OnInit {

  @Input() cssClass = '';
  @Input() id = '';
  @Input() label = '';
  @Input() placeholder = '';
  @Input() iconClass = null;
  @Input() disable = false;
  @Input() selectData = null;
  /* Chave do objeto correspondente a view do input */
  @Input() objectKey = 'name';
  @Input() customErrorMessage = null;

  /*Referencia ao a classe que quer carregar*/
  @Input() selector: AdItem = null;
  @Input() pipe = {
    transform: (data) => {
      return data[this.objectKey];
    }
  };
  isRequired = false;
  control;
  componentRef;
  readOnly = true;
  disabled = false;
  value = '';
  @Output() iconClickEvent = new EventEmitter();
  errorMessages: any;


  // Usado para mostrar se o formulário possui erro ou não
  @Input() formSubmitted = false;

  constructor(
    @Self() @Optional() public controlDirective: NgControl,
    private resolver: ComponentFactoryResolver,
    private modalAlertService: ModalService) {
    controlDirective.valueAccessor = this;
  }

  ngOnInit() {
    if (this.controlDirective.control) {
      this.control = this.controlDirective.control;
    } else {
      throw new Error('Control não encontrado, deve ser passado um FormControl e não um FormGroup');
    }

    this.control.valueChanges.subscribe(data => {
      this.value = data ? this.pipe.transform(data) : '';
    });
    this.isRequired = hasRequiredField(this.control);


    const factory = this.resolver.resolveComponentFactory(this.selector.component);
    if (factory) {
      this.componentRef = factory;
    } else {
      throw new Error('Componente selecionado não existe');
    }

    // quando tem qualquer mudança de status verifico se tem campo obrigatorio para trocar a label
    this.control.statusChanges.subscribe(() => {
      this.isRequired = hasRequiredField(this.control);
    });
  }

  onChangeCb: (_: any) => void = () => {
  };
  onTouchedCb: (_: any) => void = () => {
  };

  writeValue(v: any): void {
    this.value = v ? this.pipe.transform(v) : '';

  }

  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  openModal() {
    if (this.selectData) {
    }
    this.modalAlertService.modalGeneric(this.componentRef.componentType, true, 'xl', '', 'static',
      [
        {index: 'data', value: this.control.value}
      ], data => {
        this.onChangeCb(data);
      });
  }

}
