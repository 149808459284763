<div class="backdrop" *ngIf="switchCompanyService.show | async">
  <div class="switch-company-content">
    <button type="button" class="close" aria-label="Close" (click)="switchCompanyService.hideService()">
      <app-feather-icons [icon]="'x'"></app-feather-icons>
    </button>
    <!-- HEADER -->
    <div class="switch-company-header" role="heading">

      <div class="d-flex justify-content-sm-between align-items-center w-100">
        <form class="w-100" (ngSubmit)="filterCompany()">
          <div class="form-inline position-relative w-100">
            <input class="form-control form-control-lg w-100" type="text"
                   [formControl]="formCompanyFilter"/>
            <button type="submit" class="btn btn-primary  position-absolute"
                    style="right: .4rem;top: auto;" (click)="filterCompany()">
              <span class="d-none d-sm-block">{{'Buscar por empresa'|translate}}</span><i
              class="ft-search d-block d-sm-none"></i>
            </button>


          </div>
        </form>
      </div>

    </div>
    <!-- GROUP CONTENT -->
    <div class="group-content">
      <div class="group-card card" *ngFor="let group of renderedGroupList"
           [ngClass]="{'active': activeGroupId == group.id}" (click)="onChangeGroup(group.id)">
        <div class="group-card-img">
          <img class="rounded-circle mr-1 avatar" (error)="error(group)"
               [src]="group?.logoUrl!=null?group?.logoUrl:'assets/img/images/grupo_cliente.png'"
               height="32" width="32"/>
        </div>
        <div class="group-card-info font-medium-1">{{group.nome}}</div>
      </div>

    </div>
    <!-- COMPANY CONTENT -->
    <div class="company-content"  >
      <div class="company-card" (click)="changeCompany(company)" *ngFor="let company of renderedCompaniesList">
        <p class="company-name mb-0">{{company.razaoSocial}}</p>
        <p class="company-code text-muted font-small-3 m-0">{{company.tin}}</p>
      </div>

    </div>
  </div>
</div>
