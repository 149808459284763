import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {FilterPipe} from './filter.pipe';
import {SearchPipe} from './search.pipe';
import {ShortNamePipe} from './short-name.pipe';
import {EnumToNamePipe} from './enum-to-name.pipe';

@NgModule({
  declarations:[FilterPipe, SearchPipe, ShortNamePipe, EnumToNamePipe],
  imports:[CommonModule],
  exports:[FilterPipe, SearchPipe, ShortNamePipe, EnumToNamePipe]
})

export class PipeModule{}
