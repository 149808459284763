<div class="table-container">
  <div *ngFor="let item of tableArray;let i = index" class="table-form">{{(fieldKey == ''?item:item[fieldKey])}}
   <button
     *ngIf="showDeleted"
     (click)="onDeleteitem(i)"
     class="close" data-dismiss="alert" type="button">
    <span>×</span>
  </button>
  </div>
</div>
