<div class="form-group {{cssClass}}">
	<label *ngIf="label.length>0" [for]="id">{{label | translate}}<span class="required" *ngIf="isRequired">*</span>
	</label>
	<app-icon-popover
			*ngIf="enablePopover"
			[contentpopover] = "contentpopover"
			[titlePopover] = "titlePopover"
	></app-icon-popover>
	<div class="input-group">
		<input
			[formControl]="control"
			class="form-control"
			[id]="id"
			type="text"
			[name]="name? name: id"
			currencyMask
			[options]="{precision:2, prefix:'R$'}"

			[placeholder]="placeholder|translate"
			[ngClass]="{ 'is-invalid': formSubmitted && control.invalid, 'is-valid': formSubmitted && !control.invalid }">
	</div>

	<app-form-error-messages *ngIf="showError" [control]="control" [customErrorMessage]="customErrorMessage"></app-form-error-messages>
</div>
