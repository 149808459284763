import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CrudService} from '../../../core/services/crud.service';
import {UtilsService} from '../../../core/services/utils.service';

@Component({
  selector: 'app-modal-address',
  templateUrl: './modal-address.component.html',
  styleUrls: ['./modal-address.component.scss']
})
export class ModalAddressComponent implements OnInit {

  @Input() data;

  form: FormGroup;
  // Usado para mostrar se o formulário possui erro ou não
  public formSubmitted = false;

  constructor(
    private utilsService: UtilsService,
    public activeModal: NgbActiveModal,
    private crudService: CrudService) {
  }

  ngOnInit() {
    this.createForm();
    this.loadData();
  }

  createForm() {
    this.form = new FormGroup({
      id: new FormControl(null),
      zipCode: new FormControl(null, [Validators.required]),
      street: new FormControl({value: null, disabled: true}, [Validators.required]),
      number: new FormControl(null, [Validators.required]),
      additionalInformation: new FormControl(null),
      neighborhood: new FormControl({value: null, disabled: true}, [Validators.required]),
      // state:  new FormControl(null, [Validators.required, this.utilsService.typeheadValidate.bind(this)]),
      // city:  new FormControl(null, [Validators.required, this.utilsService.typeheadValidate.bind(this)])
      state: new FormControl(null, [Validators.required]),
      cityId: new FormControl(null, [Validators.required]),
      city: new FormControl(null, [Validators.required]),
      cityName: new FormControl({value: null, disabled: true}),
      stateName: new FormControl({value: null, disabled: true}),
    });

  }

  loadData() {
    if (this.data) {
      this.form.patchValue(this.data);
      this.pathValue(this.data);
    }
  }

  onSubmit() {
    this.formSubmitted = true;
    if (this.form.invalid) {
      this.utilsService.markAsTouched(this.form);
      return;
    }
    this.activeModal.close(this.form.getRawValue());
  }

  searchZipCode() {
    if (this.form.get('zipCode').valid) {
      const zipCode = this.form.get('zipCode').value;
      this.crudService.get('cep/' + zipCode).subscribe(response => {
        this.pathValue(response);
      });
    }
  }


  pathValue(response) {
    this.form.get('state').patchValue(response['city']['state']);
    this.form.get('stateName').patchValue(response['city']['state']['name']);
    this.form.get('city').patchValue(response['city']);
    this.form.get('cityId').patchValue(response['cityId']);
    this.form.get('cityName').patchValue(response['city']['name']);
    this.form.get('street').patchValue(response['street']);
    this.form.get('neighborhood').patchValue(response['neighborhood']);
  }
}
