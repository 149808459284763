import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SortType} from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss', './../../../../assets/sass/libs/datatables.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DatatableComponent implements OnInit, OnDestroy {

  @ViewChild('datatable') datatable;
  @Input() page;
  @Input() rows;
  @Input() columns;
  @Input() reorderable = true;
  @Input() sortType = SortType.multi;
  /*multi ou single*/
  @Output() onSetPage = new EventEmitter<any>();
  @Output() onActivate = new EventEmitter<any>();
  @Output() onSort = new EventEmitter<any>();
  message = {emptyMessage: '', totalMessage: ''};
  viewWidth = null;
  interval = null;


  // Para mostrar ou não a action de edit
  @Input() showActions = false;
  @Input() actionLabel = 'Editar';


  constructor(
    private translateService: TranslateService
  ) {
    this.translateService.get('NENHUM RESULTADO').subscribe(response => this.message.emptyMessage = response);
    this.translateService.get('TOTAL').subscribe(response => this.message.totalMessage = response);
  }


  ngOnInit() {
  }

  setPage(pageInfo) {
    this.onSetPage.emit(pageInfo.offset);
  }

  setActivate(event) {
    if (this.showActions) {
      return;
    }
    // if (event.type == 'click') {
    //   event.cellElement.blur();
    //   this.onActivate.emit(event.row);
    // }
  }

  onEdit(row) {
    this.onActivate.emit(row);
  }


  setSort(event) {
    this.onSort.emit(event.sorts);
  }

  ngOnDestroy() {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    setTimeout(() => {
      // Recalcula as medidas da tabela
      this.datatable.onWindowResize();
      // Renderiza a nova tabela
      this.rows = [...this.rows];
    }, 500);
  }


}
