import {Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {FilterModalInterface} from '../../interfaces/filter-modal.interface';
import {ListAbstract} from '../../../core/abstracts/list.abstract';
import {FunctionsCallbackModalInterface} from '../../interfaces/functions-callback-modal.interface';


@Component({
  selector: 'app-select-list',
  templateUrl: './select-list.component.html',
  styleUrls: ['./select-list.component.scss', './../../../../assets/sass/libs/datatables.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelectListComponent extends ListAbstract implements OnInit {


  @Input() selected = null;
  selectedData = [];
  @Input() title = null;
  @Input() saveLabel = 'Selecionar';
  @Input() filterKeys: FilterModalInterface[] = null;
  @Input() functionsCallback: FunctionsCallbackModalInterface[] = null;
  @Input() columnsModel = [];
  @Input() resourcePath = '';
  messages = {};

  @ViewChild(DatatableComponent) table: DatatableComponent;


  constructor(public activeModal: NgbActiveModal,
              route: ActivatedRoute) {
    super(route);

  }

// estou dando overwrite porque não quero que sobreescreva meu bookmark das tabs
  createBookmark() {
  }

  destroyBookmark() {

  }

  ngOnInit() {
    this.createForm();
    this.translateService.get(['Sem dados', 'TOTAL']).subscribe(data => {
      this.messages = {
        emptyMessage: data['Sem dados'],
        totalMessage: data['TOTAL']
      };
    });
    this.path = this.resourcePath;
    this.columns = this.columnsModel;
    this.init();
  }

  onSelect({selected}) {
  }

  public onActivate(info: any) {

  }


  createForm() {


    this.form = new FormGroup({
      page: new FormControl(0),
      textSearch: new FormControl(),
    });
  }


  onSave() {
    this.activeModal.close(this.selectedData[0]);
  }

  pipeMap(data) {

    if (this.selected != null) {
      const index = data.data.rows.findIndex(value => {
        return value.id === this.selected.id;
      });
      if (typeof index !== 'undefined') {
        this.selectedData = [data.data.rows[index]];
      }
    }

    return data;
  }

}
