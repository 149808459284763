<div class="form-group {{cssClass}}">
  <label *ngIf="label.length>0" [attr.for]="id">{{label | translate}}<span class="required" *ngIf="isRequired">*</span>
  </label>
  <app-icon-popover
    *ngIf="enablePopover"
    [contentpopover] = "contentpopover"
    [titlePopover] = "titlePopover"
  ></app-icon-popover>
  <div>
    <select
      class="form-control"
      [id]="id"
      [disabled]="disable"
      [compareWith]="compareCustom ? compareCustom : compareFn"
      [formControl]="control"
      [ngClass]="{ 'is-invalid': formSubmitted && control.invalid, 'is-valid': formSubmitted && !control.invalid }">
      <option *ngIf="isRequired" [selected]="true" [ngValue]="null" hidden>{{'Selecione uma opção' | translate}}*</option>
      <option *ngIf="showNullOption && !isRequired" [selected]="true"
              [ngValue]="null">{{'Selecione uma opção' | translate}}</option>
      <ng-container *ngIf="valueKey">
        <option
          *ngFor="let item of selectList"
          [value]=" item[valueKey]">
          {{(optionKey ? item[optionKey] : item) | translate}}
        </option>
      </ng-container>
      <ng-container *ngIf="!valueKey">
        <option
          *ngFor="let item of selectList"
          [ngValue]=" item">
          {{(optionKey ? item[optionKey] : item) | translate}}
        </option>
      </ng-container>

    </select>
  </div>

  <app-form-error-messages *ngIf="showError" [control]="control" [customErrorMessage]="customErrorMessage"></app-form-error-messages>

</div>
