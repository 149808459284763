import {AfterContentInit, Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef} from '@angular/core';


import {ActionsEnum} from '../enums/actions.enum';
import {TargetResourceEnum} from '../enums/targetResourceEnum';
import {PermissionService} from '../services/permission.service';


// tslint:disable: max-line-length
/*
  O appPermission checa(através do permissionService) se o usuário tem permissão para fazer uma ou mais Actions em um TargetResource.
  Caso seja passado um array de Actions, é considerado que o usuário é permitido caso tenha pelo menos uma dessas Actions em suas permissões.
  O usuário também será permitido, para qualquer Action passada, caso ele possua a Action.ALL em suas permissões.
  É possível passar tanto strings como enums(Lembrando que para acessar enums no html você deve criar atributos referenciando os
    enums na classe do seu componente(Ex: action = Action;)).
  Exemplos:
  <div *appPermission="action.INDEX; target: targetResource.CUSTOMERS_REGISTRATION">
    Essa div só aparece caso o usuário tenha a permissão de all OU index para customers_registration
  </div>

  <div *appPermission="['READ', 'UPDATE]; target: 'CUSTOMERS_REGISTRATION'">
    Essa div só aparece caso o usuário tenha a permissão de all OU read OU update para customers_registration
  </div>

  <div *appPermission="'ALL'; target: targetResource.CUSTOMERS_REGISTRATION">
    Essa div só aparece caso o usuário somente tenha a permissão de all para customers_registration
  </div>
*/
@Directive({
  selector: '[appPermission]'
})
export class PermissionDirective implements AfterContentInit, OnChanges {
  private hasView = false;

  // @Input() appPermissionTarget: TargetResourceEnum;
  private _target: TargetResourceEnum;
  private _actions: ActionsEnum[]

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionService: PermissionService) {
  }

  @Input()
  set appPermissionTarget(value: TargetResourceEnum) {
    this._target = value;
  }

  @Input() set appPermission(actions: ActionsEnum | ActionsEnum[]) {
    this._actions = actions instanceof Array ? actions : [actions];
  }

  private checkPermissions() {
    const condition = this.permissionService.hasPermission(this._target, ...this._actions);
    if (condition && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!condition && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }

  ngAfterContentInit(): void {
    this.checkPermissions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.checkPermissions();
  }
}
