import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LayoutService} from '../services/layout.service';
import {Subscription} from 'rxjs';
import {ConfigService} from '../services/config.service';
import {FormControl} from '@angular/forms';
import {Router} from '@angular/router';
import {ModalService} from '../../core/services/modal.service';
import {AuthenticationService} from '../../core/authentication/authentication.service';
import {CrudService} from '../../core/services/crud.service';
import {environment} from '../../../environments/environment';
import {ActionsEnum} from '../enums/actions.enum';
import {TargetResourceEnum} from '../enums/targetResourceEnum';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  currentLang = 'en';
  selectedLanguageText = '';
  selectedLanguageFlag = '';

  languagesKeys;
  languagesValue;
  toggleClass = 'ft-maximize';
  // placement = 'bottom-right';
  // logoUrl = 'assets/img/logo.png';
  menuPosition = 'Side';
  isSmallScreen = false;
  protected innerWidth: any;
  searchOpenClass = '';
  transparentBGClass = '';
  hideSidebar = true;
  public isCollapsed = true;
  layoutSub: Subscription;
  configSub: Subscription;

  ActionsEnum = ActionsEnum;
  TargetResourceEnum = TargetResourceEnum;

  @ViewChild('search') searchElement: ElementRef;
  @ViewChildren('searchResults') searchResults: QueryList<any>;

  @Output()
  toggleHideSidebar = new EventEmitter<Object>();

  @Output()
  seachTextEmpty = new EventEmitter<boolean>();

  // listItems = [];
  // control = new UntypedFormControl();

  control = new FormControl();
  public config: any = {};

  isProduction = true;

  creditos;

  constructor(public translate: TranslateService,
    private layoutService: LayoutService,
    private router: Router,
    private configService: ConfigService, private cdr: ChangeDetectorRef,
    private modalService: ModalService,
    public authenticationService: AuthenticationService,
    private crudService: CrudService,
    private translateService: TranslateService) {

    // const browserLang: string = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : 'en');

    this.languagesKeys = Object.keys(environment.translate);
    this.languagesValue = Object.values(environment.translate);
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;

    this.layoutSub = layoutService.toggleSidebar$.subscribe(
      isShow => {
        this.hideSidebar = !isShow;
      });

    // para saber se mostro o botao de reload do login
    this.isProduction = environment.production;

    // Para setar o idioma padrao
    if (this.translateService.currentLang) {
      const indexLanguage = this.languagesKeys.findIndex(data => {
        return data === this.translateService.currentLang;
      });
      if (indexLanguage >= 0) {
        this.ChangeLanguage(translateService.currentLang, indexLanguage);
      }
    }

  }

  ngOnInit() {
    // this.listItems = LISTITEMS;
    this.creditos = this.getCreditos();
    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    } else {
      this.isSmallScreen = false;
    }
  }

  ngAfterViewInit() {

    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    })
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    } else {
      this.isSmallScreen = false;
    }
  }

  loadLayout() {

    if (this.config.layout.menuPosition && this.config.layout.menuPosition.toString().trim() != '') {
      this.menuPosition = this.config.layout.menuPosition;
    }

    // if (this.config.layout.variant === 'Light') {
    //   this.logoUrl = 'assets/img/logo-dark.png';
    // } else {
    //   this.logoUrl = 'assets/img/logo.png';
    // }

    if (this.config.layout.variant === 'Transparent') {
      this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
    } else {
      this.transparentBGClass = '';
    }

  }

  onSearchKey(event: any) {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.add('first-active-item');
    }

    if (event.target.value === '') {
      this.seachTextEmpty.emit(true);
    } else {
      this.seachTextEmpty.emit(false);
    }
  }

  removeActiveClass() {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.remove('first-active-item');
    }
  }

  onEscEvent() {
    this.control.setValue('');
    this.searchOpenClass = '';
    this.seachTextEmpty.emit(true);
  }

  onEnter() {
    if (this.searchResults && this.searchResults.length > 0) {
      const url = this.searchResults.first.url;
      if (url && url != '') {
        this.control.setValue('');
        this.searchOpenClass = '';
        this.router.navigate([url]);
        this.seachTextEmpty.emit(true);
      }
    }
  }

  redirectTo(value) {
    this.router.navigate([value]);
    this.seachTextEmpty.emit(true);
  }


  ChangeLanguage(language: string, indexOfLanguagesValue) {
    this.translate.use(language);
    this.selectedLanguageText = this.languagesValue[indexOfLanguagesValue];
    this.selectedLanguageFlag = `./assets/img/flags/${language}.png`;

    // if (language === 'en') {
    //   this.selectedLanguageText = 'English';
    //   this.selectedLanguageFlag = './assets/img/flags/us.png';
    // } else if (language === 'es') {
    //   this.selectedLanguageText = 'Spanish';
    //   this.selectedLanguageFlag = './assets/img/flags/es.png';
    // } else if (language === 'pt') {
    //   this.selectedLanguageText = 'Portuguese';
    //   this.selectedLanguageFlag = './assets/img/flags/pt.png';
    // } else if (language === 'de') {
    //   this.selectedLanguageText = 'German';
    //   this.selectedLanguageFlag = './assets/img/flags/de.png';
    // }
  }

  ToggleClass() {
    if (this.toggleClass === 'ft-maximize') {
      this.toggleClass = 'ft-minimize';
    } else {
      this.toggleClass = 'ft-maximize';
    }
  }

  toggleSearchOpenClass(display) {
    this.control.setValue('');
    if (display) {
      this.searchOpenClass = 'open';
      setTimeout(() => {
        this.searchElement.nativeElement.focus();
      }, 0);
    } else {
      this.searchOpenClass = '';
    }
    this.seachTextEmpty.emit(true);
  }

  Logout() {
    this.modalService.modalAlertControl('Deseja realmente sair?', 'Atenção!', 'Sim', 'Não', () => {
      this.authenticationService.signOut();
    });
  }

  error() {
    this.authenticationService.authDecoded.pictureUrl = null;
  }

  refresh() {
    this.authenticationService.forceRelogin();
  }



  toggleNotificationSidebar() {
    this.layoutService.toggleNotificationSidebar(true);
  }

  toggleSidebar() {
    this.layoutService.toggleSidebarSmallScreen(this.hideSidebar);
  }

  getCreditos(){
        const profileType = this.authenticationService.getProfileType();
        if(profileType == 'EMPRESA'){
          this.crudService.get('empresas-profile').subscribe((response:any) => {
             if (response) {
                    this.creditos = response.creditosQtd;
                    return response.creditos;
                  } else {
                    console.log('No creditos found');
                    this.creditos = null;
                  }
          });
        }

  }
}
