import {StorageAbstract} from './storage.abstract';
import {environment} from '../../../environments/environment';
import * as CryptoJS from 'crypto-js';

export class LocalStorage extends StorageAbstract {

  check(key: string): boolean {
    if (localStorage.getItem(this.buildKey(key))) {
      return true;
    }
    return false;
  }

  clear(): void {
    localStorage.clear();
  }

  decryptData(key: string, data: any): any {
    if (environment.production) {
      try {
        const bytes = CryptoJS.AES.decrypt(data, this.buildEncryptKey(key));
        if (bytes.toString()) {
          return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        }
        return data;
      } catch (e) {
        return {};
      }
    } else {
      return JSON.parse(data);
    }
  }

  encryptData(key: string, data: any): any {
    if (environment.production) {
      try {
        return CryptoJS.AES.encrypt(JSON.stringify(data), this.buildEncryptKey(key)).toString();
      } catch (e) {
        console.log(e);
      }
    } else {
      return JSON.stringify(data);
    }
  }

  get(key: string): any {
    if (this.check(key)) {
      return this.decryptData(key, localStorage.getItem(this.buildKey(key)));
    } else {
      throw new Error('No data for key ' + key);
    }
  }

  remove(key: string): any {
    const data = this.get(key);
    localStorage.removeItem(this.buildKey(key));
    return data;
  }

  set(key: string, data: any) {
    localStorage.setItem(this.buildKey(key), this.encryptData(key, data));
  }


}
