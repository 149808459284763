import {environment} from '../../../environments/environment';
import {StorageInterface} from './storage.interface';

export abstract class StorageAbstract implements StorageInterface {

  protected buildKey(key: string) {
    return environment.projectName + '_' + (environment.production ? 'production_' : 'development_') + key;

  }

  protected buildEncryptKey(key: string) {
    return environment.encryptionKey + this.buildKey(key);
  }

  abstract check(key: string): boolean;

  abstract clear(): void;

  abstract decryptData(key: string, data: any): any;

  abstract encryptData(key: string, data: any): any;

  abstract get(key: string): any;

  abstract remove(key: string): any;

  abstract set(key: string, data: any);


}
