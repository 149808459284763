import {Component, Input, OnInit} from '@angular/core';
import {InputControlService} from '../../services/input-control.service';

@Component({
  selector: 'app-form-error-messages',
  templateUrl: './form-error-messages.component.html',
  styleUrls: ['./form-error-messages.component.scss']
})
export class FormErrorMessagesComponent implements OnInit {
  @Input() control;
  @Input() customErrorMessage;
  @Input() formSubmitted = true;

  errorMessages;

  constructor(public inputControlService: InputControlService) {
  }

  ngOnInit() {
    this.errorMessages = this.inputControlService.getErrorMessages(this.customErrorMessage);
  }

}
