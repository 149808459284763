import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';


import {NgbDatePtParserFormatterService} from './ngb-date-pt-parser-formatter.service';
import {ToastService} from '../notification/toast/toast.service';
import {CrudService} from './crud.service';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(private http: HttpClient,
              private toastService: ToastService,
              private ngbDatePtParserFormatterService: NgbDatePtParserFormatterService,
              private crudService: CrudService) {
  }

  patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }

      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }

  checkArray(): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (!Array.isArray(c.value)) {
        return {checkArray: true};
      }
      return null;
    };
  }

  checkObject(): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (c.value == null || typeof c.value !== 'object' || (typeof c.value == 'object' && !c.value.hasOwnProperty('id'))) {
        return {checkObject: true};
      }
      return null;
    };
  }

  checkObjectAcceptEmptyString(): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (c.value == null || c.value == '') {
        return null;
      } else if (typeof c.value == 'undefined' || c.value == null || typeof c.value !== 'object' || (typeof c.value == 'object' && !c.value.hasOwnProperty('id'))) {
        return {'checkObjectAcceptEmptyString': true};
      }
      return null;
    };
  }

  typeheadValidate(control: FormControl) {
    const c = control.value;
    if (c) {
      if (typeof c !== 'object') {
        return {typeaheadValidator: true};
      }
      return null;
    }
    return null;
  }

  checkPhoneMask(value) {
    if (value.length <= 10) {
      return '(00) 0000-0000*';
    } else {
      return '(00) 00000-0000';
    }

  }

  checkFullName(control: FormControl): { [s: string]: boolean } {

    if (typeof control.value == 'string') {
      const splitString = control.value.split(' ');
      if (splitString.length == 1) {
        return {checkFullName: true};
      } else if (splitString[1].length == 0) {
        return {checkFullName: true};
      }
      return null;
    } else {
      return null;
    }
  }


  onChageCellPhones(event, index, cellPhonesMasks) {
    if (event) {
      event = event.split('-').join('').split('(').join('').split(')').join('');
      if (event.length <= 10) {
        cellPhonesMasks[index].next('(00)0000-0000*');
        // I've put an extra value here in order to have the same number of characters from the second-mask.
      } else if (event.length <= 11) {
        cellPhonesMasks[index].next('(00)00000-0000');
      }
    }
  }

  onChageCellPhone(phone, cellphoneMask) {
    if (phone) {
      phone = phone.split('-').join('').split('(').join('').split(')').join('');
      if (phone.length <= 10) {
        cellphoneMask.next('(00)0000-0000*');
        // I've put an extra value here in order to have the same number of characters from the second-mask.
      } else if (phone.length <= 11) {
        cellphoneMask.next('(00)00000-0000');
      }
    }
  }

  httpValidatorAcceptEmptyString(c: FormControl): { [key: string]: any } {
    if (c['value'] == null || c['value'] == '') {
      return null;
    } else if (c['value'] != null) {
      let url = c['value'];
      if (!url.includes('http\:\/\/') && !url.includes('https\:\/\/')) {
        return {httpValidatorAcceptEmptyString: true};
      }
    }
    return null;
  }

  httpValidator(c: FormControl): { [key: string]: any } {

    if (c['value'] != null) {
      let url = c['value'];
      if (!url.includes('http\:\/\/') && !url.includes('https\:\/\/')) {
        return {httpValidator: true};
      }
    }
    return null;
  }

  phoneNumberValidator(control: AbstractControl): { [s: string]: boolean } {
    const phone = control.value;
    if (phone) {
      if (phone.length == 10 || phone.length == 11) {
        return null;
      } else {
        return {phoneNumberValidator: true};
      }
    }
  }


  dateOrderCustomValidator(control: AbstractControl) {
    const startDate = control.value.startPeriod;
    const endDate = control.value.endPeriod;
    if (startDate && endDate) {
      if (this.ngbDatePtParserFormatterService.getDate(startDate) >
        this.ngbDatePtParserFormatterService.getDate(endDate)) {
        return {orderDateValidator: true};
      } else {
        return null;
      }
    } else {
      return null;
    }
  }


  checkCpf(cpf: string): Observable<any> {
    const params = new HttpParams().set('data', cpf);
    return this.http.get('public/resources/users_olde/check-cpf', {params: params});
    // valid
    // cpfRegistered
  }

  checkEmailUsed(email: string): Observable<boolean> {
    const params = new HttpParams().set('data', email);
    return this.http.get<boolean>('public/resources/users_olde/check-email', {params: params});
  }


  checkCnpj(cnpj: string): Observable<any> {
    const params = new HttpParams().set('data', cnpj);
    return this.http.get('public/resources/users_olde/check-cnpj', {params: params});
  }

  downloadFromS3(serverUrl) {
    // Pego a url do s3 pelo nosso servidor
    this.crudService.get(serverUrl).subscribe((data: any) => {
      this.crudService.getBlob(data.url).subscribe((downloaded) => {
        this.downLoadFile(downloaded.body, downloaded.body.type, data.fileName);
      });
    })
  }

  downLoadFile(data: any, type: string, fileName: string) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    const blob = new Blob([data], {type: type});
    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob

    // TODO - PERGUNTAR PRO POPPI
    // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //   window.navigator.msSaveOrOpenBlob(blob);
    //   return;
    // }
    // TODO END

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const url = window.URL.createObjectURL(blob);

    var link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);


    // const pwa = window.open(url);
    // if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
    //   alert('Please disable your Pop-up blocker and try again.');
    // }
  }

  // objectToArray(obj) {
  //   return Object.keys(obj).map(function (key) {
  //     debugger
  //     return obj[key];
  //   });
  // }


  TextSearchPipe(data) {
    return data.name;
  }


  /**
   * Marks all controls in a form group as touched
   * @param formGroup - The form group to touch
   */
  markAsTouched(formGroup: FormGroup) {
    (Object as any).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markAsTouched(control);
      }
    });
  }

  checkFormValidationErrors(formGroup, errorMessage) {
    this.markAsTouched(formGroup);

    if (!this.getFormValidationErrors(formGroup, errorMessage, false)) {
      this.toastService.sendDanger('Formulário incompleto!');
    }
  }

  private isObject(n) {
    if (n == null) {
      return false;
    }
    return Object.prototype.toString.call(n) === '[object Object]';
  }

  checkIfSomeControlsHasErrors(controlsKey = []): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      for (let i = 0; i < controlsKey.length; i++) {
        if (c.get(controlsKey[i]).invalid) {
          return {checkIfSomeControlsHasErrors: true};
        }
      }
      return null;
    };

  }


  private getFormValidationErrors(formGroup, errorMessage, hasError = false) {

    Object.keys(formGroup.controls).forEach(key => {
      if (!hasError) {
        const control = formGroup.get(key);
        const controlErrors: ValidationErrors = control.errors;
        if (controlErrors != null) {
          const full_path = this.getControlPath(control, '');
          if (typeof errorMessage[full_path] !== 'undefined') {

            hasError = true;

            if (this.isObject(errorMessage[full_path])) {
              Object.keys(controlErrors).forEach(keyError => {
                if (typeof errorMessage[full_path][keyError] !== 'undefined') {
                  this.toastService.sendDanger(errorMessage[full_path][keyError]);
                  return;
                }
              });
            } else {
              this.toastService.sendDanger(errorMessage[full_path]);
            }
          }

        }

        if (control.controls) {
          hasError = this.getFormValidationErrors(control, errorMessage, hasError);
        }
      } else {
        return;
      }
    });

    return hasError;
  }


  private getControlName(c: AbstractControl): string | null {
    if (!c.parent) {
      return null;
    }
    const formGroup = c.parent.controls;
    return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
  }

  private getControlPath(c: AbstractControl, path: string): string | null {
    path = this.getControlName(c) + path;

    if (c.parent && this.getControlName(c.parent)) {
      path = '.' + path;
      return this.getControlPath(c.parent, path);
    } else {
      return path;
    }
  }


  checkValidateUpload(event, maxFileSize = 20, allowedExtensions = ['png', 'jpg', 'jpeg', 'pdf', 'pdfA']) {
    if (!maxFileSize) {
      maxFileSize = 20;
    }
    if (event.target.files && event.target.files[0]) {

      const file = event.target.files[0];
      const fileName = file.name;
      const fileSize = file.size;
      const maxSizeMb = maxFileSize;
      const fileExtension = fileName.split('.').pop().toLowerCase();

      if (allowedExtensions.indexOf(fileExtension) > -1) {
        if ((fileSize / 1024 / 1024) < maxSizeMb) {
          return true;
        } else {
          this.toastService.sendDanger('Tamanho do arquivo inválido');
          return false;
        }
      } else {
        this.toastService.sendDanger('Formato do arquívo inválido');
        return false;
      }
    }
  }

  public formatToDatePicker(date: Date) {
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    };
  }

  // TODO reference: https://stackoverflow.com/questions/48308414/deep-copy-of-angular-reactive-form
  public cloneAbstractControl<T extends AbstractControl>(control: T): T {
    let newControl: T;

    if (control instanceof FormGroup) {
      const formGroup = new FormGroup({}, control.validator, control.asyncValidator);
      const controls = control.controls;

      Object.keys(controls).forEach(key => {
        formGroup.addControl(key, this.cloneAbstractControl(controls[key]));
      });

      newControl = formGroup as any;
    } else if (control instanceof FormArray) {
      const formArray = new FormArray([], control.validator, control.asyncValidator);

      control.controls.forEach(formControl => formArray.push(this.cloneAbstractControl(formControl)));

      newControl = formArray as any;
    } else if (control instanceof FormControl) {
      newControl = new FormControl(control.value, control.validator, control.asyncValidator) as any;
    } else {
      // newControl = new FormControl({}) as any;
      throw new Error('Error: unexpected control value');
    }

    if (control && control.disabled) {
      newControl.disable({emitEvent: false});
    }

    return newControl;
  }

  public patchValues(d: any, originalForm: AbstractControl, composition: string[] = []) {

    if (originalForm instanceof FormControl) {
      originalForm.patchValue(d);
      return;
    }

    for (const prop in d) {
      if (Object.prototype.hasOwnProperty.call(d, prop)) {
        const newComposition = [...composition, prop];
        const property = d[prop];

        if (property instanceof Object) {
          if (Array.isArray(property)) {
            if (property != null && property.length > 0) {
              const controlArray: FormArray = originalForm.get(newComposition) as FormArray;

              // TODO Verificar aqui porque não está populando.
              // TODO provavelmente o angular ainda não criou os formControls
              try {
                const mock: AbstractControl = this.cloneAbstractControl(controlArray.at(0));
                if (controlArray.length == 1) {
                  controlArray.removeAt(0);
                }
                property.forEach(p => {
                  const currentControl: AbstractControl = this.cloneAbstractControl(mock);
                  controlArray.push(currentControl);
                  this.patchValues(p, currentControl, []);
                });
              } catch (e) {
                // console.log(e);
                // console.log(controlArray);
                // console.log(property);
                // console.log(newComposition);
              }
            } else {
              // console.log("PROPRIEDADE VAAAAAAAAZIA")
            }
          } else {
            const control: AbstractControl = originalForm.get(newComposition);
            if (control instanceof FormControl) {
              if (property) {
                control.setValue(property, {emitEvent: false});
              }
            } else {
              this.patchValues(property, originalForm, newComposition);
            }
          }
        } else {

          const control: AbstractControl = originalForm.get(newComposition);

          if (control && property) {
            control.setValue(property, {emitEvent: false});
          }

        }
      }
    }
  }

  checkAddHeader(url): boolean {
    if (
      url.indexOf('lang-files') == -1 &&
      url.indexOf('/assets/i18n/') == -1 &&
      url.indexOf('http://') == -1 &&
      url.indexOf('https://') == -1 &&
      url.indexOf('ws://') == -1 &&
      url.indexOf('wss://') == -1 &&
      url.indexOf('viacep') === -1 &&
      url.indexOf('/twilio-php-master/') == -1) {
      return true;
    }
    return false;
  }

  enumToArray(Enum) {
    let map: { id: any; name: any }[] = [];

    for (var n in Enum) {
      map.push({id: n, name: <any>Enum[n]});
    }
    return map;
  }

  // Converte um array de enuns para array de enum chave e nome
  arrayToEnumAndName(Enum) {
    let map: { id: any; name: any }[] = [];

    for (var n in Enum) {
      map.push({id: Enum[n], name: <any>this.getStringFromEnum(Enum[n])});
    }

    return map;
  }

  // Converte enum em array com nome ja convertido
  enumToArrayAndName(Enum) {
    let map: { id: any; name: any }[] = [];

    for (var n in Enum) {
      map.push({id: n, name: <any>this.getStringFromEnum(Enum[n])});
    }
    return map;
  }

  getStringFromEnum(enumValue) {
    enumValue =
      enumValue.replace(/_/g, ' ').toLowerCase();
    return enumValue[0].toUpperCase() + enumValue.substring(1);
  }

  enumStandardize(Enum) {
    let map: any = [];
    for (var n in Enum) {
      map[n] = n;
    }
    return map;
  }

  minLengthArray(min: number): ValidatorFn {
    return (c: AbstractControl): { [key: string]: any } => {
      if (c.value.length >= min) {
        return null;
      }

      return {MinLengthArray: true};
    };
  }

  isLeap(year) {
    return year % 4 == 0 && (year % 100 != 0 || year % 400 == 0);
  }

  findAge(current_date, birth_date) {
    let current_day = current_date.getDate();
    let current_month = current_date.getMonth();
    let current_year = current_date.getFullYear();

    // days of every month
    const month = [31, 28, 31, 30, 31, 30, 31,
      31, 30, 31, 30, 31];

    // ano bisiesto -> Fevereiro tem 29 dias
    if (this.isLeap(current_year)) {
      month[1] = 29;
    }

    // if birth date is greater then current birth
    // month then do not count this month and add 30
    // to the date so as to subtract the date and
    // get the remaining days
    if (birth_date.getDate() > current_day) {
      current_day = current_day + month[birth_date.getMonth() - 1];
      current_month = current_month - 1;
    }

    // if birth month exceeds current month, then do
    // not count this year and add 12 to the month so
    // that we can subtract and find out the difference
    if (birth_date.getMonth() > current_month) {
      current_year = current_year - 1;
      current_month = current_month + 12;
    }

    // calculate date, month, year
    const calculated_date = current_day - birth_date.getDate();
    const calculated_month = current_month - birth_date.getMonth();
    const calculated_year = current_year - birth_date.getFullYear();

    const age = {
      years: calculated_year,
      months: calculated_month,
      days: calculated_date
    };
    return age;

  }

  // async checkAndUploadToS3(event, size, allowedExtensions) {
  //   if (this.checkValidateUpload(event, size, allowedExtensions)) {
  //     const file = event.target.files[0];
  //     const data: any = await this.crudService.post(`cloud-files/upload`, {
  //       fileName: file.name,
  //     }, true).toPromise();
  //
  //     this.utilsService.uploadToS3(data.url, file).subscribe((s3Data) => {
  //       this.generalForm.get('pictureId').patchValue(data.id);
  //     });
  //   }
  // }

  uploadToS3(uploadUrl, file): Observable<any> {
    const headers = new HttpHeaders({'Content-Type': file.type, 'hidden-loader': 'false'});
    const req = new HttpRequest('PUT', uploadUrl, file,
      {
        headers: headers,
        reportProgress: true, // This is required for track upload process
      });
    return this.http.request(req);
  }

  getAndSetDataTypes(dataType, dataTypeArray) {
    return this.crudService.get(`data-types/${dataType}`).subscribe((data: any) => {
      if (data) {
        dataTypeArray.push(...data);
      }
    });
  }
  getDataTypes(dataType, callback) {
    return this.crudService.get(`data-types/${dataType}`).subscribe((data: any) => {
      if (data) {
        callback(data);
      }
    });
  }

  getAndSetDefaultDataTypes(dataType, formControl: FormControl) {
    return this.crudService.get(`data-types/default/${dataType}`).subscribe((data: any) => {
      if (data) {
        formControl.patchValue(data);
      }
    });
  }


  sortByKey(array, key) {
    return array.sort(function (a, b) {
      var x = a[key];
      var y = b[key];

      if (typeof x == 'string') {
        x = ('' + x).toLowerCase();
      }
      if (typeof y == 'string') {
        y = ('' + y).toLowerCase();
      }

      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }

  ptDate(control: FormControl): { [key: string]: any } {
    if ((control.value !== undefined && control.value !== '' && control.value != null)) {
      if (typeof control.value !== 'object') {
        return {'dateInvalid': true};
      }

      if (control.value.month < 1 || control.value.month > 12) { // check month range
        return {'dateInvalid': true};
      }
      if (control.value.day < 1 || control.value.day > 31) {
        return {'dateInvalid': true};
      }
      if ((control.value.month === 4 || control.value.month === 6 || control.value.month === 9 || control.value.month === 11) && control.value.day === 31) {
        return {'dateInvalid': true};
      }
      if (control.value.month == 2) { // check for february 29th
        // if ( ( ano % 4 == 0 && ano % 100 != 0 ) || (ano % 400 == 0) ) {

        const isleap = ((control.value.year % 4 === 0 && control.value.year % 100 !== 0) || (control.value.year % 400 === 0));
        if (control.value.day > 29 || (control.value.day === 29 && !isleap)) {
          return {'dateInvalid': true};
        }
      }

      if (control.value.year.toString().length != 4) {
        return {'dateInvalid': true};
      }

    }

    return null;
  }


  minDateValidator(minDate: Date): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if ((control.value !== undefined && control.value !== '' && control.value != null)) {
        if (typeof control.value === 'object') {
          const date = this.ngbDatePtParserFormatterService.getDate(control.value);
          minDate = this.ngbDatePtParserFormatterService.getDate(this.ngbDatePtParserFormatterService.fromModelDate(minDate.toString()));
          if (date < minDate) {
            return {'dateInvalid': true};
          }
        }
      }
      return null;
    }
  }

  maxDateValidator(maxDate: Date): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if ((control.value !== undefined && control.value !== '' && control.value != null)) {
        if (typeof control.value === 'object') {
          const date = this.ngbDatePtParserFormatterService.getDate(control.value);
          maxDate = this.ngbDatePtParserFormatterService.getDate(this.ngbDatePtParserFormatterService.fromModelDate(maxDate.toString()));
          if (date > maxDate) {
            return {'dateInvalid': true};
          }
        }
      }
      return null;
    }
  }

  noWhiteSpaceAllowed(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if ((control.value !== undefined && control.value !== '' && control.value != null)) {
        if (control.value.trim().length == 0) {
          return {'noWhiteSpaceAllowed': true}
        }
      }

      return null;
    }
  }

  maxLengthValidator(maxLength): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if ((control.value !== undefined && control.value !== '' && control.value != null)) {
        if (control.value.toString().length > maxLength) {
          return {maxlength: {requiredLength: maxLength, actualLength: control.value.toString().length}}
        }
      }
      return null;
    }
  }



  StandardizeObject(value, controller) {
    if (typeof value == 'string') {
      if (value.length > 0) {
        controller.setValue({'name': value}, {onlySelf: false, emitEvent: false});
      } else {
        controller.setValue(null, {onlySelf: false, emitEvent: false});
      }
    }
  }


  copyToClipBoard(value) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

}
