import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';

import {Observable} from 'rxjs';
import {AbstractGuard} from '../abstracts/guard.abstract.';

import {LoaderService} from '../loader/loader.service';
import {AuthenticationService} from './authentication.service';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard extends AbstractGuard {

  constructor(private authenticationService: AuthenticationService,
              private router: Router,
              private loaderService: LoaderService) {
    super();
  }


  guard(activatedRouter: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Observable<boolean> | boolean {
    if (this.authenticationService.isLoggedIn) {
      return true;
    }

    this.router.navigate(['login'], {queryParams: {returnUrl: routerState.url}});

    return false;

  }


}
