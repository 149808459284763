import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {UtilsService} from '../services/utils.service';
import {AuthenticationService} from '../authentication/authentication.service';


@Injectable()
export class UrlInterceptor implements HttpInterceptor {

  constructor(private  authenticationService: AuthenticationService,
              private utilsService: UtilsService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    if (this.utilsService.checkAddHeader(req.url)) {
      req = req.clone({url: environment.apiUrl + req.url});
    }
    return next.handle(req);

  }
}
