import {Injectable} from '@angular/core';

import {finalize} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CrudService {

  constructor(private http: HttpClient) {
  }

  // Ignora colocar o id no final do put
  genericsave(path: string, data: any, load = true) {

    return this.action(data).bind(this.http)(path, data, load).pipe(
      finalize(() => {

      }));
  }

  save(path: string, data: any, load = true) {

    return this.action(data).bind(this.http)(this.path(data, path), data, load).pipe(
      finalize(() => {

      }));
  }

  download(path: string, id?: string): Observable<Blob> {
    // let headers = new HttpHeaders();
    // headers.append('hidden-loader','false')
    // options = {headers: headers};

    return this.http.get(path + (id ? '/' + id : ''), {responseType: 'blob'})
      .pipe(
        finalize(() => {
        }));
  }

  getPostBlob(path: string, data: any = {}) {
    return this.http.post(path, data, {responseType: 'blob', observe: 'response'});
  }

  getBlob(path: string) {
    return this.http.get(path, {responseType: 'blob', observe: 'response'});
  }

  post(path: string, data: any = {}, load = true) {
    let options = {};
    if (!load) {
      options = {headers: {'hidden-loader': 'false'}};
    }
    return this.http.post(path, data, options).pipe(
      finalize(() => {

      }));
  }

  genericDelete(path: string, load = true) {
    let options = {};
    if (!load) {
      options = {headers: {'hidden-loader': 'false'}};
    }
    return this.http.delete(path, options).pipe(
      finalize(() => {

      }));
  }


  genericPut(path: string, data: any, load = true) {
    let options = {};
    if (!load) {
      options = {headers: {'hidden-loader': 'false'}};
    }
    return this.http.put(path, data, options).pipe(
      finalize(() => {

      }));
  }

  put(path: string, data: any, load = true) {
    let options = {};
    if (!load) {
      options = {headers: {'hidden-loader': 'false'}};
    }
    return this.http.put(path + '/' + (data.id != null ? data.id : ''), data, options).pipe(
      finalize(() => {

      }));
  }

  get(path: string, id?: string, load = true) {
    let options = {};
    if (!load) {
      options = {headers: {'hidden-loader': 'false'}};
    }
    return this.http.get(path + (id ? '/' + id : ''), options).pipe(
      finalize(() => {
      }));
  }


  read(path: string, id: number, load = true) {
    let options = {};
    if (!load) {
      options = {headers: {'hidden-loader': 'false'}};
    }

    return this.http.get(path + '/' + id, options).pipe(
      finalize(() => {
      }));
  }

  delete(path: string, id?: number, load = true) {
    let options = {};
    if (!load) {
      options = {headers: {'hidden-loader': 'false'}};
    }
    return this.http.delete(path + (id ? '/' + id : ''), options).pipe(
      finalize(() => {

      }));
  }

  action(data: any): Function {
    return data.id != null ? this.http.put : this.http.post;
  }

  path(data, path): string {
    return data.id != null ? path + '/' + data.id : path;
  }


  uploadFile(file, load = true) {
    const formData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post('files/upload', formData).pipe(
      finalize(() => {

      })
    );
  }

}
