import {Injectable} from '@angular/core';
import {PessoaTipoEnum} from '../enums/pessoa-tipo.enum';


@Injectable({
  providedIn: 'root'
})

export class PessoaTipoEnumToNameService {

  constructor() {

  }

  get() {

    return [{name: 'Física', value: PessoaTipoEnum.PF},
      {name: 'Jurídica', value: PessoaTipoEnum.PJ}
    ]
  }

}
