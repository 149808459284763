  export enum ActionsEnum {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  READ = 'READ',
  ENABLE = 'ENABLE',
  DISABLE = 'DISABLE',
  IMPORT = 'IMPORT',
  EXPORT = 'EXPORT',
  PUBLISH = 'PUBLISH',
  READ_APPLICATIONS = 'READ_APPLICATIONS',
  RATE_APPLICATIONS = 'RATE_APPLICATIONS',
  CLOSE = 'CLOSE',
  DISAPPROVE = 'DISAPPROVE',
  APPROVE = 'APPROVE',
  DUPLICATE = 'DUPLICATE',

}
