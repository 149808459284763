import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BookmarkService} from './bookmark.service';

@Component({
  selector: 'app-bookmark',
  templateUrl: './bookmark.component.html',
  styleUrls: ['./bookmark.component.scss']
})
export class BookmarkComponent implements OnInit {

  public bookmarkItems: any[] = [];

  constructor(public route: ActivatedRoute,
              private router: Router,
              private bookMarkService: BookmarkService) {
  }

  ngOnInit() {
    this.bookMarkService.bookmarks.subscribe((data) => {
      this.bookmarkItems = data;
    });
  }

  navigateTo(item) {

    if (item.path) {
      this.router.navigate([item.path], {relativeTo: this.route});
    } else if (item.click) {
      item.click();
    }
  }

}
