import {ServerResultsService} from '../services/server-results.service';
import {FormGroup} from '@angular/forms';

import {catchError, finalize, map} from 'rxjs/operators';
import {PagedData} from '../../shared/model/paged-data';
import {Injectable, OnDestroy} from '@angular/core';
import {BookmarkService} from '../../shared/components/bookmark/bookmark.service';
import {AppInjector} from '../services/app-injector.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UtilsService} from '../services/utils.service';
import {FilterService} from '../services/filter.service';
import {RouterUrlService} from '../services/router-url.service';
import {Filter} from '../../shared/model/filter';
import {TargetResourceEnum} from '../../shared/enums/targetResourceEnum';
import {ActionsEnum} from '../../shared/enums/actions.enum';
import {UntilDestroy} from '@ngneat/until-destroy';
import {throwError} from 'rxjs';

@UntilDestroy({arrayName: 'subscriptions'})
@Injectable()
export abstract class ListAbstract implements OnDestroy {

  // Array de subscriptions para dar unsubscribe
  subscriptions = [];

  public pagedData = new PagedData();

  public filter = new Filter();

  public isCollapsed = false;

  public allColumns = [];

  public columnsFilterCollapsed = false;

  public path = '';

  public form: FormGroup;

  columns: any[] = [];

  public bookmarkObject: any = [];

  // Se devo ou não concatenar o /index na url
  protected concatIndex = true;

  // Se devo mostrar o loader ou não
  protected showLoader = true;

  // Variavel para armazenar o ID do pai na rota caso exista
  protected parentId = null;


  TargetResourceEnum = TargetResourceEnum;
  ActionsEnum = ActionsEnum;

  protected utilsService: UtilsService;
  protected serverResultsService: ServerResultsService;
  protected bookmarkService: BookmarkService;
  protected router: Router;
  protected translateService: TranslateService;
  protected filterService: FilterService;
  protected routerUrlService: RouterUrlService;

  protected constructor(public route: ActivatedRoute) {

    this.utilsService = AppInjector.injector.get(UtilsService);
    this.serverResultsService = AppInjector.injector.get(ServerResultsService);
    this.bookmarkService = AppInjector.injector.get(BookmarkService);
    this.router = AppInjector.injector.get(Router);
    this.translateService = AppInjector.injector.get(TranslateService);
    this.filterService = AppInjector.injector.get(FilterService);
    this.routerUrlService = AppInjector.injector.get(RouterUrlService);

    this.bookmarkObject = [
      {path: null, title: 'Filter', icon: 'filter', click: this.openFilter.bind(this)},
      {path: null, title: 'Column filter', icon: 'check-square', click: this.openColumnFilter.bind(this)},
    ];

    if (typeof this.route.snapshot.data.registerPath != 'undefined' && this.route.snapshot.data.registerPath != '') {
      this.bookmarkObject.push({
        path: this.route.snapshot.data.registerPath,
        title: 'Register',
        icon: 'file-text'
      });
    }


    /*Pego o path que ja vem na rota*/
    this.path = this.route.snapshot.data.resourcePath;
  }

  // Usado para pegar o ID da url da rota pai LazyLoading e concatenar na rota filha
  protected pathWithParentId() {
    this.route.parent.parent.params.subscribe(param => {
      // PARENT_ID_1
      // this.path = param['id'] + '/' + this.path;
      this.parentId = param['id'];
      this.path = this.path.replace('PARENT_ID_1', param['id']);

      this.bookmarkObject.forEach((bookmark, index) => {
        if (bookmark['path'] != null) {
          this.bookmarkObject[index]['path'] = this.bookmarkObject[index]['path'].replace('PARENT_ID_1', param['id']);
        }
      });

    });

  }

  /*Quem quiser sobrescreve, o defau1lt é item*/
  public onActivate(info: any) {
    this.router.navigate(['item', info.id], {relativeTo: this.route});
  }

  // public abstract onActivate(info);

  private setFilter(): any {
    let filters = null;
    try {
      filters = this.form.getRawValue();
    } catch (e) {
    }

    return filters;
  };

  public pipeMap(data) {
    return data;
  }

  public subscribeData() {
  }

  public finalizeData() {

  }

  public catchErrorData() {

  }

  protected setFilterPage(page) {
    try {
      this.form.get('page').patchValue(page);
      this.saveFilters(this.form.getRawValue());
    } catch (e) {
      console.error(e);
    }

  }

  protected saveFilters(filters) {
    this.filterService.set(filters);
  }

  protected clearFilters() {
    this.filterService.clear();
  }

  // Para iniciar o filtro vindo do localstorage
  protected initFilter(form: FormGroup[] | FormGroup) {

    if (this.routerUrlService.isFormPreviousUrl()) {
      if (Array.isArray(form)) {
        form.forEach(currentForm => {
          currentForm.patchValue(this.filterService.get(currentForm), {onlySelf: false, emitEvent: false});
        });
      } else {
        form.patchValue(this.filterService.get(form), {onlySelf: false, emitEvent: false});
      }
    } else {
      this.clearFilters();
    }
  }


  public init() {

    try {
      this.filter.filters = this.setFilter();
      if (typeof this.filter.filters.page == 'undefined') {
        this.filter.filters.page = 0;
        console.error('ADICIONAR O FILTRO PAGE A SUA LISTA');
      }

      this.onSetPage(this.filter.filters.page);
    } catch (e) {
      console.error(e);
    }

  }

  public onSetPage(newpageNumber) {
    this.createBookmark();
    this.setFilterPage(newpageNumber);
    this.pagedData.page.pageNumber = newpageNumber;

    this.serverResultsService.getResults(this.pagedData.page, this.path + (this.concatIndex ? '/index' : ''), this.filter, {}, this.showLoader)
      .pipe(map((data) => {
        return this.pipeMap(data);
      }), finalize(() => {
        this.finalizeData();
      }), catchError((err) => {
        this.catchErrorData();
        return throwError(err);
      }))
      .subscribe((pagedData: PagedData) => {
        this.pagedData = pagedData;
        this.subscribeData();
      });

  }

  // Limpar o conteudo da tabela
  public clearListData() {
    this.pagedData.data.rows = [];
  }

  public refresh() {
    if (this.pagedData.data.rows.length > 0) {
      this.onSetPage(this.pagedData.page.pageNumber);
    } else {
      this.onSetPage(0);
    }

  }

  public onClear() {
    this.form.reset();
    this.onSubmit();
  }

  public onSubmit() {
    this.filter.filters = this.setFilter();
    this.saveFilters(this.filter.filters);
    this.onSetPage(0);
  }

  public onSort(sort) {
    this.filter.sortProperties = sort;
    this.onSetPage(0);
  }


  protected openFilter() {
    this.isCollapsed = true;
  }

  protected createBookmark() {
    this.bookmarkService.setBookMark(this.bookmarkObject);
  }

  protected destroyBookmark() {
    this.bookmarkService.clearBookMark();
  }

  public ngOnDestroy(): void {
    this.destroyBookmark();
    this.filterService.init('');
  }

  // Início métodos filtro de colunas
  public makeColumns(columns) {
    this.columns = columns;
    this.allColumns = columns;
  }
  public pushColumns(newColumns) {
    this.columns = [...this.columns, ...newColumns];
    this.allColumns = [...this.allColumns, ...newColumns];
  }

  public openColumnFilter() {
    this.columnsFilterCollapsed = true;
    // console.log(this.columnsFilterCollapsed);
  }

  public columnFilterClick(col) {
    const isChecked = this.columnFilterIsChecked(col);

    if (isChecked) {
      this.columns = this.columns.filter(c => {
        return c.name !== col.name;
      });
    } else {
      this.columns = [...this.columns, col];
    }
  }

  public columnFilterIsChecked(col) {
    return (
      this.columns.find(c => {
        return c.name === col.name;
      }) !== undefined
    );
  }

  // Fim métodos filtro de colunas


  getParams() {
    return this.serverResultsService.createParams(this.pagedData.page, this.filter);
  }

}
