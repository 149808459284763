import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {FormAbstract} from '../../../core/abstracts/form.abstract';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActionConfirmTypesEnum} from '../../enums/action-confirm-types.enum';

@Component({
  selector: 'app-custom-confirm-modal',
  templateUrl: './custom-confirm-modal.component.html',
  styleUrls: ['./custom-confirm-modal.component.scss']
})
export class CustomConfirmModalComponent extends FormAbstract implements OnInit {


  @Input() title;
  @Input() confirmText = 'Para confirmar, digite CONFIRM_TEXT no campo de entrada de texto.';
  @Input() buttonLabel;
  @Input() successLabel;
  @Input() btnClass = 'btn-danger'

  @Input() entityId;
  @Input() url;
  @Input() actionType = ActionConfirmTypesEnum.DELETE;

  // Código de verificação
  code = '';
  // O rash que o servidor me envia
  hashData = '';


  constructor(public activeModal: NgbActiveModal,
              public route: ActivatedRoute, elementRef: ElementRef) {
    super(route, elementRef);
  }

  ngOnInit(): void {
    this.createForm();
    this.loadData();

  }


  save(data: any) {

    this.crudService.put(`${this.url}/${this.entityId}/actions/${this.actionType}/${this.hashData}/${data.userKey}`, {}).subscribe(response => {
      this.toastService.sendSuccess(this.successLabel);
      this.activeModal.close();
    });

  }

  createForm() {
    this.form = new FormGroup({
      userKey: new FormControl(null, [Validators.required]),
    }, this.sameValue.bind(this));


  }

  sameValue(group: FormGroup): { [s: string]: boolean } {

    const userKey = group.get('userKey').value;
    if (userKey != this.code) {
      group.get('userKey').setErrors({notSame: true});
    }

    return null;
  }

  loadData() {

    this.crudService.get(`${this.url}/${this.entityId}/actions/${this.actionType}`).subscribe((response: any) => {
      this.hashData = response.hashData;
      this.code = response.code;
    });


  }


}
