import {Injectable} from '@angular/core';
import {AreaAtuacaoStatusEnum} from '../enums/area-atuacao-status.enum';
import {CupomDescontoStatusEnum} from '../enums/cupom-desconto-status.enum';

@Injectable({
    providedIn: 'root'
})

export class CupomDescontoStatusEnumToNameService {
    constructor() {
    }

    get() {
        return [
            {name: 'ATIVO', value: CupomDescontoStatusEnum.ATIVO},
            {name: 'DESATIVADO', value: CupomDescontoStatusEnum.DESATIVADO},
        ];
    }
}
