import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';

export abstract class AbstractGuard implements CanActivate, CanLoad, CanActivateChild {

  abstract guard(...args: any[]): Observable<boolean> | Promise<boolean> | boolean;

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this.guard(route);
  }

  canActivate(activatedRouter: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.guard(activatedRouter, routerState);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.guard(childRoute, state);
  }

}
