<div class="{{cssClass}} form-group">
  <label *ngIf="showRequiredLabel">{{label | translate}}<span class="required" *ngIf="isRequired">*</span>
  </label>
  <app-icon-popover
    *ngIf="enablePopover"
    [contentpopover] = "contentpopover"
    [titlePopover] = "titlePopover"
  ></app-icon-popover>
  <div class="input-group">
    <ng-container *ngFor="let option of options; let i = index">
      <div class="radio {{radioClass}} " style="margin-bottom: 5px;padding: 0">
        <input
          [id]="id+'_'+i"
          class=""
          [disabled]="disabled"
          [readonly]="readOnly"
          [name]="name"
          type="radio"
          [formControl]="control"
          (blur)="onTouchedCb()"
          (change)="onChangeCb(option.value)"
          [value]="option.value"
          [checked]="control.value === option.value">
        <label [for]="id+'_'+i" class="{{labelClass}}">
          {{(option?.label ? option?.label : option.value) | translate}}
        </label>
      </div>

    </ng-container>


    <app-form-error-messages [control]="control" [customErrorMessage]="customErrorMessage"></app-form-error-messages>
  </div>
</div>
