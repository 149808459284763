<!--<div class="errors-container" *ngIf="control.dirty || control.touched">-->
<!--  <span class="text text-danger d-block" *ngFor="let error of inputControlService.makeErrors(control)"-->
<!--        [innerHTML]="errorMessages[error] | translate:inputControlService.getErrorObject(error,control)"></span>-->
<!--</div>-->

<div *ngIf="(control.dirty || control.touched) && formSubmitted" class="help-block mt-1 text-danger">
  <ng-container *ngFor="let error of inputControlService.makeErrors(control)">
    <i class="ft-alert-circle align-middle"></i><span style="margin-left: 5px;"
                                                      [innerHTML]="errorMessages[error] | translate:inputControlService.getErrorObject(error,control)"></span><br>

  </ng-container>

</div>
