import {PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';


export class EnumToNameModelPipe implements PipeTransform {

  private text: string = null;

  constructor(public enumArray: {}[],
              public translateService: TranslateService) {

  }

  transform(value: any, args?: any): any {
    const index = this.enumArray.find((data) => {
      return data['value'] == value;
    });
    if (index) {
      this.translateService.get(index['name']).subscribe(translate => {
        this.text = translate;
      });
    } else {
      this.text = '-';
    }
    return this.text;
  }
}
