import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UtilsService} from '../../../core/services/utils.service';
import {CrudService} from '../../../core/services/crud.service';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {TargetResourceEnum} from '../../enums/targetResourceEnum';
import {ActionsEnum} from '../../enums/actions.enum';
import {PermissionService} from '../../services/permission.service';

@Component({
  selector: 'app-input-image',
  templateUrl: './input-image.component.html',
  styleUrls: ['./input-image.component.scss']
})
export class InputImageComponent implements OnInit {

  @Input() defaultImageSrc = 'assets/img/images/user.png';
  @Input() imageSrc = null;
  @Input() disabled = false;
  @Input() actions: ActionsEnum[];
  @Input() target: TargetResourceEnum;

  @Output() onFileChange = new EventEmitter<any>();
  @Output() onFileReset = new EventEmitter<any>();
  @ViewChild('fileUpload', {static: false}) fileUpload: ElementRef;

  isLoading = false;

  constructor(private utilsService: UtilsService,
              private crudService: CrudService,
              private permissionService: PermissionService) {
  }

  ngOnInit(): void {
    try {
      if (!this.disabled) {
        // Lugares que não precisão de permissão irão funcionar
        const newDisabled = !this.permissionService.hasPermission(this.target, ...this.actions);
        this.disabled = newDisabled;
      }
    } catch (e) {
    }

  }

  onImageLoad() {
    this.isLoading = false;
  }

  reset() {
    this.imageSrc = null;
    this.onFileReset.emit();
  }

  async onChangeFile(event) {


    if (this.utilsService.checkValidateUpload(event, 20, ['png', 'jpg', 'jpeg'])) {
      this.isLoading = true;
      try {
        const file = event.target.files[0];
        const data: any = await this.crudService.post(`cloud-files/upload`, {
          fileName: file.name,
        }, true).toPromise();

        this.utilsService.uploadToS3(data.url, file).pipe(catchError((err) => {
          this.onImageLoad();
          this.fileUpload.nativeElement.value = '';
          return throwError(err);
        })).subscribe((s3Data) => {
          // this.form.get('pictureId').patchValue(data.id);
          this.onFileChange.emit(data.id);
          this.imageSrc = data.s3Url
          this.fileUpload.nativeElement.value = '';
        });
      } catch (e) {
        this.onImageLoad();
      }

    }
  }

  error() {
    this.imageSrc = null;
  }
}
