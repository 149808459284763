import {Injectable} from '@angular/core';
import {EmpresaStatusEnum} from '../enums/empresa-status.enum';


@Injectable({
    providedIn: 'root'
})

export class EmpresaStatusEnumToNameService {

    constructor() {

    }

    get() {
        return [
            {name: 'ATIVADA', value: EmpresaStatusEnum.ATIVADA},
            {name: 'DESATIVADA', value: EmpresaStatusEnum.DESATIVADA},
        ];
    }

}
