import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {AppInjector} from './core/services/app-injector.service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

    subscription: Subscription;

    constructor(private router: Router, private translateService: TranslateService, injector: Injector) {
        AppInjector.injector = injector;

        this.translateService.addLangs(Object.keys(environment.translate));
        /*Default primeira tradução do objeto*/
        this.translateService.setDefaultLang(this.translateService.getLangs()[0]);

        const language = this.translateService.getBrowserCultureLang();
        if (this.translateService.getLangs().indexOf(language) !== -1) {
            this.translateService.use(language);
        } else {

            this.translateService.getLangs().every((lang) => {

                if (lang.indexOf('-') !== -1) {
                    const splitedLang = lang.split('-');
                    if (splitedLang[0].toLowerCase() === language) {
                        this.translateService.use(lang);
                        return false;
                    }
                }
                return true;
            });

        }
    }

    ngOnInit() {
        this.subscription = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(() => window.scrollTo(0, 0));
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
