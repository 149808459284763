import {NgModule} from '@angular/core';
import {OverlayModule} from '@angular/cdk/overlay';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {ClickOutsideModule} from 'ng-click-outside';
import {AutocompleteModule} from './components/autocomplete/autocomplete.module';
import {PipeModule} from 'app/shared/pipes/pipe.module';

// COMPONENTS
import {FooterComponent} from './footer/footer.component';
import {NavbarComponent} from './navbar/navbar.component';
import {HorizontalMenuComponent} from './horizontal-menu/horizontal-menu.component';
import {VerticalMenuComponent} from './vertical-menu/vertical-menu.component';
import {CustomizerComponent} from './customizer/customizer.component';
import {NotificationSidebarComponent} from './notification-sidebar/notification-sidebar.component';

// DIRECTIVES
import {ToggleFullscreenDirective} from './directives/toggle-fullscreen.directive';
import {SidebarLinkDirective} from './directives/sidebar-link.directive';
import {SidebarDropdownDirective} from './directives/sidebar-dropdown.directive';
import {SidebarAnchorToggleDirective} from './directives/sidebar-anchor-toggle.directive';
import {SidebarDirective} from './directives/sidebar.directive';
import {TopMenuDirective} from './directives/topmenu.directive';
import {TopMenuLinkDirective} from './directives/topmenu-link.directive';
import {TopMenuDropdownDirective} from './directives/topmenu-dropdown.directive';
import {TopMenuAnchorToggleDirective} from './directives/topmenu-anchor-toggle.directive';
import {FormErrorMessagesComponent} from './components/form-error-messages/form-error-messages.component';
import {InputFieldComponent} from './components/input-field/input-field.component';
import {BookmarkComponent} from './components/bookmark/bookmark.component';
import {IntegerInputFieldComponent} from './components/integer-input-field/integer-input-field.component';
import {InderterminateCheckboxComponent} from './components/inderterminate-checkbox/inderterminate-checkbox.component';

import {InputTextareaComponent} from './components/input-textarea/input-textarea.component';
import {ModalWarningComponent} from './components/modal-warning/modal-warning.component';
import {MultipleCheckListComponent} from './components/multiple-check-list/multiple-check-list.component';
import {ModalAlertComponent} from './components/modal-alert/modal-alert.component';
import {PermissionDirective} from './directives/permission.directive';
import {DatePickerComponent} from './components/date-picker/date-picker.component';
import {CustomConfirmModalComponent} from './components/custom-confirm-modal/custom-confirm-modal.component';
import {InputModalFormComponent} from './components/input-modal-form/input-modal-form.component';
import {FormTableComponent} from './components/form-table/form-table.component';
import {RealInputFieldComponent} from './components/real-input-field/real-input-field.component';
import {SelectListComponent} from './components/select-list/select-list.component';
import {ResizableDirective} from './directives/resizable.directive';
import {TimePickerComponent} from './components/time-picker/time-picker.component';
import {InputModalComponent} from './components/input-modal/input-modal.component';
import {SwitchCompanyComponent} from './components/switch-company/switch-company.component';
import {NumberDirective} from './directives/numbers-only.directive';
import {FeatherIconsComponent} from './components/feather-icons/feather-icons.component';
import {BreadcrumbComponent} from './components/breadcrumb/breadcrumb.component';
import {InputRadioComponent} from './components/input-radio/input-radio.component';
import {ModalAddressComponent} from './components/modal-address/modal-address.component';
import {DefaultFormActionsButtonComponent} from './components/default-form-actions-button/default-form-actions-button.component';
import {InputMultSelectComponent} from './components/input-mult-select/input-mult-select.component/input-mult-select.component';
import {InputImageComponent} from './components/input-image/input-image.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {SingleUploadButtonComponent} from './components/single-upload-button/single-upload-button.component';
import {InputSelectComponent} from './components/input-select/input-select.component';
import {TypeaheadSingleComponent} from './components/typeahead-single/typeahead-single.component';
import {UserImageComponent} from './components/user-image/user-image.component';
import {IconPopoverComponent} from './components/icon-popover/icon-popover.component';
import {CoreModule, HttpLoaderFactory} from '../core/core.module';
import {FormBadgesComponent} from './components/form-badges/form-badges.component';
import {ActionConfirmModalComponent} from './components/action-confirm-modal/action-confirm-modal.component';
import {DatatableComponent} from './components/datatable/datatable.component';
import {TypeaheadComponent} from './components/typeahead/typeahead.component';
import {HttpClient} from '@angular/common/http';
import {NgxCurrencyModule} from './components/currency-mask/currency-mask.module';
import {InputMaskModule} from 'racoon-mask-raw';
import { InputSearchComponent } from './components/input-search/input-search.component';
import { InputCurrencyComponent } from './components/input-currency/input-currency.component';
import { VagasListStatusComponent } from './system-components/vagas-list-status/vagas-list-status.component';
import { CompetenciasListStatusComponent } from './system-components/competencias-list-status/competencias-list-status.component';
import { EmpresasListStatusComponent } from './system-components/empresas-list-status/empresas-list-status.component';
import { AdvogadosListStatusComponent } from './system-components/advogados-list-status/advogados-list-status.component';
import { ProfilePictureComponent } from './components/profile-picture/profile-picture.component';
import { AreaAtuacaoListStatusComponent } from './system-components/area-atuacao-list-status/area-atuacao-list-status.component';
import {CupomDescontoListStatusComponent} from './system-components/cupom-desconto-list-status/cupom-desconto-list-status.component';

export const customCurrencyMaskConfig = {
    align: 'left',
    allowNegative: false,
    allowZero: false,
    decimal: ',',
    precision: 2,
    prefix: '',
    suffix: '',
    thousands: '.',
    nullable: true
};

@NgModule({
    exports: [
        FooterComponent,
        NavbarComponent,
        VerticalMenuComponent,
        HorizontalMenuComponent,
        CustomizerComponent,
        ToggleFullscreenDirective,
        SidebarDirective,
        TopMenuDirective,
        NotificationSidebarComponent,
        FeatherIconsComponent,


        // Componentes Nossos
        FormErrorMessagesComponent,
        InputFieldComponent,
        BookmarkComponent,
        ModalAlertComponent,
        ModalAddressComponent,
        ModalWarningComponent,
        SelectListComponent,
        InputModalComponent,
        MultipleCheckListComponent,
        DatatableComponent,
        DatePickerComponent,
        InputSelectComponent,
        InputModalFormComponent,
        BreadcrumbComponent,
        ResizableDirective,
        InputTextareaComponent,
        UserImageComponent,
        ActionConfirmModalComponent,
        PermissionDirective,
        InderterminateCheckboxComponent,
        TimePickerComponent,
        InputRadioComponent,
        FormBadgesComponent,
        FeatherIconsComponent,
        TypeaheadComponent,
        FormTableComponent,
        CustomConfirmModalComponent,
        InputImageComponent,
        SingleUploadButtonComponent,
        SwitchCompanyComponent,
        NumberDirective,
        IconPopoverComponent,
        InputSearchComponent,

        // Shared modules
        CoreModule,
        TranslateModule,
        PipeModule,
        DefaultFormActionsButtonComponent,
        IntegerInputFieldComponent,
        RealInputFieldComponent,
        NgSelectModule,
        InputMultSelectComponent,
        TypeaheadSingleComponent,
        InputCurrencyComponent,
        VagasListStatusComponent,
        CompetenciasListStatusComponent,
        EmpresasListStatusComponent,
        AdvogadosListStatusComponent,
        ProfilePictureComponent,
        AreaAtuacaoListStatusComponent,
        CupomDescontoListStatusComponent
    ],
    imports: [
        CoreModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            isolate: false,
        }),

        // Default do template
        OverlayModule,
        PerfectScrollbarModule,
        ClickOutsideModule,
        AutocompleteModule,
        PipeModule,
        InputMaskModule,
        NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
        NgSelectModule
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        VerticalMenuComponent,
        HorizontalMenuComponent,
        CustomizerComponent,
        ToggleFullscreenDirective,
        SidebarLinkDirective,
        SidebarDropdownDirective,
        SidebarAnchorToggleDirective,
        SidebarDirective,
        TopMenuLinkDirective,
        TopMenuDropdownDirective,
        TopMenuAnchorToggleDirective,
        TopMenuDirective,
        NumberDirective,
        NotificationSidebarComponent,
        FeatherIconsComponent,

        // Componentes Nossos
        FormErrorMessagesComponent,
        InputFieldComponent,
        BookmarkComponent,
        ModalAlertComponent,
        ModalAddressComponent,
        ModalWarningComponent,
        SelectListComponent,
        InputModalComponent,
        MultipleCheckListComponent,
        DatatableComponent,
        DatePickerComponent,
        InputSelectComponent,
        InputModalFormComponent,
        BreadcrumbComponent,
        ResizableDirective,
        InputTextareaComponent,
        UserImageComponent,
        ActionConfirmModalComponent,
        PermissionDirective,
        InderterminateCheckboxComponent,
        TimePickerComponent,
        InputRadioComponent,
        FormBadgesComponent,
        FeatherIconsComponent,
        TypeaheadComponent,
        FormTableComponent,
        CustomConfirmModalComponent,
        DefaultFormActionsButtonComponent,
        InputImageComponent,
        SingleUploadButtonComponent,
        SwitchCompanyComponent,
        IntegerInputFieldComponent,
        RealInputFieldComponent,
        InputMultSelectComponent,
        IconPopoverComponent,
        TypeaheadSingleComponent,
        InputSearchComponent,
        InputCurrencyComponent,
        VagasListStatusComponent,
        CompetenciasListStatusComponent,
        EmpresasListStatusComponent,
        AdvogadosListStatusComponent,
        ProfilePictureComponent,
        AreaAtuacaoListStatusComponent,
        CupomDescontoListStatusComponent
    ]
})
export class SharedModule { }
