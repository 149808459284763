import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ToastService} from '../notification/toast/toast.service';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import {AuthenticationService} from '../authentication/authentication.service';


@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private toastService: ToastService,
              private router: Router,
              private authenticationService: AuthenticationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.error instanceof ErrorEvent) {
            // client-side error
            this.toastService.sendDanger(error.error.message);
          } else {
            // server-side error
            let messageToTranslate;
            // messageTranslationKey
            if (error.error && error.error.userMessage) {

              try {
                if (error.error.objects && error.error.objects.length > 0) {
                  error.error.objects.forEach((value) => {
                    this.toastService.sendDanger(value.userMessage);
                  });
                } else {
                  messageToTranslate = error.error.userMessage;
                  if (!environment.production) {
                    console.error(error.error);
                  }
                }
              } catch (e) {

              }
            } else {
              switch (error.status) {
                case 0:
                  // this.router.navigate(['item', info.id], {relativeTo: this.route});
                  messageToTranslate = 'Houve um erro. Por favor, tente novamente';
                  break;
                case 401:
                  messageToTranslate = 'Acesso não permitido';
                  break;
                case 403:
                  if (request.url.indexOf('/login') === -1) {
                    this.authenticationService.signOut();
                    messageToTranslate = 'Acesso não permitido';
                  } else {
                    try {
                      messageToTranslate = error.headers.get('invalid-reason');
                    } catch (e) {
                      messageToTranslate = 'Usuário ou senha inválidos';
                    }
                  }
                  break;
                case 417:
                  if (request.url.indexOf('/forgot-password/reset') != -1) {
                    messageToTranslate = 'Código inválido, solicite uma nova recuperação de senha e tente novamente.';
                  }
                  break;
                case 404:
                  messageToTranslate = 'Página não encontrada';
                  break;
                default:
                  messageToTranslate = 'Houve um erro. Por favor, tente novamente';
              }
            }
            // client-side error
            if (messageToTranslate) {
              this.toastService.sendDanger(messageToTranslate);
            }

          }

          return throwError(error);
        })
      );
  }
}
