export const environment = {
    production: false,
    projectName: 'DEFAULT_PORTAL_JUDCLASS',
    encryptionKey: 'AES_DEFAULT_DATA_JUDCLASS',

    // apiUrl: '',

    // GABA
    // apiUrl: 'http://192.168.195.204:9090/judclass/',

    // JOAO
    //apiUrl: 'http://34.226.139.210/api/',

    // EMERSON
    apiUrl: 'https://stg.judclass.com/api/',


    // apiUrl: 'https://api.judclass.com/judclass/',

    // LUIS
    // apiUrl: 'http://192.168.195.203:8083/judclass/',


    translate: {
        'pt-BR': 'Português'
        // 'ja': 'Japonês'
    },
    ignoreValidation: true,
    ignoreRoles: false
};
