import {Injectable} from '@angular/core';
import {CompetenciaStatusEnum} from '../enums/competencia-status.enum';


@Injectable({
    providedIn: 'root'
})

export class CompetenciaStatusEnumToNameService {

    constructor() {

    }

    get() {
        return [
            {name: 'EM ANÁLISE', value: CompetenciaStatusEnum.ANALISE},
            {name: 'APROVADA', value: CompetenciaStatusEnum.APROVADA},
            {name: 'REPROVADA', value: CompetenciaStatusEnum.REPROVADA},
        ];
    }

}
