import {Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {SwitchCompanyService} from './switch-company.service';
import {CrudService} from '../../../core/services/crud.service';
import {AuthenticationService} from '../../../core/authentication/authentication.service';
import {Router} from '@angular/router';


@Component({
  selector: 'app-switch-company',
  templateUrl: './switch-company.component.html',
  styleUrls: ['./switch-company.component.scss']
})
export class SwitchCompanyComponent implements OnInit {

  renderedGroupList: any[];
  renderedCompaniesList: any[];
  originalList: any[];
  activeGroupId = '';


  formCompanyFilter: FormControl;

  constructor(public switchCompanyService: SwitchCompanyService,
              private crudService: CrudService,
              private authenticationService: AuthenticationService,
              private router: Router) {
  }


  ngOnInit(): void {
    this.formCompanyFilter = new FormControl(null);

    this.switchCompanyService.show.subscribe(show => {
      if (show) {
        this.crudService.get(`grupos-clientes/resources/unidades/list`).subscribe((data: any) => {
          this.originalList = data;
          this.setDefaultActiveGroup();
          this.setLists(data);
        });
      }
    });

  }


  filterCompany() {
    let value = this.formCompanyFilter.value;
    if (value === '' || !value) {
      this.setLists(this.originalList);
    }
    let copyOriginalList = JSON.parse(JSON.stringify(this.originalList));

    copyOriginalList = copyOriginalList
      .map(group => {
        const newCompanies = group.unidades.filter(company => {
          return company.razaoSocial.toUpperCase().includes(value.toUpperCase());
        });
        if (newCompanies.length > 0) {
          group.unidades = newCompanies;
          return group;
        }
      })
      .filter(group => {
        return !!group;
      })
    // if (copyOriginalList.length > 0) {
    //   this.activeGroupId = copyOriginalList[0].id;
    // }
    this.setLists(copyOriginalList);
  }

  setLists(serverList: any[]) {
    this.renderedGroupList = [...serverList];
    if (this.renderedGroupList.length > 0) {
      const grupo = serverList.filter((data) => data.id == this.activeGroupId);
      if (grupo) {
        this.renderedCompaniesList = [...grupo[0].unidades];
      } else {
        this.renderedCompaniesList = [];
      }

    } else {
      this.renderedCompaniesList = [];
    }
  }

  setDefaultActiveGroup() {
    try {
      this.activeGroupId = this.authenticationService.getGrupoClienteId();
    } catch (e) {

    }

  }

  onChangeGroup(id) {
    this.activeGroupId = id;
    this.setLists(this.originalList);
  }

  error(grupo) {
    grupo.logoUrl = null;
  }


  changeCompany(company) {
    this.authenticationService.changeCompany(company).subscribe(() => {
      this.router.navigate(['/']).then(()=>{
        window.location.reload();
      });
    });
  }

}

