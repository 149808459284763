import {Injectable} from '@angular/core';
import {VagaStatusEnum} from '../enums/vaga-status.enum';


@Injectable({
    providedIn: 'root'
})

export class VagaStatusEnumToNameService {

    constructor() {

    }

    get() {
        return [
            {name: 'EM CRIAÇÃO', value: VagaStatusEnum.EM_CRIACAO},
            {name: 'DISPONIBILIZADA', value: VagaStatusEnum.PUBLICADA},
            {name: 'ENCERRADA', value: VagaStatusEnum.ENCERRADA},
        ];
    }

}
