<div class="form-group {{cssClass}}">
  <label *ngIf="label.length>0" [attr.for]="id">{{label | translate}}<span class="required" *ngIf="isRequired">*</span>
  </label>
  <app-icon-popover
    *ngIf="enablePopover"
    [contentpopover] = "contentpopover"
    [titlePopover] = "titlePopover"
  ></app-icon-popover>
  <div>

      <textarea
        [id]="id"
        class="form-control"
        [disabled]="disabled"
        [formControl]="control"
        [ngClass]="{ 'is-invalid': formSubmitted && control.invalid, 'is-valid': formSubmitted && !control.invalid , 'disableResize':disableResize }"
        style="min-height: 100px;"
      >
  </textarea>
    <span *ngIf="showLength"
          class="count">{{(control.value?.length > 0 ? control.value.length :0 )}}/{{maxLength}}</span>

  </div>
  <app-form-error-messages [control]="control" [customErrorMessage]="customErrorMessage"></app-form-error-messages>

</div>
