<div class="container-fluid" [ngClass]="{'no-breadcrumb' : !show}">
  <div class="page-header">
    <div class="row">
      <div class="">
        <div class="page-header-left">
          <h3>{{title|translate}}</h3>
          <ol class="breadcrumb" *ngIf="breadCrumbsList.length>0">
            <li
              class="breadcrumb-item"
              *ngFor="let breadCrumb of breadCrumbsList; let i = index"
              [ngClass]="{'active': (breadCrumbsList.length -1) == i, 'link-pointer':
              (breadCrumbsList.length -1) != i}">
              <a (click)="navigateTo(breadCrumb.url)">{{breadCrumb.label | translate}}</a></li>
          </ol>
        </div>
      </div>
<!--      <div class="col-lg-6">-->
<!--        <app-bookmark></app-bookmark>-->
<!--      </div>-->
    </div>
  </div>
</div>
