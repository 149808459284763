import {Component, EventEmitter, Input, OnInit, Optional, Output, Self, ViewChild} from '@angular/core';
import {AbstractControl, ControlValueAccessor, FormControl, NgControl, NgModel} from '@angular/forms';


// const INPUT_DEFAULT_VALUE_ACESSOR: any = {
//   provide: NG_VALUE_ACCESSOR,
//   useExisting: forwardRef(() => InputFieldComponent),
//   multi: true
// };

export const hasRequiredField = (abstractControl: AbstractControl): boolean => {
  if (abstractControl.validator) {
    const validator = abstractControl.validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
    return false;
  }
};

@Component({
  selector: 'app-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss']
})
export class InputFieldComponent implements ControlValueAccessor, OnInit {

  @Input() cssClass = '';
  @Input() id = '';
  @Input() label = '';
  @Input() name = null;
  @Input() placeholder = '';
  @Input() type = 'text';
  @Input() iconClass = null;

  // Icon popover
  @Input() enablePopover = false;
  @Input() contentpopover = '';
  @Input() titlePopover = '';

  // Se o icone vai ser pre ou pos
  @Input() iconClassPre = true;
  // Máscara do input
  @Input() mask = '';
  // Mostrar a mascara no input
  @Input() showMaskTyped = false
  // Prefixo de mascara do input
  @Input() maskPrefix = '';
  // Sufixo de máscara do input
  @Input() maskSuffix = '';
  // Caso queira usar o validador da máscara
  @Input() maskValidation = false;
  // Separador da máscara
  @Input() maskSeparator = '.';
  // Recebe objeto com chave valor customizado para mensagem de erro
  @Input() customErrorMessage = null;
  @Input() readOnly = false;
  @Input() disabled = false;

  // Para o radio
  @Input() inputValue;
  checked = false;

  control;
  errorMessages;

  @Output() iconClickEvent = new EventEmitter();
  isRequired = false;
  innerValue: any;

  @ViewChild('input', {static: false}) input: NgModel;


  // Usado para mostrar se o formulário possui erro ou não
  @Input() formSubmitted = false;
  @Input() showError = true;

  constructor(
    @Self() @Optional() public controlDirective: NgControl) {
    this.controlDirective && (this.controlDirective.valueAccessor = this);
  }

  ngOnInit() {
    if (this.controlDirective) {
      this.control = this.controlDirective.control;
      this.isRequired = hasRequiredField(this.control);

      /*Para adicionar validadores já existentes*/
      if (this.control.validator) {
        this.control.setValidators([this.validate.bind(this), this.control.validator]);
      } else {
        this.control.setValidators([this.validate.bind(this)]);
      }
      this.control.updateValueAndValidity();


      // quando tem qualquer mudança de status verifico se tem campo obrigatorio para trocar a label
      this.control.statusChanges.subscribe(() => {
        this.isRequired = hasRequiredField(this.control);
      });
    }

  }

  get value() {
    if (this.type == 'radio') {
      return this.inputValue;
    }
    return this.innerValue;
  }

  set value(v: any) {
    if (this.type == 'checkbox') {
      v = v == 'true' ? false : true;
    }

    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChange(v);
    }
  }


  iconClick() {
    this.iconClickEvent.emit('');
  }

  onChange: (_: any) => void = () => {
  };
  onTouched: (_: any) => void = () => {
  };

  writeValue(obj: any): void {
    if (this.type == 'radio') {
      this.checked = obj == this.inputValue;
    } else {
      this.innerValue = obj;
    }

  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate({value}: FormControl) {
    if (this.mask != '') {
      if (typeof this.input != 'undefined') {
        if (!this.input.valid) {
          return {invalidMask: true};
        }
      }
    }
    return false;
    // const isNotValid = this.answer !== Number(value);
    // return isNotValid && {
    //   invalid: true
    // }
  }

}
