import {Component, EventEmitter, Input, OnInit, Optional, Output, Self, ViewChild} from '@angular/core';
import {AbstractControl, ControlValueAccessor, FormControl, NgControl, NgModel} from '@angular/forms';

export const hasRequiredField = (abstractControl: AbstractControl): boolean => {
    if (abstractControl.validator) {
        const validator = abstractControl.validator({} as AbstractControl);
        if (validator && validator.required) {
            return true;
        }
        return false;
    }
};

@Component({
    selector: 'app-input-currency',
    templateUrl: './input-currency.component.html',
    styleUrls: ['./input-currency.component.scss']
})
export class InputCurrencyComponent implements ControlValueAccessor, OnInit {

    @Input() cssClass = '';
    @Input() id = '';
    @Input() label = '';
    @Input() name = null;
    @Input() placeholder = '';

    // Icon popover
    @Input() enablePopover = false;
    @Input() contentpopover = '';
    @Input() titlePopover = '';


    // Recebe objeto com chave valor customizado para mensagem de erro
    @Input() customErrorMessage = null;
    @Input() readOnly = false;
    @Input() disabled = false;

    control;
    errorMessages;

    isRequired = false;
    innerValue: any;




    // Usado para mostrar se o formulário possui erro ou não
    @Input() formSubmitted = false;
    @Input() showError = true;

    constructor(@Self() @Optional() public controlDirective: NgControl) {
        this.controlDirective && (this.controlDirective.valueAccessor = this);
    }

    ngOnInit() {
        this.control = this.controlDirective.control;
        this.isRequired = hasRequiredField(this.control);
        // quando tem qualquer mudança de status verifico se tem campo obrigatorio para trocar a label
        this.control.statusChanges.subscribe(() => {
            this.isRequired = hasRequiredField(this.control);
        });
    }

    get value() {
        return this.innerValue;
    }

    set value(v: any) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChangeCb(v);
        }
    }


    onChangeCb: (_: any) => void = () => {
    };
    onTouchedCb: (_: any) => void = () => {
    };

    writeValue(v: any): void {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChangeCb(v);
        }
    }

    registerOnChange(fn: any): void {
        this.onChangeCb = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchedCb = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
