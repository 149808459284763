export enum ActionConfirmTypesEnum {
    ENABLE = 'ENABLE',
    DISABLE = 'DISABLE',

    ATIVAR = 'ATIVAR',
    DESATIVAR = 'DESATIVAR',

    DELETE = 'DELETE',
    APROVAR = 'APROVAR',
    REPROVAR = 'REPROVAR',
    PUBLICAR = 'PUBLICAR',
    ENCERRAR = 'ENCERRAR'

}
