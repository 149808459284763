<div class="form-group">
<!--  <div class="form-row">-->
    <label [for]="id">{{label | translate}}<span class="required" *ngIf="isRequired">*</span>
    </label>
  <app-icon-popover
    *ngIf="enablePopover"
    [contentpopover] = "contentpopover"
    [titlePopover] = "titlePopover"
  ></app-icon-popover>
    <div class="input-group">
      <input
        [ngbTypeahead]="search"
        [inputFormatter]="formatterToClean"
        [resultFormatter]="formatter"
        (selectItem)="selectItemFunction($event)"
        [placeholder]="placeholderText"
        min-length=0
        (blur)="_onTouched()"
        (focus)="onFocus()"
        [(ngModel)]="keyword"
        autocomplete="off"
        style="width: 100%"
        [disabled]="disabled"
        class="form-control"
        id="{{id}}"
        type="text">
    </div>


  <app-form-badges *ngIf="showBadges" [showDeleted]="!disabled" [fieldKey]="searchAttribute" (deleteitem)="deleteitem($event)"
                   [badgesArray]="innerNewValue"></app-form-badges>

    <!--<app-form-badges *ngIf="showBadges" showDeleted="true" [fieldKey]="searchAttribute" (deleteitem)="deleteitem($event)"
                     [badgesArray]="innerNewValue"></app-form-badges>-->

  <app-form-table *ngIf="showTable" showDeleted="true" [fieldKey]="searchAttribute" (deleteitem)="deleteitem($event)"
                   [tableArray]="innerNewValue"></app-form-table>

  <app-form-error-messages [control]="control" [customErrorMessage]="customErrorMessage"></app-form-error-messages>
<!--  </div>-->
</div>
