import {Injectable} from '@angular/core';
import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

export const ISO8601_DATE_REGEX = /^(\d{4})-?(\d\d)-?(\d\d)(?:T(\d\d)(?::?(\d\d)(?::?(\d\d)(?:\.(\d+))?)?)?(Z|([+-])(\d\d):?(\d\d))?)?$/;

function padNumber(value: number) {
  if (isNumber(value)) {
    return `0${value}`.slice(-2);
  } else {
    return '';
  }
}

function isNumber(value: any): boolean {
  return !isNaN(toInteger(value));
}

function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}


@Injectable({providedIn: 'root'})
export class NgbDatePtParserFormatterService extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct {
    if (value) {
      const dateParts = value.trim().split('/');
      if (dateParts.length === 1 && isNumber(dateParts[0])) {
        return {year: toInteger(dateParts[0]), month: null, day: null};
      } else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
        return {year: toInteger(dateParts[1]), month: toInteger(dateParts[0]), day: null};
      } else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
        return {year: toInteger(dateParts[2]), month: toInteger(dateParts[1]), day: toInteger(dateParts[0])};
      }
    }
    return null;
  }

  fromModelDate(value, utc = false): NgbDateStruct {
    if (value) {
      const date = this.newDateRFC822(value);
      if (utc) {
        return {
          year: date.getUTCFullYear(),
          month: date.getUTCMonth() + 1,
          day: date.getUTCDate()
        };
      } else {
        return {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate()
        };
      }

    }
    return null;
  }

  fromModelDateTime(value: string) {
    if (value) {
      const date = this.newDateRFC822(value);
      return {
        hour: date.getHours(),
        minute: date.getMinutes(),
        second: date.getSeconds()
      };
    }
    return null;
  }

  fromModelOnlyTime(value: string) {
    const time = value.split(':');
    return {
      hour: Number(time[0]),
      minute: Number(time[1]),
      second: 0
    };

  }

  format(date: NgbDateStruct): string {
    let stringDate: string = '';
    if (date) {
      stringDate += isNumber(date.day) ? padNumber(date.day) + '/' : '';
      stringDate += isNumber(date.month) ? padNumber(date.month) + '/' : '';
      stringDate += date.year;
    }
    return stringDate;
  }

  getDate(date: NgbDateStruct): Date {
    return new Date(date.year, date.month - 1, date.day);
  }


  getDateTime(date, time): Date {
    return new Date(date.year, date.month - 1, date.day, time.hour, time.minute, time.second);
  }

  getOnlyTime(time) {
    return `${time.hour}:${time.minute}`;
  }

  newDateRFC822(RFC822) {
    let match: RegExpMatchArray | null;
    const RFC822STRING = RFC822.toString();
    if (match = RFC822STRING.match(ISO8601_DATE_REGEX)) {
      return this.isoStringToDate(match);
    }
    return new Date(RFC822);
  }

  isoStringToDate(match: RegExpMatchArray): Date {
    const date = new Date(0);
    let tzHour = 0;
    let tzMin = 0;
    // match[8] means that the string contains "Z" (UTC) or a timezone like "+01:00" or "+0100"
    const dateSetter = match[8] ? date.setUTCFullYear : date.setFullYear;
    const timeSetter = match[8] ? date.setUTCHours : date.setHours;
    // if there is a timezone defined like "+01:00" or "+0100"
    if (match[9]) {
      tzHour = Number(match[9] + match[10]);
      tzMin = Number(match[9] + match[11]);
    }
    dateSetter.call(date, Number(match[1]), Number(match[2]) - 1, Number(match[3]));
    const h = Number(match[4] || 0) - tzHour;
    const m = Number(match[5] || 0) - tzMin;
    const s = Number(match[6] || 0);
    const ms = Math.round(parseFloat('0.' + (match[7] || 0)) * 1000);
    timeSetter.call(date, h, m, s, ms);
    return date;
  }

}
