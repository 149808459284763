<div class="form-group">
  <!--  <div class="form-row">-->
  <label [for]="id">{{label | translate}}<span class="required" *ngIf="isRequired">*</span>
  </label>
  <app-icon-popover
    *ngIf="enablePopover"
    [contentpopover] = "contentpopover"
    [titlePopover] = "titlePopover"
  ></app-icon-popover>
  <div class="input-group">
    <input
      [ngbTypeahead]="search"
      [inputFormatter]="formatterToClean"
      [resultFormatter]="formatter"
      (selectItem)="selectItemFunction($event)"
      [placeholder]="placeholderText"
      min-length=0
      (blur)="_onTouched()"
      (focus)="onFocus()"
      [(ngModel)]="value"
      autocomplete="off"
      style="width: 100%"
      [disabled]="disabled"
      class="form-control"
      id="{{id}}"
      [ngClass]="{ 'is-invalid': formSubmitted && control.invalid, 'is-valid': formSubmitted && !control.invalid }"
      type="text">
    <small *ngIf="searching" class="form-text text-muted">{{'buscando'}}...</small>
  </div>

  <app-form-error-messages [control]="control" [customErrorMessage]="customErrorMessage"></app-form-error-messages>
  <!--  </div>-->
</div>
