import {Injectable} from '@angular/core';
import {StorageInterface} from './storage.interface';
import {StorageAbstract} from './storage.abstract';
import {LocalStorage} from './local-storage';
import {SessionStorage} from './session-storage';
import {StorageEnum} from './storage.enum';

@Injectable({providedIn: 'root'})
export class StorageService implements StorageInterface {

  private storageEnum = StorageEnum;
  private selected: StorageAbstract;

  private localStorage: LocalStorage = new LocalStorage();

  private sessionStorage: SessionStorage = new SessionStorage();

  private checkKey = 'checkKey';

  constructor() {
    this.useStorage(this.storageEnum.sessionStorage);
    if (!this.check(this.checkKey)) {
      this.useStorage(this.storageEnum.localStorage);
    }
  }

  useStorage(storageType, setCheckKey = false) {
    if (storageType == this.storageEnum.localStorage) {
      this.selected = this.localStorage;
    } else if (storageType == this.storageEnum.sessionStorage) {
      this.selected = this.sessionStorage;
    }

    if (setCheckKey) {
      this.set(this.checkKey, true);
    }
  }

  check(key: string): boolean {
    return this.selected.check(key);
  }

  // Só use ele no logout
  clear(): void {
    this.selected.clear();
  }

  decryptData(key: string, data: any): any {
    return this.selected.decryptData(key, data);
  }

  encryptData(key: string, data: any): any {
    return this.selected.encryptData(key, data);
  }

  get(key: string): any {
    return this.selected.get(key);
  }

  remove(key: string): any {
    return this.selected.remove(key);
  }

  set(key: string, data: any) {
    this.selected.set(key, data);
  }


}
