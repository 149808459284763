import {Component, EventEmitter, Input, OnChanges, OnInit, Optional, Output, Self, ViewChild} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NgControl, NgModel} from '@angular/forms';

export const hasRequiredField = (abstractControl: AbstractControl): boolean => {
  if (abstractControl.validator) {
    const validator = abstractControl.validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
    return false;
  }
};


@Component({
  selector: 'app-real-input-field',
  templateUrl: './real-input-field.component.html',
  styleUrls: ['./real-input-field.component.scss']
})
export class RealInputFieldComponent implements ControlValueAccessor, OnInit, OnChanges {

  @Input() cssClass = '';
  @Input() id = '';
  @Input() label = '';
  @Input() name = null;
  @Input() placeholder = '';
  @Input() iconClass = null;
  // Se o icone vai ser pre ou pos
  @Input() iconClassPre = true;
  // Recebe objeto com chave valor customizado para mensagem de erro
  @Input() customErrorMessage = null;
  @Input() readOnly = false;
  @Input() disabled = false;

  // Número de casas decimais
  @Input() precision = 1;

  // Icon popover
  @Input() enablePopover = false;
  @Input() contentpopover = '';
  @Input() titlePopover = '';

  // Para o radio
  @Input() inputValue;
  checked = false;

  control;
  errorMessages;

  @Output() iconClickEvent = new EventEmitter();
  isRequired = false;
  innerValue: any;

  @ViewChild('input', {static: false}) input: NgModel;


  // Usado para mostrar se o formulário possui erro ou não
  @Input() formSubmitted = false;
  @Input() showError = true;

  constructor(
    @Self() @Optional() public controlDirective: NgControl) {
    this.controlDirective && (this.controlDirective.valueAccessor = this);
  }

  ngOnInit() {
    if (this.controlDirective) {
      this.control = this.controlDirective.control;
      this.isRequired = hasRequiredField(this.control);

      // quando tem qualquer mudança de status verifico se tem campo obrigatorio para trocar a label
      this.control.statusChanges.subscribe(() => {
        this.isRequired = hasRequiredField(this.control);
      });
    }

  }

  get value() {
    return this.innerValue;
  }

  set value(v: any) {

    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChange(v);
    }
  }

  onChange: (_: any) => void = () => {
  };
  onTouched: (_: any) => void = () => {
  };

  writeValue(obj: any): void {
    this.innerValue = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }


  ngOnChanges(changes: any): void {
  }

  iconClick() {
    this.iconClickEvent.emit('');
  }
}
