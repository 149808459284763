<div class="form-inline position-relative">
	<input
			style="padding-right: 100px;"
			class="form-control form-control-lg "
			type="text"
		    [formControl]="control"
	/>

	<button
			type="submit"
			class="btn btn-primary position-absolute"
			style="right: .4rem;top: auto;">
		<span class="d-none d-sm-block">{{'Buscar'|translate}}</span><i class="ft-search d-block d-sm-none"></i>
	</button>
</div>
