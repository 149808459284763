import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-alert',
  templateUrl: './modal-alert.component.html',
  styleUrls: ['./modal-alert.component.scss']
})
export class ModalAlertComponent implements OnInit {

  @Input('title') title: string;
  @Input('description') description: string;
  @Input('textClose') textClose: string;
  @Input('textDismiss') textDismiss: string;


  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
  }

}
