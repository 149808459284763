import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class InputControlService {

  public errorMessages = {
    required: 'Campo obrigatório',
    email: 'Formato de e-mail inválido',
    pattern: 'Formato inválido',
    typeaheadValidator: 'Escolha apenas entre os valores indicados',
    checkFullName: 'Apenas nome completoe',
    phoneNumberValidator: 'Telefone inválido',
    minlength: 'Número mínimo de caracteres é NUMBER',
    maxlength: 'Número máximo de caracteres é NUMBER',
    min: 'O valor fornecido deve ser maior ou igual a {{min}}',
    max: 'O valor fornecido deve ser menor ou igual a {{max}}',
    hasSmallCase: 'Deve conter pelo menos 1 letra minúscula',
    hasSpecialCharacters: 'Deve conter pelo menos 1 caractere especial',
    hasCapitalCase: 'Deve conter pelo menos 1 letra maiúscula',
    hasNumber: 'Deve conter pelo menos 1 número',
    dateInvalid: 'Data inválida',
    noWhiteSpaceAllowed: 'Não é permitido espaços em branco'
  };

  makeErrors(control) {
    if (control.errors) {
      const arrayErrors = Object.keys(control.errors);
      return arrayErrors;
    }
    return [];
  }

  getErrorObject(key, control) {
    return control.errors[key];
  }

  getErrorMessages(custom?) {
    if (custom) {
      // necessário pro objeto perder a referência e não modificar o objeto padrão
      const newMessages = JSON.parse(JSON.stringify(this.errorMessages));
      Object.keys(custom).forEach(key => {
        newMessages[key] = custom[key];
      });
      return newMessages;
    } else {
      return this.errorMessages;
    }
  }

}
