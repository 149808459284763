<div class="form-group {{cssClass}}">
  <label [attr.for]="id">{{label | translate}}<span class="required" *ngIf="isRequired">*</span>
  </label>
  <div class="input-group custom-datepicker">
    <div class="input-group-prepend">
          <span
            class="input-group-text"
            (click)="datePicker.open()"
            [ngClass]="{'input-button-disabled': disabled}"><i class="fa fa-calendar"></i></span>
    </div>
    <input
      class="form-control"
      [id]="id"
      (ngModelChange)="emitDate($event)"
      (click)="control.markAsTouched()"
      (focus)="control.markAsTouched()"
      [(ngModel)]="dateModel"
      [disabled]="disabled"
      ngbDatepicker
      #datePicker="ngbDatepicker"
      [restoreFocus]="false"
      [minDate]="dateMinDate"
      [maxDate]="dateMaxDate"
      [placement]="placement"
      [markDisabled]="isDisabled"
      (navigate)="navigate.emit($event)"
      rInputMask="99/99/9999"
      autocomplete="off"
      [ngClass]="{ 'is-invalid': formSubmitted && control.invalid, 'is-valid': formSubmitted && !control.invalid }">
  </div>
  <!-- <pre>{{control.value | json}}</pre> -->

  <app-form-error-messages *ngIf="showError" [control]="control" [customErrorMessage]="customErrorMessage"></app-form-error-messages>

</div>
