import {LoaderService} from '../loader/loader.service';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Page} from '../../shared/model/page';
import {finalize, map} from 'rxjs/operators';
import {PagedData} from '../../shared/model/paged-data';
import {ServerResults} from '../../shared/interfaces/server-results.interface';


@Injectable({providedIn: 'root'})
export class ServerResultsService {


  constructor(private loaderService: LoaderService,
              private http: HttpClient) {
  }

  createParams(page, filter) {
    let params = {};
    params['itemsPerPage'] = page.size;
    params['page'] = page.pageNumber;

    if (Array.isArray(filter.sortProperties)) {
      params['sortProperties'] = filter.sortProperties;
    } else {
      params['sortProperties'] = [];
    }


    if (filter.filters) {
      params['filters'] = filter.filters;
    }

    return params;
  }

  getResults(page: Page, path: string, filter?: any, params: any = {}, showLoader = true) {
    params = this.createParams(page, filter);


    let options = {};
    if (!showLoader) {
      options = {headers: {'hidden-loader': 'false'}};
    }

    return this.http.post<ServerResults>(path, params, options).pipe(map((data) => {
      const retorno = this.getPagedData(page, data);
      return retorno;
    }), finalize(() => {

    }));

  }

  private getPagedData(page: Page, data) {
    const pagedData = new PagedData();

    page.totalElements = data.totalElements;
    page.totalPages = data.totalPages;

    pagedData.data['columns'] = data['columns'];
    pagedData.data['rows'] = data['content'];
    pagedData.page = page;

    return pagedData;
  }
}
