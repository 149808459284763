/**
 * An array of data with an associated page object used for paging
 */
import {ServerResults} from '../interfaces/server-results.interface';
import {Page} from './page';


export class PagedData {
    data: ServerResults = {columns: [], rows: []};
    page = new Page();
}
