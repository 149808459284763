import {Injectable} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ModalAlertComponent} from '../../shared/components/modal-alert/modal-alert.component';
import {FormGroup} from '@angular/forms';
import {ModalAddressComponent} from '../../shared/components/modal-address/modal-address.component';
import {ModalWarningComponent} from '../../shared/components/modal-warning/modal-warning.component';


@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(
    private modalService: NgbModal) {
  }

  public dismissAll() {
    this.modalService.dismissAll();
  }

  public modalWarningControl(description, title?, textDismiss?) {

    const modalAlertComponentRef = this.modalService.open(ModalWarningComponent, {
      centered: true,
      windowClass: 'height-auto',
      backdrop: 'static'
    });

    if (typeof description == 'object') {
      description.subscribe(data => modalAlertComponentRef.componentInstance.description = data);
    } else {
      modalAlertComponentRef.componentInstance.description = description;
    }

    if (!(typeof title == 'undefined')) {
      if (typeof title == 'object') {
        title.subscribe(data => modalAlertComponentRef.componentInstance.title = data);
      } else {
        modalAlertComponentRef.componentInstance.title = title;
      }
    } else {
      modalAlertComponentRef.componentInstance.title = 'Atenção';
    }

    if (!(typeof textDismiss == 'undefined')) {
      if (typeof textDismiss == 'object') {
        textDismiss.subscribe(data => modalAlertComponentRef.componentInstance.textDismiss = data);
      } else {
        modalAlertComponentRef.componentInstance.textDismiss = textDismiss;
      }
    } else {
      modalAlertComponentRef.componentInstance.textDismiss = 'OK';
    }
  }


  public modalAlertControl(description, title?, textClose?, textDismiss?, callBack?) {

    const modalAlertComponentRef = this.modalService.open(ModalAlertComponent, {
      centered: true,
      windowClass: 'height-auto',
      backdrop: 'static'
    });

    if (typeof description == 'object') {
      description.subscribe(data => modalAlertComponentRef.componentInstance.description = data);
    } else {
      modalAlertComponentRef.componentInstance.description = description;
    }

    if (!(typeof title == 'undefined')) {
      if (typeof title == 'object') {
        title.subscribe(data => modalAlertComponentRef.componentInstance.title = data);
      } else {
        modalAlertComponentRef.componentInstance.title = title;
      }
    } else {
      modalAlertComponentRef.componentInstance.title = 'Alerta';
    }

    modalAlertComponentRef.componentInstance.textClose = textClose;

    modalAlertComponentRef.componentInstance.textDismiss = textDismiss;

    modalAlertComponentRef.result.then(() => {
      if (typeof callBack != 'undefined') {
        callBack();
      }
    }, () => {
    });

  }

  public modalGeneric(component, centered?, size?, windowsClass?, backdrop?, params?, callBack?, dismissCallBack?): NgbModalRef {
    const modalAlertComponentRef = this.modalService.open(component, {
      centered: centered,
      size: size,
      windowClass: windowsClass,
      backdrop: backdrop,
      scrollable: true
    });

    if (typeof params != 'undefined' && params != null) {
      for (let i = 0; i < params.length; i++) {
        modalAlertComponentRef.componentInstance[params[i].index] = params[i].value;
      }
    }


    modalAlertComponentRef.result.then((data) => {
      if (typeof callBack != 'undefined') {
        callBack(data);
      }
    }, (data) => {
      if (typeof dismissCallBack != 'undefined') {
        dismissCallBack(data);
      }

    });


    return modalAlertComponentRef;
  }

  public modalAdress(form: FormGroup, data?) {
    this.modalGeneric(ModalAddressComponent, true, 'xl', '', 'static',
      [
        {index: 'data', value: data}
      ], address => {
        if (address) {
          form.patchValue(address);
        }
      });
  }

}
