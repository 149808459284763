<div class="modal-header">

  <h3 class="font verde">{{title | translate}}</h3>
  <hr>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <app-feather-icons [icon]="'x'"></app-feather-icons>

  </button>


</div>


<div class="modal-body custom-datatable datatable-checkbox">
  <fieldset class="form-group">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="d-flex justify-content-sm-between align-items-center">
        <div class="form-inline position-relative">
          <input style="padding-right: 100px;" class="form-control form-control-lg " type="text"
                 formControlName="textSearch"/>
          <button type="submit" class="btn btn-primary  position-absolute"
                  style="right: .4rem;top: auto;">
            <span class="d-none d-sm-block">{{'Buscar'|translate}}</span><i class="ft-search d-block d-sm-none"></i>
          </button>
        </div>

      </div>
    </form>
  </fieldset>

  <ngx-datatable
    class="bootstrap core-bootstrap"
    [rows]="pagedData.data.rows"
    [columnMode]="'force'"
    [headerHeight]="50"
    [footerHeight]="50"
    [rowHeight]="50"
    [selected]="allSelectedForComponent"
    (select)="onSelect($event)"
    [messages]="messages"
    [selectionType]="selectionType.checkbox"
    [externalPaging]="true"
    (page)='onSetPage($event.offset)'
    [externalSorting]="true"
    [count]="pagedData.page.totalElements"
    [offset]="pagedData.page.pageNumber"
    [limit]="pagedData.page.size"
    [rowIdentity]="getId"
  >

    <ngx-datatable-column
      [width]="30"
      [sortable]="false"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="false">
      <ng-template ngx-datatable-header-template let-value="value">
        <input type="checkbox" [checked]="selectAllOnPage[pagedData.page.pageNumber]" (change)="selectAll($event)"/>
      </ng-template>

      <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected"
                   let-onCheckboxChangeFn="onCheckboxChangeFn">
        <input type="checkbox" [checked]="isSelected" (change)="onCheckboxChangeFn($event)"/>
      </ng-template>
    </ngx-datatable-column>


    <ngx-datatable-column *ngFor="let column of columns" name="{{column.name | translate}}"
                          [prop]="column.prop" [pipe]="column?.pipe"
                          [cellTemplate]="column?.cellTemplate"
                          [minWidth]="column?.minWidth"
                          [sortable]="column?.sortable"></ngx-datatable-column>
  </ngx-datatable>
</div>


<div class="modal-footer">
  <button type="button" class="btn btn-primary mb-1" (click)="finalSelection()">{{"Selecionar" | translate}}</button>
</div>
