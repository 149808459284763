import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';

@Injectable({ providedIn: 'root'})
export class BookmarkService {
    bookmarks = new BehaviorSubject([]);

    setBookMark(bookmark) {
        this.bookmarks.next(bookmark);
    }

    clearBookMark() {
        this.bookmarks.next([]);
    }
}
