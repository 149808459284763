<ngx-datatable
  #datatable
  class="bootstrap core-bootstrap"
  [rows]="rows"
  [columns]="columns"
  [columnMode]="'force'"
  [headerHeight]="50"
  [footerHeight]="50"
  [rowHeight]="'auto'"
  [externalPaging]="true"
  [externalSorting]="true"
  [count]="page.totalElements"
  [offset]="page.pageNumber"
  [limit]="page.size"
  [messages]="message"
  (page)='setPage($event)'
  (activate)="setActivate($event)"
  (sort)="setSort($event)"
  [reorderable]="reorderable"
  [sortType]="sortType"
  [scrollbarH]="true">

  <ngx-datatable-column *ngFor="let column of columns" name="{{column.name | translate}}"
                        [prop]="column.prop" [pipe]="column?.pipe"
                        [cellTemplate]="column?.cellTemplate"
                        [cellClass]="column?.cellClass"
                        [headerClass]="column?.headerClass"
                        [minWidth]="column?.minWidth"
                        [sortable]="column?.sortable"></ngx-datatable-column>

  <ngx-datatable-column [headerClass]="'text-center'" [cellClass]="'justify-content-center'" [sortable]="false"  *ngIf="showActions" name="{{'Ações'|translate}}" minWidth="70">
    <ng-template ngx-datatable-cell-template let-row="row">
      <i class="ft-edit text-primary cursor-pointer" title="{{actionLabel|translate}}" (click)="onEdit(row)"></i>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
<ng-content></ng-content>

