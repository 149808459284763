import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {UtilsService} from '../services/utils.service';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {

  constructor(private translateService: TranslateService,
              private utilsService: UtilsService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.utilsService.checkAddHeader(req.url)) {
      let lang = (typeof this.translateService.currentLang == 'undefined' ? this.translateService.getBrowserLang() : this.translateService.currentLang);
      req = req.clone({headers: req.headers.set('Accept-Language', lang)});
    }


    return next.handle(req);
  }
}
