import {Component, Input, OnInit, Optional, Self} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NgControl} from '@angular/forms';
import {NgbDatePtParserFormatterService} from '../../../core/services/ngb-date-pt-parser-formatter.service';

// const TIME_PICKER_VALUE_ACESSOR: any = {
//   provide: NG_VALUE_ACCESSOR,
//   useExisting: forwardRef(() => TimePickerComponent),
//   multi: true
// };

export const hasRequiredField = (abstractControl: AbstractControl): boolean => {
  if (abstractControl.validator) {
    const validator = abstractControl.validator({}as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
    return false;
  }
};

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements ControlValueAccessor, OnInit {


  @Input() cssClass = '';
  @Input() id = '';
  @Input() label = '';
  @Input() hasSeconds = false;
  @Input() meridian = false;
  @Input() hourStep = 1;
  @Input() minuteStep = 15;
  @Input() secondStep = 30;
  @Input() spinners = true;
  @Input() readOnly = false;
  @Input() disable = false;
  @Input() showRequiredLabel = true;
  // Recebe objeto com chave valor customizado para mensagem de erro
  @Input() customErrorMessage = null;
  isRequired = false;
  timeModel = {};
  control;



  constructor(
    @Self() @Optional() public controlDirective: NgControl,
    public ngbDatePtParserFormatterService: NgbDatePtParserFormatterService) {
    controlDirective.valueAccessor = this;
  }

  ngOnInit() {
    this.control = this.controlDirective.control;
    this.isRequired = hasRequiredField(this.control);
    // quando tem qualquer mudança de status verifico se tem campo obrigatorio para trocar a label
    this.control.statusChanges.subscribe(() => {
      this.isRequired = hasRequiredField(this.control);
    });
  }

  onChangeCb: (_: any) => void = () => {
  };
  onTouchedCb: (_: any) => void = () => {
  };

  writeValue(v: any): void {
    if (v) {
      this.timeModel = this.ngbDatePtParserFormatterService.fromModelOnlyTime(v);
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
  }

  emitTime(event) {
    if (event) {
      this.onChangeCb(this.ngbDatePtParserFormatterService.getOnlyTime(event));
    }
  }

}
