import {Component, ElementRef, EventEmitter, Input, OnInit, Optional, Output, Self, ViewChild} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NgControl} from '@angular/forms';

import {CrudService} from '../../../core/services/crud.service';
import {DomSanitizer} from '@angular/platform-browser';
import {UtilsService} from '../../../core/services/utils.service';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';

export const hasRequiredField = (abstractControl: AbstractControl): boolean => {
  if (abstractControl.validator) {
    const validator = abstractControl.validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
    return false;
  }
};


@Component({
  selector: 'app-single-upload-button',
  templateUrl: './single-upload-button.component.html',
  styleUrls: ['./single-upload-button.component.scss']
})
export class SingleUploadButtonComponent implements ControlValueAccessor, OnInit {

  @Input() label = '';
  isRequired = false;
  control;
  @Input() disabled = false;
  @Input() title: string = 'Upload';
  @Input() allowedExtensions = ['png', 'jpg', 'jpeg'];
  accept = '.png,.jpg,.jpeg';
  @Input() maxFileSize = 20;
  @Input() recommendationMessage = 'A imagem deve ter no máximo 20Mb, nos formatos png, jpg ou jpeg';


  // Path do upload do botão
  @Input() uploadPath = 'files/upload';
  @Input() downloadPath = '';
  @Input() uploadKey = 'file';

  // Emite evento quando objeto do servidor puder ser retornado
  @Output() readyToSave = new EventEmitter();
  path = '';


  //Objeto file
  objctFile = null;
  // Se mostro ou não informações do arquivo selecionado.... para o componente poder ser reutilizado
  @Input() showFileInfo = true;


  // Usado para mostrar se o formulário possui erro ou não
  @Input() formSubmitted = false;
  @Input() showError = true;
  // Recebe objeto com chave valor customizado para mensagem de erro
  @Input() customErrorMessage = null;


  @ViewChild('myInputFile') myInputFile: ElementRef;


  @Input() s3Upload = false;

  constructor(
    @Self() @Optional() public controlDirective: NgControl,
    private utilsService: UtilsService,
    private crudService: CrudService,
    private sanitizer: DomSanitizer) {
    this.controlDirective && (this.controlDirective.valueAccessor = this);
  }

  ngOnInit() {
    if (this.controlDirective) {
      this.control = this.controlDirective.control;
      this.isRequired = hasRequiredField(this.control);


      // quando tem qualquer mudança de status verifico se tem campo obrigatorio para trocar a label
      this.control.statusChanges.subscribe(() => {
        this.isRequired = hasRequiredField(this.control);
      });
    }

    this.accept = this.allowedExtensions.map(value => {
      return '.' + value
    }).join(',');

  }

  onChangeCb: (_: any) => void = () => {
  };
  onTouchedCb: (_: any) => void = () => {
  };

  onChangeFile(event: any) {
    if (this.utilsService.checkValidateUpload(event, this.maxFileSize, this.allowedExtensions)) {
      const file = event.target.files[0];

      if (this.s3Upload) {
        this.uploadToS3(file).then();
      } else {
        this.uploadToServer(file);
      }


    }
  }

  uploadToServer(file) {
    const formData = new FormData();
    if (typeof file != 'undefined') {
      formData.append(this.uploadKey, file, file.name);
    }
    this.crudService.post(this.uploadPath, formData).subscribe(data => {
      this.onChangeCb(data);
      this.objctFile = data;
      // this.downloadImg();
    });
  }

  async uploadToS3(file) {
    const data: any = await this.crudService.post(`cloud-files/upload`, {
      fileName: file.name,
    }, true).toPromise();

    this.utilsService.uploadToS3(data.url, file).pipe(catchError((err) => {
      // this.onImageLoad();
      this.myInputFile.nativeElement.value = '';
      return throwError(err);
    })).subscribe((s3Data) => {
      // this.form.get('pictureId').patchValue(data.id);
      // this.onFileChange.emit(data.id);
      // this.imageSrc = data.s3Url
      // this.fileUpload.nativeElement.value = '';
      //
      // this.onChange(data.id);

      this.objctFile = data;
      this.objctFile.originalName = data.fileName;
      this.onChangeCb(data);
    });
  }


  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.objctFile = obj;

    if (obj == null && this.myInputFile) {
      this.myInputFile.nativeElement.value = '';
    }
  }

  // downloadImg() {
  //   this.crudService.getBlob(this.downloadPath + '/' + this.objctFile['id']).subscribe(res => {
  //
  //     const reader = new FileReader();
  //     reader.readAsDataURL(res.body);
  //     reader.onloadend = () => {
  //       const result = reader.result.toString().replace('application/octet-stream', 'image/' + this.objctFile['extension']);
  //       this.imgSrc = this.sanitizer.bypassSecurityTrustUrl(result);
  //     };
  //   });
  // }

  remove() {
    this.objctFile = null;
    this.onChangeCb(null);
  }

}
