import {Pipe, PipeTransform} from '@angular/core';
import {Helper} from '../model/helper';


@Pipe({
  name: 'addressPipe'
})
export class AddressPipe implements PipeTransform {
  private static simpleAddress(value) {
    return (value['name'] ? value['name'] + ' - ' : '')
      + value['street'] +
      (value['city'] ? value['city']['name'] ? ' - ' + value['city']['name'] : '' : '') +
      (value['state'] ? value['state']['name'] ? ' - ' + value['state']['name'] : '' : '');
  }

  private static fullAddress(value) {
    return (value['name'] ? value['name'] + ' - ' : '')
      + value['street'] +
      ( Helper.objectValid(value['number']) ? ', ' + value['number'] : '') +
      ' - ' + value['neighborhood'] +
      (value['city'] ? value['city']['name'] ? ' - ' + value['city']['name'] : '' : '') +
      (value['state'] ? value['state']['name'] ? ' - ' + value['state']['name'] : '' : '') +
      ' (' + value['zipCode'] + ')';
  }

  transform(value: any, format?: string, notDefinedLabel?: string): any {
    if (Helper.objectValid(value) && Helper.objectValid(value.street)) {
      switch (format) {
        case 'simple': return AddressPipe.simpleAddress(value);
        default: return AddressPipe.fullAddress(value);
      }
    } else {
      return notDefinedLabel || '';
    }
  }

}
