import {Component, OnInit} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {LoaderService} from './loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  show = false;

  constructor(private loaderService: LoaderService,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.loaderService.show.subscribe((show: boolean) => {
      setTimeout(() => {
        if (show) {
          this.spinner.show();
          // this.spinner.show(undefined,
          //   {
          //     type: 'ball-triangle-path',
          //     size: 'medium',
          //     bdColor: 'rgba(0, 0, 0, 0.8)',
          //     color: '#fff',
          //     fullScreen: true
          //   });
        } else {
          this.spinner.hide();
        }
        this.show = show;
      });
    });

  }
}
