<div class="modal-header">
  <h3 class="modal-title">{{'Endereço' | translate}}</h3>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
<!--    <app-feather-icons [icon]="'x'"></app-feather-icons>-->
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form" class="theme-form">
    <div class="form-row">

      <div class="row">
        <app-input-field class="col-6" id="zipCode" label="CEP"
                         formControlName="zipCode" mask="00000-000"
                         maskValidation="true"
                         [formSubmitted]="formSubmitted"
                         [customErrorMessage]="{invalidMask: 'Invalid zip code'}"

        ></app-input-field>

        <div class="col-6" style="top: 24px;">
          <button (click)="searchZipCode()" class="btn btn-success">{{'Buscar'|translate}}
          </button>
        </div>
      </div>

      <div class="row">
        <app-input-field class="col-md-5" id="street" label="Logradouro"
                         [formSubmitted]="formSubmitted"
                         formControlName="street"></app-input-field>

        <app-input-field class="col-md-2" id="number" label="Número"
                         [formSubmitted]="formSubmitted"
                         formControlName="number"></app-input-field>

        <app-input-field class="col-md-5" id="additionalInformation"
                         [formSubmitted]="formSubmitted"
                         label="Complemento" formControlName="additionalInformation"></app-input-field>

        <app-input-field class="col-md-4" id="neighborhood" label="Bairro"
                         [formSubmitted]="formSubmitted"
                         formControlName="neighborhood"></app-input-field>

        <app-input-field class="col-md-4" id="state" label="Estado"
                         [formSubmitted]="formSubmitted"
                         formControlName="stateName"></app-input-field>

        <app-input-field class="col-md-4" id="city" label="Cidade"
                         [formSubmitted]="formSubmitted"
                         formControlName="cityName"></app-input-field>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button class="btn btn-primary mr-1" (click)="onSubmit()">{{'Salvar' | translate}}</button>
  <button class="btn btn-secondary" (click)="activeModal.dismiss()">{{'Cancelar' | translate}}</button>
</div>
<!-- <div class="modal-footer">
</div> -->
