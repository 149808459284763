import {Component, Input, OnInit} from '@angular/core';
import {AdvogadosStatusEnumToNameService} from '../../enum-to-name-services/advogados-status-enum-to-name.service';

@Component({
  selector: 'app-advogados-list-status',
  templateUrl: './advogados-list-status.component.html',
  styleUrls: ['./advogados-list-status.component.scss']
})
export class AdvogadosListStatusComponent implements OnInit {

  @Input() status = null;
  enumArray = [];

  constructor(private advogadosStatusEnumToNameService: AdvogadosStatusEnumToNameService) {
    this.enumArray = this.advogadosStatusEnumToNameService.get()
  }

  ngOnInit(): void {
  }

}
