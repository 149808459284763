import {ErrorHandler, Injectable} from '@angular/core';

// import * as Sentry from '@sentry/browser';


@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor() {
  }

  handleError(error) {
    console.log(error);
    // const eventId = Sentry.captureException(error.originalError || error);
    // Sentry.showReportDialog({eventId: eventId, lang: 'pt'});
  }
}
