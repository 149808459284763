import {Component, Input, OnInit} from '@angular/core';
import {AreaAtuacaoStatusEnumToNameService} from '../../enum-to-name-services/area-atuacao-status-enum-to-name.service';
import {CupomDescontoStatusEnumToNameService} from '../../enum-to-name-services/cupom-desconto-status-enum-to-name.service';

@Component({
  selector: 'app-cupom-desconto-list-status',
  templateUrl: './cupom-desconto-list-status.component.html',
  styleUrls: ['./cupom-desconto-list-status.component.scss']
})
export class CupomDescontoListStatusComponent implements OnInit {

  @Input() status = null;
  enumArray = [];
  constructor(private cupomDescontoStatusEnumToNameService: CupomDescontoStatusEnumToNameService) {
    this.enumArray = this.cupomDescontoStatusEnumToNameService.get()
  }

  ngOnInit(): void {
  }

}
