<ng-container *ngIf="!editing; else updateBlockShow">
  <button *appPermission="actionEnumSaveButton; target: target"
          class="btn btn-primary mr-1 mb-1"
          (click)="onSubmit.emit()"><i
    class="ft-check-square mr-1"></i>{{'Salvar' | translate}}</button>
</ng-container>
<ng-template #updateBlockShow>
  <button *appPermission="actionEnumSaveButtonEditing; target: target"
          class="btn btn-primary mr-1 mb-1"
          (click)="onSubmit.emit()"><i
    class="ft-check-square mr-1"></i>{{'Salvar' | translate}}</button>
</ng-template>

<button *ngIf="enableCancelButton" class="btn btn-secondary mb-1" (click)="cancel.emit()"><i class="ft-x mr-1"></i>{{'Cancelar' | translate}}
</button>
