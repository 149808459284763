import {Component, Input, OnInit} from '@angular/core';
import {VagaStatusEnumToNameService} from '../../enum-to-name-services/vaga-status-enum-to-name.service';
import {CompetenciaStatusEnumToNameService} from '../../enum-to-name-services/competencia-status-enum-to-name.service';

@Component({
  selector: 'app-competencias-list-status',
  templateUrl: './competencias-list-status.component.html',
  styleUrls: ['./competencias-list-status.component.scss']
})
export class CompetenciasListStatusComponent implements OnInit {

  @Input() status = null;
  enumArray = [];

  constructor(private competenciaStatusEnumToNameService: CompetenciaStatusEnumToNameService) {
    this.enumArray = this.competenciaStatusEnumToNameService.get()
  }

  ngOnInit(): void {
  }

}
