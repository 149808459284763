import {Component, Input, OnInit, Optional, Self} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NgControl} from '@angular/forms';


export const hasRequiredField = (abstractControl: AbstractControl): boolean => {
  if (abstractControl.validator) {
    const validator = abstractControl.validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
    return false;
  }
};
@Component({
  selector: 'app-input-textarea',
  templateUrl: './input-textarea.component.html',
  styleUrls: ['./input-textarea.component.scss']
})
export class InputTextareaComponent implements ControlValueAccessor, OnInit {

  @Input() cssClass = '';
  @Input() id = '';
  @Input() label = '';
  @Input() disabled = false;
  @Input() customErrorMessage = null;
  @Input() showLength = true;
  @Input() maxLength = 2000;

  // Icon popover
  @Input() enablePopover = false;
  @Input() contentpopover = '';
  @Input() titlePopover = '';

  control;

  isRequired = false;
  private innerValue: any;

  // Usado para mostrar se o formulário possui erro ou não
  @Input() formSubmitted = false;

  // Para desabilitar o resize do textarea
  @Input() disableResize = false;

  constructor(
    @Self() @Optional() public controlDirective: NgControl) {
    controlDirective.valueAccessor = this;
  }

  ngOnInit() {
    this.control = this.controlDirective.control;
    this.isRequired = hasRequiredField(this.control);
    // quando tem qualquer mudança de status verifico se tem campo obrigatorio para trocar a label
    this.control.statusChanges.subscribe(() => {
      this.isRequired = hasRequiredField(this.control);
    });
  }

  get value() {
    return this.innerValue;
  }

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCb(v);
    }
  }


  onChangeCb: (_: any) => void = () => {
  };
  onTouchedCb: (_: any) => void = () => {
  };

  writeValue(v: any): void {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCb(v);
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }


}
