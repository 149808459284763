<div class="form-group {{cssClass}}">
    <label *ngIf="showRequiredLabel"  [attr.for]="id">{{label | translate}}<span class="required" *ngIf="isRequired">*</span>
    </label>
    <div class="input-group custom-datepicker">

        <ngb-timepicker
        class="time-picker-custom"
        [id]="id"
        [hourStep]="hourStep"
        (click)="control.markAsTouched()"
        (keydown)="control.markAsTouched()"
        [minuteStep]="minuteStep"
        [secondStep]="secondStep"
        [spinners]="spinners"
        (ngModelChange)="emitTime($event)"
        [(ngModel)]="timeModel"
        [meridian]="meridian">
        </ngb-timepicker>
    </div>
    <!-- <pre>{{control.value | json}}</pre> -->
    <app-form-error-messages [control]="control" [customErrorMessage]="customErrorMessage"></app-form-error-messages>

  </div>
