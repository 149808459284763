import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Injectable({providedIn: 'root'})
export class ToastService {

  constructor(
    private toastrService: ToastrService,
    private translateService: TranslateService) {

  }

  sendSuccess(msg, title = '', close: boolean = true, timeout: number = 3000) {
    this.translateService.get([msg, title]).subscribe(data => {
      this.toastrService.success(data[msg] ? data[msg] : msg, data[title] ? data[title] : title, {closeButton: close, timeOut: timeout});
    });
  }

  sendWarning(msg, title = '', close: boolean = true, timeout: number = 3000, interpolateParams?: Object) {
    this.translateService.get([msg, title], interpolateParams).subscribe(data => {
      this.toastrService.warning(data[msg] ? data[msg] : msg, data[title] ? data[title] : title, {closeButton: close, timeOut: timeout});
    });
  }

  sendDanger(msg, title = '', close: boolean = true, timeout: number = 3000, interpolateParams?: Object) {
    this.translateService.get([msg, title], interpolateParams).subscribe(data => {
      this.toastrService.error(data[msg] ? data[msg] : msg, data[title] ? data[title] : title, {closeButton: close, timeOut: timeout});
    });
  }

  sendInfo(msg, title = '', close: boolean = true, timeout: number = 3000) {
    this.translateService.get([msg, title]).subscribe(data => {
      this.toastrService.info(data[msg] ? data[msg] : msg, data[title] ? data[title] : title, {closeButton: close, timeOut: timeout});
    });
  }

}
