import {Injectable} from '@angular/core';
import {EmpresaStatusEnum} from '../enums/empresa-status.enum';
import {AdvogadosStatusEnum} from '../enums/advogados-status.enum';

@Injectable({
    providedIn: 'root'
})

export class AdvogadosStatusEnumToNameService {
    constructor() {
    }

    get() {
        return [
            {name: 'ATIVO', value: AdvogadosStatusEnum.ATIVO},
            {name: 'INATIVO', value: AdvogadosStatusEnum.INATIVO},
        ]
    }
}
