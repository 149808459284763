import {Injectable} from '@angular/core';
import {PermissionService} from './permission.service';
import {HROUTES} from '../horizontal-menu/navigation-routes.config';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  constructor(private permissionService: PermissionService) {
  }

  getHROUTES() {
    HROUTES.forEach(menuItem => {
      this.checkRoles(menuItem);
    });
    // HROUTES.forEach(data => {
    //   if (data.submenu && data.submenu.length > 0) {
    //     let show = false;
    //     data.submenu.forEach(menuItem => {
    //       if (menuItem['show']) {
    //         show = true;
    //       }
    //     });
    //     data['show'] = show;
    //   }
    // });
    HROUTES.map(a => {
      if (a.submenu.length > 0) {
        const hasShow = a.submenu.find(b => b['show'] == true);
        if (!hasShow) {
          a['show'] = false;
        }
      }
      return a;
    });
    return HROUTES;
  }

  checkRoles(menuItem) {
    // Verifico as roles independente do tipo do usuário
    if (!menuItem.targetResource) {
      menuItem['show'] = true;
    } else {
      menuItem['show'] = this.permissionService.hasPermission(menuItem.targetResource);
    }

    // Mas o tipo do usuário tem precedencia
    if (menuItem.profilesType) {
      menuItem['show'] = this.permissionService.hasProfile(menuItem.profilesType);
    }
    ;

    if (menuItem.submenu && menuItem.submenu.length > 0) {
      menuItem.submenu.forEach(element => {
        this.checkRoles(element);
      });
    }
    ;
  };


}
