<div class="form-group">
  <label>{{label | translate}}<span class="required" *ngIf="isRequired">*</span></label>
  <div>
    <div class="container-upload">
      <button
        style="display: flex; justify-content: center; align-items: center;"
        type="button"
        [disabled]="disabled"
        class="btn btn-primary w-100"
        (click)="myInputFile.click()">
        <span class="mr-2">{{title | translate}}</span>
        <app-feather-icons class="ml-2" [icon]="'upload-cloud'"></app-feather-icons>
      </button>
      <input
        style="display: none;"
        (change)="onChangeFile($event)"
        type="file"
        [accept]="accept"
        #myInputFile/>
    </div>
    <span class="recommendation-message">{{recommendationMessage | translate}}</span>
    <ng-container *ngIf="showFileInfo">
      <span *ngIf="!objctFile" class="not-file">{{'Arquivo não selecionado' | translate}}</span>


      <div class="container-box" *ngIf="objctFile">
        <div class="content-container">
          {{objctFile.originalName}}
          <div class="remove" (click)="remove()">x</div>
        </div>
      </div>
    </ng-container>

    <app-form-error-messages *ngIf="showError" [control]="control"
                             [customErrorMessage]="customErrorMessage"></app-form-error-messages>

  </div>
</div>
