<div class="form-group {{cssClass}}">
  <label *ngIf="label.length>0" [attr.for]="id">{{label | translate}}<span class="required" *ngIf="isRequired">*</span>
  </label>
  <app-icon-popover
    *ngIf="enablePopover"
    [contentpopover]="contentpopover"
    [titlePopover]="titlePopover"
  ></app-icon-popover>
  <div>
    <ng-select
      class="form-control"
      [multiple]="multiple"
      [id]="id"
      [items]="items"
      [disabled]="disable"
      [formControl]="control"
      [closeOnSelect]="closeOnSelect"
      [hideSelected]="hideSelected"
      [bindLabel]=optionKey
      [bindValue]="valueKey"
      placeholder="{{placeholder| translate}}"
      [compareWith]="compareFn.bind(this)"
      [notFoundText]="'Nenhum item encontrado'|translate"
      [ngClass]="{ 'is-invalid': formSubmitted && control.invalid, 'is-valid': formSubmitted && !control.invalid }">

      <ng-template ng-option-tmp let-item="item">
        <div title="{{item[optionKey]|translate}}">{{item[optionKey]|translate}}</div>
      </ng-template>
    </ng-select>
  </div>

  <app-form-error-messages *ngIf="showError" [control]="control"
                           [customErrorMessage]="customErrorMessage"></app-form-error-messages>
</div>
