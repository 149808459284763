import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {NgbDateParserFormatter, NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import {ToastrModule} from 'ngx-toastr';
import {HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';

import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';

import {AppRoutingModule} from './app-routing.module';
import {SharedModule} from './shared/shared.module';
import {AppComponent} from './app.component';
import {ContentLayoutComponent} from './layouts/content/content-layout.component';
import {FullLayoutComponent} from './layouts/full/full-layout.component';

import {WINDOW_PROVIDERS} from './shared/services/window.service';
import {HttpLoaderFactory} from './core/core.module';
import {CustomDatepickerI18n} from './shared/services/custom-datepicker-i18n.service';
import {NgbDatePtParserFormatterService} from './core/services/ngb-date-pt-parser-formatter.service';
import {UrlInterceptor} from './core/interceptors/url.interceptor';
import {HttpErrorInterceptor} from './core/error/http-error.interceptor';
import {AuthInterceptor} from './core/interceptors/auth.interceptor';
import {LoaderInterceptor} from './core/interceptors/loader.interceptor';
import {LanguageInterceptor} from './core/interceptors/language.interceptor';
import {HomeComponent} from './pages/full-pages/home/home.component';
import {BrowserModule} from '@angular/platform-browser';
import {GlobalErrorHandler} from './core/error/global-error-handler';
import { ConfiguracoesEmpresaComponent } from './pages/full-pages/configuracoes-empresa/configuracoes-empresa.component';
import { UsuariosJudclassListComponent } from './pages/full-pages/usuarios-judclass/usuarios-judclass-list/usuarios-judclass-list.component';
import { UsuariosJudclassItemComponent } from './pages/full-pages/usuarios-judclass/usuarios-judclass-item/usuarios-judclass-item.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelPropagation: false
};

@NgModule({
    declarations: [AppComponent, FullLayoutComponent, ContentLayoutComponent, HomeComponent, ConfiguracoesEmpresaComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        SharedModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            isolate: false
        }),


        ToastrModule.forRoot()

    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: UrlInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
        {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
        {provide: NgbDateParserFormatter, useClass: NgbDatePtParserFormatterService},
        {provide: ErrorHandler, useClass: GlobalErrorHandler},
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        {provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG},
        WINDOW_PROVIDERS
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
