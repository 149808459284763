<div class="form-group {{cssClass}}">

  <label class="container-checkbox indeterminate {{themeColor}} d-flex align-items-center">
    <input
      [(ngModel)]="value"
      [id]="id"
      (blur)="_onTouched()"
      (change)="emitChange()"
      type="checkbox"
      #inderterminateCheckbox>
    <span class="checkmark mr-2"></span>
    {{label | translate}}<span class="required " *ngIf="isRequired">*</span>
  </label>

  <app-form-error-messages [control]="control" [customErrorMessage]="customErrorMessage"></app-form-error-messages>
  <!-- <pre>{{control.value | json}}</pre> -->
</div>
