import {Component, EventEmitter, Input, OnInit, Optional, Output, Self} from '@angular/core';
import {SelectListComponent} from '../select-list/select-list.component';

import {AbstractControl, ControlValueAccessor, NgControl} from '@angular/forms';
import {MultipleCheckListComponent} from '../multiple-check-list/multiple-check-list.component';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ModalService} from '../../../core/services/modal.service';

export const hasRequiredField = (abstractControl: AbstractControl): boolean => {
  if (abstractControl.validator) {
    const validator = abstractControl.validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
    return false;
  }
};

@Component({
  selector: 'app-input-modal',
  templateUrl: './input-modal.component.html',
  styleUrls: ['./input-modal.component.scss']
})
export class InputModalComponent implements ControlValueAccessor, OnInit {

  @Input() cssClass = '';
  @Input() id = '';
  @Input() label = '';
  @Input() placeholder = '';
  @Input() type: 'select-list' | 'multiple-checklist' = 'select-list';
  @Input() iconClass = null;
  @Input() disabled = false;
  @Input() selectData = null;
  /* Chave do objeto correspondente a view do input */
  @Input() objectKey = 'nome';
  @Input() customErrorMessage = null;
  /* Pode receber um pipe customizado para dados mostrados no input */
  @Input() pipe = {transform: this.pipeDefault.bind(this)};
  isRequired = false;
  control;
  @Input() badges = false;

  value = '';
  @Output() iconClickEvent = new EventEmitter();

  // Referencia para a modal aberta
  modalReference: NgbModalRef;

  // Usado para mostrar se o formulário possui erro ou não
  @Input() formSubmitted = false;


  constructor(
    @Self() @Optional() public controlDirective: NgControl,
    private modalAlertService: ModalService) {
    controlDirective.valueAccessor = this;
  }

  ngOnInit() {
    this.control = this.controlDirective.control;

    if (this.control['value'] != null) {
      this.value = this.pipe.transform(this.control['value']);
    }

    // this.control.valueChanges.subscribe(data => {
    //   this.value = this.pipe.transform(data);
    // });
    this.isRequired = hasRequiredField(this.control);

    // quando tem qualquer mudança de status verifico se tem campo obrigatorio para trocar a label
    this.control.statusChanges.subscribe(() => {
      this.isRequired = hasRequiredField(this.control);
    });
  }

  onChangeCb: (_: any) => void = () => {
  };
  onTouchedCb: (_: any) => void = () => {
  };

  writeValue(v: any): void {
    this.value = this.pipe.transform(v);
  }

  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  openModal() {
    if (this.selectData != null) {
      this.selectData.map(data => {
        if (data.index == 'selected') {
          data.value = this.control.value;
        }
      });
    }

    if (this.type == 'select-list') {
      this.modalReference = this.modalAlertService.modalGeneric(SelectListComponent, true, 'xl', 'with-filter', 'static',
        this.selectData,
        (data) => {
          this.value = this.pipe.transform(data);
          this.onChangeCb(data);
        });
    } else if (this.type == 'multiple-checklist') {
      this.modalReference = this.modalAlertService.modalGeneric(MultipleCheckListComponent, true, 'xl', '', 'static',
        this.selectData,
        (data) => {
          this.value = this.pipe.transform(data);
          this.onChangeCb(data);
        });
    }
  }

  pipeDefault(data) {
    let result = '';

    if (data && Array.isArray(data)) {
      data.forEach(v => {
        if (result != '') {
          result += ', ';
        }
        if (v[this.objectKey]) {
          result += v[this.objectKey];
        } else {
          result += v;
        }
      });
    } else {
      result = data ? data[this.objectKey] : '';
    }
    return result;

  }

}
