<div class="badges-container">
  <span *ngFor="let item of badgesArray;let i = index" class="badge-form">{{(fieldKey == ''?item:item[fieldKey])}}
   <button
     *ngIf="showDeleted"
     (click)="onDeleteitem(i)"
     class="close" data-dismiss="alert" type="button">
    <span>×</span>
  </button>
  </span>
</div>
