import {Component, Input, OnInit} from '@angular/core';
import {CompetenciaStatusEnumToNameService} from '../../enum-to-name-services/competencia-status-enum-to-name.service';
import {EmpresaStatusEnumToNameService} from '../../enum-to-name-services/empresa-status-enum-to-name.service';

@Component({
  selector: 'app-empresas-list-status',
  templateUrl: './empresas-list-status.component.html',
  styleUrls: ['./empresas-list-status.component.scss']
})
export class EmpresasListStatusComponent implements OnInit {

  @Input() status = null;
  enumArray = [];

  constructor(private empresaStatusEnumToNameService: EmpresaStatusEnumToNameService) {
    this.enumArray = this.empresaStatusEnumToNameService.get()
  }

  ngOnInit(): void {
  }

}
