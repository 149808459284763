<div class="form-group {{cssClass}}">
  <label *ngIf="label.length>0" [for]="id">{{label | translate}}<span class="required" *ngIf="isRequired">*</span>
  </label>
  <app-icon-popover
    *ngIf="enablePopover"
    [contentpopover] = "contentpopover"
    [titlePopover] = "titlePopover"
  ></app-icon-popover>
  <div *ngIf="!iconClass" class="input-group">
    <input
      #input="ngModel"
      class="form-control"
      [id]="id"
      type="text"
      [name]="name? name: id"
      [(ngModel)]="value"
      (blur)="onTouched()"
      (change)="onChange(value)"
      [disabled]="disabled"
      [placeholder]="placeholder|translate"
      min="0"
      currencyMask
      [options]="{precision:precision}"
      [ngClass]="{ 'is-invalid': formSubmitted && control.invalid, 'is-valid': formSubmitted && !control.invalid }">
    <!-- <span (click)="iconClick()"><i class="{{iconClass}}"></i></span> -->
  </div>

  <div *ngIf="iconClass" class="input-group">
    <div class="input-group-prepend" style="cursor: pointer;"  *ngIf="iconClassPre">
            <span [ngClass]="{'input-button-disabled': disabled}" class="input-group-text"
                  (click)="iconClick()"><i class="{{iconClass}}"></i></span>
    </div>
    <input
      #input="ngModel"
      class="form-control icon"
      [id]="id"
      [disabled]="disabled"
      [readonly]="readOnly"
      [name]="name? name: id"
      type="text"
      [(ngModel)]="value"
      (blur)="onTouched()"
      (change)="onChange(value)"
      [placeholder]="placeholder|translate"
      min="0"
      currencyMask
      [options]="{precision:precision}"
      [ngClass]="{ 'is-invalid': formSubmitted && control.invalid, 'is-valid': formSubmitted && !control.invalid }">
    <div class="input-group-prepend" style="cursor: pointer;"  *ngIf="!iconClassPre">
            <span [ngClass]="{'input-button-disabled': disabled}" class="input-group-text"
                  (click)="iconClick()"><i class="{{iconClass}}"></i></span>
    </div>
  </div>


  <app-form-error-messages *ngIf="showError" [control]="control" [customErrorMessage]="customErrorMessage"></app-form-error-messages>
</div>
