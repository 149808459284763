import {Component, ElementRef, EventEmitter, Input, OnInit, Optional, Output, Renderer2, Self} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NgControl} from '@angular/forms';
import {NgbTypeaheadSelectItemEvent} from '@ng-bootstrap/ng-bootstrap';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, switchMap, tap} from 'rxjs/operators';


export const hasRequiredField = (abstractControl: AbstractControl): boolean => {
    if (abstractControl.validator) {
        const validator = abstractControl.validator({} as AbstractControl);
        if (validator && validator.required) {
            return true;
        }
        return false;
    }
};


@Component({
    selector: 'app-typeahead-single',
    templateUrl: './typeahead-single.component.html',
    styleUrls: ['./typeahead-single.component.scss']
})
export class TypeaheadSingleComponent implements OnInit, ControlValueAccessor {

    constructor(private _elementRef: ElementRef,
                private _renderer: Renderer2,
                @Self() @Optional() public controlDirective: NgControl) {
        this.controlDirective && (this.controlDirective.valueAccessor = this);
    }


    // model:any;

    @Input() content: any[] = [];
    @Input() id: string;
    @Input() searchParam: any = null;
    @Input() placeholderText: string = '';
    @Input() clearInput: boolean = true;
    @Input() dataFormatter = null;
    @Input() minLengthToSearch = 0;
    @Input() debounceTime = 200;

    @Input() public dataProvider: any;
    @Input() customErrorMessage = null;
    @Input() label = '';
    // Se precisar emitir qualquer mudança
    @Input() emitAllChanges = false;

    // Icon popover
    @Input() enablePopover = false;
    @Input() contentpopover = '';
    @Input() titlePopover = '';

    isRequired = false;

    @Output() selectItem = new EventEmitter<any>();

    control;

    keyword: any;
    innerValue: any = '';
    selection: any;
    formValue: any;

    @Input() disabled = false;
    searching = false;

    @Input() searchAttribute: string = 'name';

    // Usado para mostrar se o formulário possui erro ou não
    @Input() formSubmitted = false;

    public _onChange = (_: any) => {
    };
    public _onTouched = () => {
    };

    onFocus() {
        if (typeof this.dataProvider === 'function') {
            this.dataProvider('').subscribe(data => {
                this.content = data;
                this.showSuggestions();
            });
        } else if (typeof this.dataProvider === 'object' && this.dataProvider.search) {
            this.dataProvider.search('').subscribe(data => {
                this.content = data;
                this.showSuggestions();
            });
        }
    }

    showSuggestions() {
        this._elementRef.nativeElement.querySelector('input').dispatchEvent(new Event('input', { bubbles: true }));
    }

    search = (text$: Observable<string>) => {

        /*Pode ser um serviço ou uma funçao*/
        if (typeof this.dataProvider === 'function' || typeof this.dataProvider === 'object') {
            return text$.pipe(
                debounceTime(this.debounceTime),
                distinctUntilChanged(),
                tap(() => (this.searching = true)),
                switchMap(term => {
                        term = encodeURI(term);
                        const checkMinLength = (term.length >= this.minLengthToSearch);
                        if (typeof this.dataProvider === 'function' && checkMinLength) {
                            if (this.searchParam != null) {
                                return this.dataProvider(term, this.searchParam);
                            } else {
                                return this.dataProvider(term);
                            }
                        } else if (typeof this.dataProvider === 'object' && checkMinLength) {
                            if (this.searchParam != null) {
                                return this.dataProvider.search(term, this.searchParam);
                            } else {
                                return this.dataProvider.search(term);
                            }
                        }

                    }
                ),
                tap(() => (this.searching = false)),
            );
        } else {
            return text$.pipe(
                debounceTime(0),
                distinctUntilChanged(),
                map(term => (term === '' || (this.content.length === 0) || typeof term !== 'string') ? []
                    : this.content.filter(v => this.getPath(v).toLowerCase().indexOf(term.toLowerCase()) !== -1))
            );
        }
    };


    get value() {
        return this.innerValue;
    }

    set value(v: any) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this._onChange(v);
        }
    }


    formatter = (x) => {
        return this.getPath(x);
    };

    formatterToClean = (x) => {
        if (this.clearInput) {
            return '';
        } else {
            if (typeof x === 'string') {
                return x;
            } else {
                return this.getPath(x);
            }
        }
    };


    getPath(v) {
        let value: any;
        value = v[this.searchAttribute];
        return value;
    }

    ngOnInit() {
        this.control = this.controlDirective.control;
        this.isRequired = hasRequiredField(this.control);
        // quando tem qualquer mudança de status verifico se tem campo obrigatorio para trocar a label
        this.control.statusChanges.subscribe(() => {
            this.isRequired = hasRequiredField(this.control);
        });

    }

    selectItemFunction(selectedItem: NgbTypeaheadSelectItemEvent) {
        this.selectItem.emit(selectedItem);
        this.selection = selectedItem.item;
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: any): void {
        if (obj !== this.selection) {
            this.selection = obj || null;
            this.formValue = this.selection;
            this.innerValue = obj;
        }
    }
}
