import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-form-badges',
  templateUrl: './form-badges.component.html',
  styleUrls: ['./form-badges.component.scss']
})
export class FormBadgesComponent implements OnInit {

  @Input() badgesArray = [];
  @Input() fieldKey = 'name';
  @Output() deleteitem = new EventEmitter<any>();
  @Input() showDeleted: boolean = false;


  constructor() {
  }

  ngOnInit() {
  }

  onDeleteitem(index) {
    this.deleteitem.emit(index);
  }

}
