export class Helper {

  public static stringHasContent(value: string) {
    return  this.objectValid(value) && value !== '';
  }

  public static collectionHasContent(value: any[]) {
    return this.objectValid(value) && this.isCollection(value) && value.length > 0;
  }

  public static objectValid(value: any) {
    return value != null && value !== undefined;
  }

  public static objectInvalid(value: any) {
    return !this.objectValid(value);
  }

  public static collectionLastItem(value: any[]): any {
    if (!this.collectionHasContent(value)) { return null; }

    return value[value.length - 1];
  }

  public static isCollection(value: any) {
    return Array.isArray(value );
  }

  public static addToCollection(array: any[], value: any): any[] {
    if (Helper.isCollection(value)) {
      if (Helper.collectionHasContent(value)) { array = array.concat(value); }
    } else if (Helper.objectValid(value)) {
      array.push(value);
    }

    return array;
  }

  public static toCollection(value: any): any[] {
    return Helper.isCollection(value) ? value : [value];
  }

  public static isNumber(value) {
    return !isNaN(parseFloat(value)) && isFinite(value);
  }

}
