import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

import {FullLayoutComponent} from './layouts/full/full-layout.component';
import {ContentLayoutComponent} from './layouts/content/content-layout.component';

import {Full_ROUTES} from './shared/routes/full-layout.routes';
import {CONTENT_ROUTES} from './shared/routes/content-layout.routes';

import {BreadcrumbGuard} from './core/guards/breadcrumb.guard';
import {AuthenticationGuard} from './core/authentication/authentication.guard';

const appRoutes: Routes = [
  {
    path: '',
    component: FullLayoutComponent,
    children: Full_ROUTES,
    canActivate: [AuthenticationGuard],
    canActivateChild: [AuthenticationGuard, BreadcrumbGuard]
  },
  {
    path: '',
    component: ContentLayoutComponent,
    children: CONTENT_ROUTES
  },
  {
    path: '**',
    redirectTo: 'error'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'corrected'})],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
