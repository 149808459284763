import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActionsEnum} from 'app/shared/enums/actions.enum';
import {TargetResourceEnum} from 'app/shared/enums/targetResourceEnum';

@Component({
  selector: 'app-default-form-actions-button',
  templateUrl: './default-form-actions-button.component.html',
  styleUrls: ['./default-form-actions-button.component.scss']
})
export class DefaultFormActionsButtonComponent implements OnInit {

  ActionsEnum = ActionsEnum;

  @Input() editing = false;
  @Input() target: TargetResourceEnum;
  @Output() onSubmit = new EventEmitter<any>();
  @Output() cancel = new EventEmitter<any>();

  @Input() actionEnumSaveButton: ActionsEnum | ActionsEnum[] = [ActionsEnum.CREATE];
  @Input() actionEnumSaveButtonEditing: ActionsEnum | ActionsEnum[] = [ActionsEnum.UPDATE];
  @Input() enableCancelButton = true;

  constructor() {
  }

  ngOnInit(): void {
  }

}
