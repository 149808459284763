import {Routes} from '@angular/router';
import {TargetResourceEnum} from '../enums/targetResourceEnum';
import {RoleGuard} from '../../core/guards/role.guard';
import {HomeComponent} from '../../pages/full-pages/home/home.component';
import {DashboardComponent} from '../../pages/full-pages/dashboard/dashboard.component';

// Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
    {
        path: '',
        component: HomeComponent,
        data: {
            hidden: true,
            overwriteBreadcrumb: true
        },
    },
    {
        path: 'dashboard',
        loadChildren: () => import('../../pages/full-pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [RoleGuard],
        data: {targetResource: TargetResourceEnum.DASHBOARD}
    },
    {
        path: 'cupons-desconto',
        loadChildren: () => import('../../pages/full-pages/cupons-desconto/cupons-desconto.module').then(m => m.CuponsDescontoModule),
        canActivate: [RoleGuard],
        data: {targetResource: TargetResourceEnum.CUPONS_DESCONTO}
    },
    {
        path: 'controle-planos',
        loadChildren: () => import('../../pages/full-pages/controle-planos/controle-planos.module').then(m => m.ControlePlanosModule),
        canActivate: [RoleGuard],
        data: {targetResource: TargetResourceEnum.CONTROLE_PLANOS}
    },
    {
      path: 'planos-de-compra',
      loadChildren: () => import('../../pages/full-pages/plano-de-compra/plano-de-compra.module').then(m => m.PlanoDeCompraModule),
      canActivate: [RoleGuard],
      data: {targetResource: TargetResourceEnum.PLANOS_DE_COMPRA}
    },
    {
        path: 'empresas',
        loadChildren: () => import('../../pages/full-pages/empresas/empresas.module').then(m => m.EmpresasModule),
        canActivate: [RoleGuard],
        data: {targetResource: TargetResourceEnum.EMPRESAS}
    },
    {
        path: 'advogados',
        loadChildren: () => import('../../pages/full-pages/advogados/advogados.module').then(m => m.AdvogadosModule),
        canActivate: [RoleGuard],
        data: {targetResource: TargetResourceEnum.ADVOGADOS}
    },
    {
        path: 'vagas',
        loadChildren: () => import('../../pages/full-pages/vagas/vagas.module').then(m => m.VagasModule),
        canActivate: [RoleGuard],
        data: {targetResource: TargetResourceEnum.VAGAS}
    },
    {
        path: 'competencias',
        loadChildren: () => import('../../pages/full-pages/competencias/competencias.module').then(m => m.CompetenciasModule),
        canActivate: [RoleGuard],
        data: {targetResource: TargetResourceEnum.COMPETENCIAS}
    },
    {
        path: 'formacoes-academicas',
        loadChildren: () => import('../../pages/full-pages/formacoes/formacoes.module').then(m => m.FormacoesModule),
        canActivate: [RoleGuard],
        data: {targetResource: TargetResourceEnum.FORMACOES_ACADEMICAS}
    },
    {
        path: 'areas-atuacao',
        loadChildren: () => import('../../pages/full-pages/areas-atuacao/areas-atuacao.module').then(m => m.AreasAtuacaoModule),
        canActivate: [RoleGuard],
        data: {targetResource: TargetResourceEnum.AREAS_ATUACAO}
    },
    {
        path: 'idiomas',
        loadChildren: () => import('../../pages/full-pages/idiomas/idiomas.module').then(m => m.IdiomasModule),
        canActivate: [RoleGuard],
        data: {targetResource: TargetResourceEnum.IDIOMAS}
    },
    {
        path: 'usuarios-judclass',
        loadChildren: () => import('../../pages/full-pages/usuarios-judclass/usuarios-judclass.module').then(m => m.UsuariosJudclassModule),
        canActivate: [RoleGuard],
        data: {targetResource: TargetResourceEnum.USUARIOS_JUDCLASS}
    },
    {
        path: 'controle-acessos',
        loadChildren: () => import('../../pages/full-pages/controle-acessos/controle-acessos.module').then(m => m.ControleAcessosModule),
        canActivate: [RoleGuard],
        data: {targetResource: TargetResourceEnum.CONTROLE_ACESSOS}
    },
    {
        path: 'configuracoes-conta',
        loadChildren: () => import('../../pages/full-pages/configuracoes-conta/configuracoes-conta.module').then(m => m.ConfiguracoesContaModule),
    },
    {
        path: 'configuracoes-empresa',
        loadChildren: () => import('../../pages/full-pages/configuracoes-empresa/configuracoes-empresa.module').then(m => m.ConfiguracoesEmpresaModule),
        canActivate: [RoleGuard],
        data: {targetResource: TargetResourceEnum.CONFIGURACOES_EMPRESA}
    },
    {
        path: 'candidatos',
        loadChildren: () => import('../../pages/full-pages/vagas/vagas-candidatos/vagas-candidatos.module').then(m => m.VagasCandidatosModule),
        canActivate: [RoleGuard],
        data: {targetResource: TargetResourceEnum.VAGAS}
    },
    {
        path: 'system',
        loadChildren: () => import('../../pages/full-pages/system/system.module').then(m => m.SystemModule),
        canActivate: [RoleGuard],
        data: {targetResource: TargetResourceEnum.SYSTEM}
    },
];
