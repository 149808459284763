import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';

import {AbstractGuard} from '../abstracts/guard.abstract.';
import {BreadcrumbRouteService} from '../../shared/components/breadcrumb/breadcrumb-route.service';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbGuard extends AbstractGuard {

  constructor(private breadcrumbRouteService: BreadcrumbRouteService) {
    super();
  }

  guard(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (childRoute.data) {
      if (childRoute.data.overwriteBreadcrumb) {
        this.breadcrumbRouteService.setData(childRoute.data);
      }
    } else {
      this.breadcrumbRouteService.setData({});
    }
    return true;
  }
}
