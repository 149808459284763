import {Injectable, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {ToastService} from '../notification/toast/toast.service';
import {CrudService} from '../services/crud.service';
import {StorageService} from '../storage/storage.service';
import {StorageEnum} from '../storage/storage.enum';
import {Authentication} from './authentication.model';
import {JwtHelperService} from '@auth0/angular-jwt';
import {TokenModel} from './token.model';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError, finalize, map} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {environment} from '../../../environments/environment';
import {SwitchCompanyService} from '../../shared/components/switch-company/switch-company.service';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public userData: any;
  public showLoader = false;
  private storageEnum = StorageEnum;
  auth: Authentication;
  isLoggedIn: boolean;
  key = 'auth';
  keySaveOnlyForDevelopment = 'saveOnlyForDevelopment';
  authDecoded: TokenModel = null;

  // Login com rede social sempre é true;
  rememberMe = true;

  constructor(public router: Router,
              public ngZone: NgZone,
              private toastService: ToastService,
              private crudService: CrudService,
              private storageService: StorageService,
              private http: HttpClient,
              private switchCompanyService: SwitchCompanyService) {


    this.auth = new Authentication();

    if (this.storageService.check(this.key)) {
      Object.assign(this.auth, this.storageService.get(this.key));
      const helper = new JwtHelperService();
      if (this.auth.token != null && !helper.isTokenExpired(this.auth.token)) {
        this.isLoggedIn = true;
        this.decode();
      }
    }

  }

  private decode() {
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(this.auth.token);
    this.authDecoded = JSON.parse(decodedToken.sub);
    if (!environment.production) {
      console.log(this.authDecoded);
    }

    // console.log(this.auth.token);
  }

  getPermissions() {
    return this.authDecoded['permissions'];
  }

  getProfileType() {
    return this.authDecoded['profileType'];
  }

  getGrupoClienteId() {
    return this.authDecoded['grupoClienteId'];
  }

  getTipoClassificacao() {
    return this.authDecoded['tipoClassificacao'];
  }

  getTipoWorkflow() {
    return this.authDecoded['tipoWorkflow'];
  }

  podeAprovarClassificacao() {
    return this.authDecoded['podeAprovarClassificacao'];
  }

  hasProperties(propertie) {
    return this.authDecoded['properties'] && this.authDecoded['properties'][propertie];
  }

  isAdmin() {
    return this.hasProperties('admin');
  }

  isCustomer() {
    return this.authDecoded['customer'];
  }


  getAuthorization(res) {
    const authorization = res.headers.get('Authorization');
    this.auth.token = authorization.split(' ')[1];

    try {
      const refresh = res.headers.get('refresh');
      this.auth.refresh = refresh.split(' ')[1];
    } catch (e) {

    }

    /*Login*/
    this.isLoggedIn = true;
    this.storageService.set(this.key, this.auth);
    this.decode();
  }

  // Salvo dados do usuario no localstorage somente para desenvolvimento
  emailLogin(data, saveOnlyForDevelopment = false) {
    if (typeof data['rememberMe'] == 'undefined') {
      data['rememberMe'] = true;
    }
    const rememberMe = data['rememberMe'];
    return this.http.post('login', data, {observe: 'response'}).pipe(
      map(res => {

        this.storageService.useStorage(rememberMe ?
          this.storageEnum.localStorage : this.storageEnum.sessionStorage, true);

        this.getAuthorization(res);

        return {ok: true};

      }),
      finalize(() => {
        if (saveOnlyForDevelopment) {
          this.storageService.set(this.keySaveOnlyForDevelopment, data);
        }
      }),
      catchError((err) => {
        return throwError(err);
      })
    );

  }

  forceRelogin() {
    this.emailLogin(this.storageService.get(this.keySaveOnlyForDevelopment)).subscribe(() => {
      window.location.reload();
    });
  }

  changeCompany(company) {
    return this.http.put(`saas/select-unidade-cliente/${company.id}`, {}, {observe: 'response'}).pipe(
      map(res => {
        this.getAuthorization(res);
        return {ok: true};
      }),
      finalize(() => {

      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  refreshAccessToken() {
    return this.http.post('users/refresh-token', {}, {observe: 'response'}).pipe(
      map(res => {
        this.getAuthorization(res);
        return {ok: true};
      }),
      finalize(async () => {
      }),
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          switch ((err as HttpErrorResponse).status) {
            case 401:
            case 403:
              this.signOut();
              break;
          }
        }
        return throwError(err);
      })
    ).toPromise();
  }

  // Sign out
  signOut() {

    this.auth = new Authentication();
    this.storageService.clear();
    this.isLoggedIn = false;
    this.switchCompanyService.hideService();

    this.router.navigate(['login']);
  }
}
