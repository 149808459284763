import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BreadcrumbRouteService} from './breadcrumb-route.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  breadCrumbsList = [];
  title = '';
  show = true;

  constructor(
    private router: Router,
    private breadcrumbRouteService: BreadcrumbRouteService,
    public route: ActivatedRoute) {
  }

  ngOnInit() {
    this.breadcrumbRouteService.data.subscribe((data) => {
      if (typeof data['hidden'] == 'undefined') {
        // this.breadCrumbsList = data['breadcrumb'];
        this.title = data['title'];
        // Chamar esse metodo e comentar o de cima em caso de adicionar home
        this.buildBreadCrumbList(data.breadcrumb);
        this.show = true;
      } else {
        this.show = false;
      }
    });

  }

  buildBreadCrumbList(data) {

    if (!data) {
      this.breadCrumbsList = [];
      return;
    }

    this.breadCrumbsList = [];
    data.forEach(element => {
      this.breadCrumbsList.push(element);
    });
  }

  navigateTo(url) {
    if (url == '') {
      return;
    }
    this.router.navigate([url], {relativeTo: this.route});
  }

}
