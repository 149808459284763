    <div class="media align-items-center">
    <div class="container-img">
      <img *ngIf="isLoading" src="./assets/img/images/tenor.gif" class="loading"/>
      <img (error)="error()" (load)="onImageLoad()" (click)="fileUpload.click()"
           [src]="imageSrc!=null?imageSrc:defaultImageSrc"
           class="users-avatar-shadow rounded avatar mr-3" height="140" width="140">
    </div>
    <div class="media-body">
      <div class="d-flex flex-sm-row flex-column justify-content-start px-0">
        <input [disabled]="disabled"  style="display: none;" type="file" #fileUpload id="fileUpload" name="fileUpload"
               (change)="onChangeFile($event)"/>

        <ng-container *ngIf="!disabled">
          <button class="btn btn-sm bg-light-primary mb-1 mb-sm-0"
                  (click)="fileUpload.click()">{{'Carregar foto'|translate}}</button>
          <button class="btn btn-sm bg-light-secondary ml-sm-2" (click)="reset()">{{'Redefinir'|translate}}</button>
        </ng-container>

      </div>
      <p *ngIf="!disabled" class="text-muted mb-0 mt-1 mt-sm-0">
        <small>{{'Permitido JPG ou PNG.'|translate}}</small>
      </p>
    </div>
  </div>
