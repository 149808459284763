import {Component, Input, OnInit, Optional, Self, ViewChild} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NgControl, NgModel} from '@angular/forms';


export const hasRequiredField = (abstractControl: AbstractControl): boolean => {
  if (abstractControl.validator) {
    const validator = abstractControl.validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
    return false;
  }
};


@Component({
  selector: 'app-input-radio',
  templateUrl: './input-radio.component.html',
  styleUrls: ['./input-radio.component.scss']
})
export class InputRadioComponent implements ControlValueAccessor, OnInit {

  @Input() cssClass = '';
  @Input() radioClass = 'col-12';

  @Input() labelClass = '';
  /*para organizar quantos por linha*/
  @Input() id = '';
  @Input() label = '';
  @Input() name = null;

  // Recebe objeto com chave valor customizado para mensagem de erro
  @Input() customErrorMessage = null;
  @Input() readOnly = false;
  @Input() disabled = false;
  @Input() showRequiredLabel = true;

  // Icon popover
  @Input() enablePopover = false;
  @Input() contentpopover = '';
  @Input() titlePopover = '';

  // Array de opções [{label, value}];
  @Input() options = [];

  control;
  isRequired = false;
  innerValue: any;

  @ViewChild('input', {static: false}) input: NgModel;


  constructor(
    @Self() @Optional() public controlDirective: NgControl) {
    controlDirective.valueAccessor = this;
  }


  ngOnInit() {
    this.control = this.controlDirective.control;
    this.isRequired = hasRequiredField(this.control);
    // quando tem qualquer mudança de status verifico se tem campo obrigatorio para trocar a label
    this.control.statusChanges.subscribe(() => {
      this.isRequired = hasRequiredField(this.control);
    });
  }

  get value() {
    return this.innerValue;
  }

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCb(v);
    }
  }


  onChangeCb: (_: any) => void = () => {
  };
  onTouchedCb: (_: any) => void = () => {
  };

  writeValue(v: any): void {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCb(v);
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }


}
