import {Component, ElementRef, EventEmitter, Input, OnInit, Optional, Output, Renderer2, Self} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NgControl} from '@angular/forms';
import {NgbTypeaheadSelectItemEvent} from '@ng-bootstrap/ng-bootstrap';
import {Observable, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';


export const hasRequiredField = (abstractControl: AbstractControl): boolean => {
    if (abstractControl.validator) {
        const validator = abstractControl.validator({} as AbstractControl);
        if (validator && validator.required) {
            return true;
        }
        return false;
    }
};


@Component({
    selector: 'app-typeahead',
    templateUrl: './typeahead.component.html',
    styleUrls: ['./typeahead.component.scss'],
})
export class TypeaheadComponent implements OnInit, ControlValueAccessor {

    constructor(private _elementRef: ElementRef,
                private _renderer: Renderer2,
                @Self() @Optional() public controlDirective: NgControl) {
        this.controlDirective && (this.controlDirective.valueAccessor = this);
    }


    // model:any;

    @Input() content: any[] = [];
    @Input() id: string;
    @Input() searchParam: any = null;
    @Input() placeholderText: string = '';
    @Input() clearInput: boolean = true;
    @Input() dataFormatter = null;
    @Input() minLengthToSearch = 0;
    @Input() debounceTime = 200;

    @Input() public dataProvider: any;
    @Input() customErrorMessage = null;
    @Input() label = '';
    @Input() fieldKey = 'id';
    // Se precisar emitir qualquer mudança
    @Input() emitAllChanges = false;

    // Icon popover
    @Input() enablePopover = false;
    @Input() contentpopover = '';
    @Input() titlePopover = '';

    isRequired = false;
    // Para mostrar ou não os selecionados
    @Input() showBadges = true;
    // Para mostrar ou não tabela com itens selecionados
    @Input() showTable = false;
    // Se pode selecionar apenas um elemento
    @Input() singleSelected = false;

    @Output() selectItem = new EventEmitter<any>();

    innerNewValue: any = [];
    control;


    /*Somente para controle interno*/
    private subscription: Subscription;

    keyword: any;
    value: any = '';
    selection: any;
    formValue: any;

    @Input() disabled = false;


    @Input() searchAttribute: string = 'name';

    public _onChange = (_: any) => {
    };
    public _onTouched = () => {
    };

    onFocus() {
        if (typeof this.dataProvider === 'function') {
            this.dataProvider('').subscribe(data => {
                this.content = data;
                this.showSuggestions();
            });
        } else if (typeof this.dataProvider === 'object' && this.dataProvider.search) {
            this.dataProvider.search('').subscribe(data => {
                this.content = data;
                this.showSuggestions();
            });
        }
    }

    showSuggestions() {
        this._elementRef.nativeElement.querySelector('input').dispatchEvent(new Event('input', { bubbles: true }));
    }

    search = (text$: Observable<string>) => {

        // /*Para cancelar request que ja iniciaram*/
        // if (typeof this.subscription != "undefined") {
        //   this.subscription.unsubscribe();
        // }

        /*Pode ser um serviço ou uma funçao*/
        if (typeof this.dataProvider === 'function' || typeof this.dataProvider === 'object') {
            return text$.pipe(
                debounceTime(this.debounceTime),
                distinctUntilChanged(),
                switchMap(term => {
                        term = encodeURI(term);
                        const checkMinLength = (term.length >= this.minLengthToSearch);
                        if (typeof this.dataProvider === 'function' && checkMinLength) {
                            if (this.searchParam != null) {
                                return this.dataProvider(term, this.searchParam);
                            } else {
                                return this.dataProvider(term);
                            }
                        } else if (typeof this.dataProvider === 'object' && checkMinLength) {
                            if (this.searchParam != null) {
                                return this.dataProvider.search(term, this.searchParam);
                            } else {
                                return this.dataProvider.search(term);
                            }
                        }

                    }
                )
            );
        } else {
            return text$.pipe(
                debounceTime(0),
                distinctUntilChanged(),
                map(term => (term === '' || (this.content.length === 0) || typeof term !== 'string') ? []
                    : this.content.filter(v => this.getPath(v).toLowerCase().indexOf(term.toLowerCase()) !== -1))
            );
        }
    };


    formatter = (x) => {
        return this.getPath(x);
    };

    formatterToClean = (x) => {
        if (this.clearInput) {
            return '';
        } else {
            if (typeof x === 'string') {
                return x;
            } else {
                return this.getPath(x);
            }
        }
    };


    getPath(v) {
        let value: any;
        if (typeof this.dataFormatter === 'function') {
            value = this.dataFormatter(v);
        } else {
            value = v[this.searchAttribute];
        }

        return value;

    }

    ngOnInit() {
        this.control = this.controlDirective.control;
        this.isRequired = hasRequiredField(this.control);
        // quando tem qualquer mudança de status verifico se tem campo obrigatorio para trocar a label
        this.control.statusChanges.subscribe(() => {
            this.isRequired = hasRequiredField(this.control);
        });

    }

    selectItemFunction(selectedItem: NgbTypeaheadSelectItemEvent) {


        this.keyword = this.getLabel(selectedItem.item);
        this.add(selectedItem.item);

        this.selectItem.emit(selectedItem);

        this.selection = selectedItem.item;

    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        // this._renderer.setProperty(this._elementRef.nativeElement.querySelector('.search-input'), 'disabled', isDisabled);

    }

    writeValue(obj: any): void {
        if (obj !== this.selection) {
            this.selection = obj || null;
            this.formValue = this.selection;
            this.keyword = this.getLabel(this.selection);
            this.innerNewValue = obj;
        }
    }

    // writeValue(obj: any): void {
    //   if (obj !== this.innerValue) {
    //     if (obj == null || obj == '') {
    //       obj = [];
    //     }
    //     // this.innerValue = obj;
    //     this.innerNewValue = obj;
    //   }
    // }

    emitChange() {
        if (this.emitAllChanges) {
            this._onChange(this.keyword);
        } else {
            if (this.keyword == '' || this.keyword == null) {
                this._onChange(null);
            }
        }


        // this._onChange(this.keyword);
    }

    private getLabel(selection: any): any {
        if (selection == null || this.clearInput) {
            return null;
        } else {
            return this.getPath(selection);
        }
    }

    public updateModel() {
        this._onChange(this.innerNewValue);
    }

    deleteitem(index) {
        this.innerNewValue.splice(index, 1);
        this._onChange(this.innerNewValue);
    }

    add(data) {

        if (!this.singleSelected) {
            if (!Array.isArray(this.innerNewValue)) {
                this.innerNewValue = [];
            }
            this.innerNewValue.push(data);

            data = this.innerNewValue.filter((obj, pos, arr) => {
                return arr.map(mapObj => mapObj[this.fieldKey]).indexOf(obj[this.fieldKey]) === pos;
            });
        }

        this.innerNewValue = data;
        this.updateModel();
    }
}

