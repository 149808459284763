


<div class="form-group {{cssClass}}" *ngIf="type != 'checkbox' && type != 'radio'">
  <label *ngIf="label.length>0" [for]="id">{{label | translate}}<span class="required" *ngIf="isRequired">*</span>
  </label>
  <app-icon-popover
    *ngIf="enablePopover"
    [contentpopover] = "contentpopover"
    [titlePopover] = "titlePopover"
  ></app-icon-popover>
  <div *ngIf="!iconClass" class="input-group">
    <input
      #input="ngModel"
      class="form-control"
      [id]="id"
      [type]="type"
      [name]="name? name: id"
      [(ngModel)]="value"
      (blur)="onTouched()"
      [disabled]="disabled"
      [mask]="mask"
      [showMaskTyped]="showMaskTyped"
      [prefix]="maskPrefix"
      [suffix]="maskSuffix"
      [validation]="maskValidation"
      [placeholder]="placeholder|translate"
      [ngClass]="{ 'is-invalid': formSubmitted && control.invalid, 'is-valid': formSubmitted && !control.invalid }">
  </div>

  <div *ngIf="iconClass" class="input-group">
    <div class="input-group-prepend" style="cursor: pointer;"  *ngIf="iconClassPre">
            <span [ngClass]="{'input-button-disabled': disabled}" class="input-group-text"
                  (click)="iconClick()"><i class="{{iconClass}}"></i></span>
    </div>
    <input
      #input="ngModel"
      class="form-control icon"
      [id]="id"
      [disabled]="disabled"
      [readonly]="readOnly"
      [name]="name? name: id"
      [type]="type"
      [(ngModel)]="value"
      (blur)="onTouched()"
      [mask]="mask"
      [prefix]="maskPrefix"
      [suffix]="maskSuffix"
      [validation]="maskValidation"
      [placeholder]="placeholder|translate"
      [ngClass]="{ 'is-invalid': formSubmitted && control.invalid, 'is-valid': formSubmitted && !control.invalid }">
    <div class="input-group-prepend" style="cursor: pointer;"  *ngIf="!iconClassPre">
            <span [ngClass]="{'input-button-disabled': disabled}" class="input-group-text"
                  (click)="iconClick()"><i class="{{iconClass}}"></i></span>
    </div>
  </div>


  <app-form-error-messages *ngIf="showError" [control]="control" [customErrorMessage]="customErrorMessage"></app-form-error-messages>
</div>
<div class="{{cssClass}} form-group check-radio checkbox" *ngIf="type == 'checkbox'">
  <input
    #input="ngModel"
    [id]="id"
    class="custom-control-input"
    [name]="name? name: id"
    [disabled]="disabled"
    [readonly]="readOnly"
    [type]="type"
    [(ngModel)]="value"
    (blur)="onTouched()"
    [checked]="value">
  <label [for]="id" class="">
    {{label | translate}}<span class="required " *ngIf="isRequired">*</span>
  </label>
  <app-icon-popover
    *ngIf="enablePopover"
    [contentpopover] = "contentpopover"
    [titlePopover] = "titlePopover"
  ></app-icon-popover>

  <app-form-error-messages  *ngIf="showError"  [control]="control" [customErrorMessage]="customErrorMessage"></app-form-error-messages>
</div>

<div class="{{cssClass}} form-group" *ngIf="type == 'radio'">
  <label [for]="id">
    <input

      [id]="id"
      class="radio_animated"
      [name]="name? name: id"
      [disabled]="disabled"
      [readonly]="readOnly"
      [type]="type"
      [formControl]="control"
      (blur)="onTouched()"
      [value]="value"
      (change)="onChange(value)"
      [checked]="checked">
    {{label | translate}}<span class="required " *ngIf="isRequired">*</span>
  </label>
  <app-icon-popover
    *ngIf="enablePopover"
    [contentpopover] = "contentpopover"
    [titlePopover] = "titlePopover"
  ></app-icon-popover>

  <app-form-error-messages  *ngIf="showError"  [control]="control" [customErrorMessage]="customErrorMessage"></app-form-error-messages>
</div>
