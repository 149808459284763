import {ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {SelectionType} from '@swimlane/ngx-datatable';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {FilterModalInterface} from '../../interfaces/filter-modal.interface';
import {ListAbstract} from '../../../core/abstracts/list.abstract';

@Component({
  selector: 'app-multiple-check-list',
  templateUrl: './multiple-check-list.component.html',
  styleUrls: ['./multiple-check-list.component.scss', './../../../../assets/sass/libs/datatables.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MultipleCheckListComponent extends ListAbstract implements OnInit {

  @Input() selected = [];
  @Input() title = null;
  @Input() hasFilter = null;
  @Input() filterKeys: FilterModalInterface[] = null;
  @Input() columnsModel = [];
  @Input() resourcePath = '';
  messages = {emptyMessage: '', totalMessage: '', selectedMessage: ''};


  form: FormGroup;

  selectionType = SelectionType;


  // todos os selecionados
  allSelectedControl = [];
  // O componente precisa de um array de selecionados. Não pode ser o de controle pois o componente manipula o array. Ele sempre tera o mesmo valor de allSelectedControl
  allSelectedForComponent = [];
  /*Ao iniciar ou trocar de pagina armazenar os que estao selecionados na pagina atual. Isso é feito
  para conseguir saber quem saiou ou entrou na nova seleção*/
  selectedOnCurrentPage = [];

  // Variavel para controlar qual pagina esta com todos selecionados
  selectAllOnPage = [];

  constructor(
    public activeModal: NgbActiveModal,
    private cd: ChangeDetectorRef,
    route: ActivatedRoute) {
    super(route);
    this.bookmarkObject = [];
  }

  ngOnInit() {
    this.createForm();
    this.translateService.get('Nenhum resultado').subscribe(response => this.messages.emptyMessage = response);
    this.translateService.get('Total').subscribe(response => this.messages.totalMessage = response);
    this.translateService.get('Selecionados').subscribe(response => this.messages.selectedMessage = response);
    this.path = this.resourcePath;
    this.columns = this.columnsModel;
    this.validateSelectedRow();
    this.init();
  }


  public onActivate(info: any) {
  }

  subscribeData() {

    this.setSelectedOnCurrentPageAndAllSelectedForComponent();
    this.cd.markForCheck(); // marks path
  }

  // Armazeno os itens que estao selecionados na pagina corrente
  setSelectedOnCurrentPageAndAllSelectedForComponent() {
    this.allSelectedForComponent.splice(0, this.allSelectedForComponent.length);
    this.allSelectedForComponent.push(...this.allSelectedControl);
    // Limpo o array para garantir que só dados da pagina atual estão nele
    this.selectedOnCurrentPage.splice(0, this.selectedOnCurrentPage.length);
    this.allSelectedControl.forEach((value) => {
      const index = this.pagedData.data.rows.find(data => {
        return data.id === value.id;
      });

      if (typeof index !== 'undefined') {
        index.isSelected = true;
        this.selectedOnCurrentPage.push(index);
      }
    });


    // Verifico se todo os itens da tela estao checados
    if (this.selectedOnCurrentPage.length === this.pagedData.data.rows.length) {
      this.setSelectAllOnPage(true);
    } else {
      this.setSelectAllOnPage(false);
    }

  }

  operation(list1, list2, isUnion = false) {
    const result = [];
    for (let i = 0; i < list1.length; i++) {
      const item1 = list1[i];
      let found = false;
      for (let j = 0; j < list2.length && !found; j++) {
        found = item1.id === list2[j].id;
      }
      if (found === !!isUnion) { // isUnion is coerced to boolean
        result.push(item1);
      }
    }
    return result;
  }

  onSelect({selected}) {
    // Make sure we are no longer selecting all
    this.selectAllOnPage[this.pagedData.page.pageNumber] = false;

    // Verifico os dados que estão somente nos selecionados anteriormente e removo doe todos os selecionados
    const inFirstOnly = this.operation(this.selectedOnCurrentPage, selected);
    inFirstOnly.forEach((value) => {
      this.removeSelectedArray(value);
    });

    // Preciso ver oque veio de novo na seleção para adicionar no array de todos os selecionados
    const onNewOnly = this.operation(selected, this.allSelectedControl);
    this.setAllSelectedControlAndAllSelectedForComponent(onNewOnly);

    this.setSelectedOnCurrentPage(selected);

  }

  setAllSelectedForComponent() {
    // Definos novamente os itens que estão selecionados na página
    this.allSelectedForComponent.splice(0, this.allSelectedForComponent.length);
    this.allSelectedForComponent.push(...this.allSelectedControl);
    this.allSelectedForComponent = [...this.allSelectedForComponent];
  }

  setAllSelectedControlAndAllSelectedForComponent(data) {
    this.allSelectedControl.push(...data);
    this.setAllSelectedForComponent();
  }

  setSelectedOnCurrentPage(selected) {
    // Definos novamente os itens que estão selecionados na página
    this.selectedOnCurrentPage.splice(0, this.selectedOnCurrentPage.length);
    this.selectedOnCurrentPage.push(...selected);
    this.selectedOnCurrentPage = [...this.selectedOnCurrentPage];
  }

  removeSelectedArray(selectedItem) {
    const index = this.allSelectedControl.findIndex(data => {
      return data.id === selectedItem.id;
    });

    if (typeof index !== 'undefined') {
      this.allSelectedControl.splice(index, 1);
    }
  }

  createForm() {

    this.form = new FormGroup({
      page: new FormControl(0),
      textSearch: new FormControl(),
    });

  }

  finalSelection() {
    this.activeModal.close(this.allSelectedControl);
  }

  validateSelectedRow() {
    if (this.selected == null) {
      this.selected = [];
    } else if (!Array.isArray(this.selected)) {
      this.selected = [this.selected];
    }

    // Inicio o meu array de controle com os dados que vieram de edição
    this.allSelectedControl.push(...this.selected);
  }

  getId(row) {
    return row.id;
  }

  selectAll(event) {
    const dataRow = this.pagedData.data.rows;
    // Se for para selecionar todos
    if (!this.isSelectAllOnPage()) {

      // removo do array de controle oque ja estava selecionado na tela
      this.selectedOnCurrentPage.forEach((value) => {
        this.removeSelectedArray(value);
      });
      // Adiciono ao array de controle e aos outros auxiliares tudo que esta na tela
      // Aqui eu garanto que o AllSelectedForComponent vai ser igual ao AllSelectedControl, por isso não preciso chamar setAllSelectedForComponent
      this.setAllSelectedControlAndAllSelectedForComponent(dataRow);
      this.setSelectedOnCurrentPage(dataRow);
      this.setSelectAllOnPage(true);
    } else {

      // removo do array de controle oque ja estava selecionado na tela
      this.selectedOnCurrentPage.forEach((value) => {
        this.removeSelectedArray(value);
      });
      // Preciso que eles tenham o mesmo valor
      this.setAllSelectedForComponent();
      // Limpo os outros arrays de controle
      this.setSelectedOnCurrentPage([]);
      this.setSelectAllOnPage(false);
    }
  }

  setSelectAllOnPage(value) {
    this.selectAllOnPage[this.pagedData.page.pageNumber] = value;
  }

  isSelectAllOnPage() {
    return this.selectAllOnPage[this.pagedData.page.pageNumber];
  }

}
