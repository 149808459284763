import {Component, ElementRef, OnInit} from '@angular/core';
import {FormAbstract} from '../../../core/abstracts/form.abstract';
import {AddressPipe} from '../../../shared/pipes/address.pipe';
import {AdItem} from '../../../shared/model/ad-item';
import {ModalAddressComponent} from '../../../shared/components/modal-address/modal-address.component';
import {PessoaTipoEnum} from '../../../shared/enums/pessoa-tipo.enum';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ModalService} from '../../../core/services/modal.service';
import {PessoaTipoEnumToNameService} from '../../../shared/enum-to-name-services/pessoa-tipo-enum-to-name.service';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-configuracoes-empresa',
    templateUrl: './configuracoes-empresa.component.html',
    styleUrls: ['./configuracoes-empresa.component.scss']
})
export class ConfiguracoesEmpresaComponent extends FormAbstract implements OnInit {
    addressPipe = new AddressPipe();
    selector = new AdItem(ModalAddressComponent);

    pessoaTipoEnum = PessoaTipoEnum;
    tiposDePessoa = [];

    constructor(route: ActivatedRoute, elementRef: ElementRef, private translateService: TranslateService, private modalService: ModalService,
                private pessoaTipoEnumToNameService: PessoaTipoEnumToNameService) {
        super(route, elementRef);
        this.tiposDePessoa = this.pessoaTipoEnumToNameService.get();
    }

    ngOnInit(): void {
        this.createForm();
        this.onChanges();
        this.loadData();
    }

    afterDefaultLoadData(data) {
        this.form.get('login').disable();
        this.form.get('cnpj').disable();
        this.form.get('cpf').disable();
        this.form.get('tipo').disable();


        /*this.addEmail(data.contatos.emails.length === 0 ? 0 : data.contatos.emails.length);
        this.form.get('contatos.emails').patchValue(data.contatos.emails);*/

        this.addPhone(data.contatos.telefones.length === 0 ? 0 : data.contatos.telefones.length);
        this.form.get('contatos.telefones').patchValue(data.contatos.telefones);

    }

    public loadData() {
        this.defaultLoadData();
    }



    createForm() {
        this.form = new FormGroup({
            id: new FormControl(null),
            status: new FormControl(null),
            logoId: new FormControl(null),
            logoUrl: new FormControl(null),
            websiteUrl: new FormControl(null, [this.utilsService.httpValidatorAcceptEmptyString.bind(this), Validators.maxLength(256)]),
            linkedInUrl: new FormControl(null, [this.utilsService.httpValidatorAcceptEmptyString.bind(this), Validators.maxLength(256)]),
            // nomeFantasia: new FormControl(null, [Validators.maxLength(256)]),
            nome: new FormControl(null, [Validators.maxLength(256), Validators.minLength(5)]),
            // razaoSocial: new FormControl(null),
            endereco: new FormControl(null, [Validators.required]),
            contatos: new FormGroup({
                id: new FormControl(null),
                // emails: new FormArray([]),
                telefones: new FormArray([])
            }),
            cpf: new FormControl(null),
            cnpj: new FormControl(null),
            descricao: new FormControl(null, [Validators.maxLength(4000)]),
            login: new FormControl(null, [Validators.required]),
            tipo: new FormControl(null, [Validators.required])
        })
    }

    onChanges(): void {
        this.form.get('tipo').valueChanges.subscribe((tipo) => {
            this.form.get('nome').clearValidators();
            this.form.get('cpf').clearValidators();
            // this.form.get('razaoSocial').clearValidators();
            this.form.get('cnpj').clearValidators();
            // this.form.get('nomeFantasia').clearValidators();

            if (tipo === PessoaTipoEnum.PF) {
                this.form.get('nome').setValidators([Validators.required, Validators.maxLength(256), Validators.minLength(5)]);
                this.form.get('cpf').setValidators([Validators.required]);
            } else {
                this.form.get('nome').setValidators([Validators.required, Validators.maxLength(256), Validators.minLength(5)]);
                // this.form.get('razaoSocial').setValidators([Validators.required]);
                this.form.get('cnpj').setValidators([Validators.required]);
                // this.form.get('nomeFantasia').setValidators([Validators.required]);
            }

            this.form.get('nome').updateValueAndValidity({onlySelf: false, emitEvent: false});
            this.form.get('cpf').updateValueAndValidity({onlySelf: false, emitEvent: false});
            // this.form.get('razaoSocial').updateValueAndValidity({onlySelf: false, emitEvent: false});
            this.form.get('cnpj').updateValueAndValidity({onlySelf: false, emitEvent: false});
            // this.form.get('nomeFantasia').updateValueAndValidity({onlySelf: false, emitEvent: false});
        });
    }

    /*addEmail(cont = 1) {
        for (let i = 0; i < cont; i++) {
            (this.form.get('contatos.emails') as FormArray).insert(i, new FormControl(null, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]));
        }
    }*/

    addPhone(cont = 1) {
        for (let i = 0; i < cont; i++) {
            (this.form.get('contatos.telefones') as FormArray).insert(i, new FormControl(null, [Validators.required, this.utilsService.phoneNumberValidator.bind(this)]))
        }
    }

    /*deleteEmail(index: number) {
        (this.form.get('contatos.emails') as FormArray).removeAt(index);
    }*/

    deletePhone(index: number) {
        (this.form.get('contatos.telefones') as FormArray).removeAt(index);
    }

    onFileChange(data) {
        this.form.get('logoId').patchValue(data);
    }

    onFileReset() {
        this.form.get('logoId').patchValue(null);
    }
}

