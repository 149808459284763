import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-icon-popover',
  templateUrl: './icon-popover.component.html',
  styleUrls: ['./icon-popover.component.scss']
})
export class IconPopoverComponent implements OnInit {

  @Input() contentpopover = '';
  @Input() titlePopover = '';

  constructor() { }

  ngOnInit(): void {
  }

}
