import {Injectable} from '@angular/core';
import {TargetResourceEnum} from '../enums/targetResourceEnum';
import {ActionsEnum} from '../enums/actions.enum';
import {AuthenticationService} from '../../core/authentication/authentication.service';
import {environment} from '../../../environments/environment';
import {PerfilTipoEnum} from '../enums/perfil-tipo.enum';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(private authenticationService: AuthenticationService) {

  }

  tenhoPermissaoParaEsseTargetResourceEActions(permissions, actions, targetResource) {
    // Só quero saber se tenho permissão para acessar
    if (!actions || actions.length === 0) {
      return !!permissions[targetResource] && permissions[targetResource].includes(ActionsEnum.READ);
    } else {
      return !!permissions[targetResource] &&
        (permissions[targetResource].some(tr => actions.includes(tr)));
    }
  }

  hasPermission(targetResource: TargetResourceEnum | TargetResourceEnum[], actions?: ActionsEnum[] | ActionsEnum): boolean {
    if (this.hasFreeAccess()) {
      return true;
    }
    if (actions) {
      actions = actions instanceof Array ? actions : [actions];
    }


    const permissions = this.authenticationService.getPermissions();
    if (targetResource instanceof Array) {
      targetResource.forEach(value => {
        if (this.tenhoPermissaoParaEsseTargetResourceEActions(permissions, actions, value)) {
          return true;
        }
      })
      return false;
    } else {

      return this.tenhoPermissaoParaEsseTargetResourceEActions(permissions, actions, targetResource);
    }
  }

  hasProfile(profilesType: PerfilTipoEnum[] | PerfilTipoEnum): boolean {
    if (this.hasFreeAccess()) {
      return true;
    }

    const profileType = this.authenticationService.getProfileType();
    if (profilesType.length === 0) {
      return profilesType === profileType;
    } else {
      return profilesType.includes(profileType);
    }
  }

  private hasFreeAccess() {
    if (environment.ignoreRoles) {
      return true;
    } else if (!!this.authenticationService.isAdmin()) {
      return true;
    }
    return false;
  }

}
