import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-profile-picture',
    templateUrl: './profile-picture.component.html',
    styleUrls: ['./profile-picture.component.scss']
})
export class ProfilePictureComponent implements OnInit {

    @Input() defaultImageSrc = 'assets/img/images/user.png';

    @Input() imageSrc = null;

    constructor() {
    }

    ngOnInit(): void {
    }
}
