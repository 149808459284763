import {Component, EventEmitter, Input, OnInit, Optional, Output, Self} from '@angular/core';
import {ControlValueAccessor, NgControl} from '@angular/forms';
import {hasRequiredField} from '../../input-field/input-field.component';

@Component({
  selector: 'app-input-mult-select',
  templateUrl: './input-mult-select.component.html',
  styleUrls: ['./input-mult-select.component.scss']
})
export class InputMultSelectComponent implements ControlValueAccessor, OnInit {

  @Input() cssClass = '';
  @Input() id = '';
  @Input() label = '';
  @Input() disable = false;
  // chave referente a option do objeto
  @Input() optionKey = null;
  // chave referente ao value do select
  @Input() valueKey = null;
  // Lista de items do select
  @Input() items = [];

  // placeholder do input
  @Input() placeholder = 'Selecione uma opção';

  // Icon popover
  @Input() enablePopover = false;
  @Input() contentpopover = '';
  @Input() titlePopover = '';

  @Input() multiple = true;
  @Input() closeOnSelect = false;
  @Input() hideSelected = true;

  // Caso necessário recebe uma função custom de compareFn
  @Input() compareCustom = null;
  @Input() customErrorMessage = null;

  // para mostrar o primeiro option como null
  @Input() showNullOption = true;
  @Input() showError = true;

  control: any;

  @Output() iconClickEvent = new EventEmitter();
  isRequired = false;
  private innerValue: any;

  // Usado para mostrar se o formulário possui erro ou não
  @Input() formSubmitted = false;

  constructor(
    @Self() @Optional() public controlDirective: NgControl) {
    controlDirective.valueAccessor = this;
  }

  ngOnInit(): void {
    this.control = this.controlDirective.control;
    this.isRequired = hasRequiredField(this.control);
    // quando tem qualquer mudança de status verifico se tem campo obrigatorio para trocar a label
    this.control.statusChanges.subscribe(() => {
      this.isRequired = hasRequiredField(this.control);
    });
  }

  onChangeCb: (_: any) => void = () => {
  };
  onTouchedCb: (_: any) => void = () => {
  };

  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(v: any): void {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCb(v);
    }
  }

  compareFn(c1: any, c2: any): boolean {
    // Ver se isso não irá causar problemas no catalogo de produtos
    if (this.valueKey) {
      return c1[this.valueKey] === c2;
    }
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

}
