import {Component, Input, OnInit} from '@angular/core';
import {AreaAtuacaoStatusEnumToNameService} from '../../enum-to-name-services/area-atuacao-status-enum-to-name.service';

@Component({
  selector: 'app-area-atuacao-list-status',
  templateUrl: './area-atuacao-list-status.component.html',
  styleUrls: ['./area-atuacao-list-status.component.scss']
})
export class AreaAtuacaoListStatusComponent implements OnInit {

  @Input() status = null;
  enumArray = [];
  constructor(private areaAtuacaoStatusEnumToNameService: AreaAtuacaoStatusEnumToNameService) {
    this.enumArray = this.areaAtuacaoStatusEnumToNameService.get()
  }

  ngOnInit(): void {
  }

}
