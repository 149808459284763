import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, filter, switchMap, take} from 'rxjs/operators';
import {UtilsService} from '../services/utils.service';
import {AuthenticationService} from '../authentication/authentication.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    private refreshTokenInProgress = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private authService: AuthenticationService,
                private utilsService: UtilsService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


        return next.handle(this.addAuthenticationToken(request)).pipe(
            catchError(err => {
                if (err instanceof HttpErrorResponse) {
                    switch ((<HttpErrorResponse>err).status) {
                        case 401:
                            this.handle401Error(request, next);
                            break;
                        // case 400:
                        //     this.authService.signOut();
                            break;
                    }
                }
                return throwError(err);
            }));
    }


    private handle401Error(request, next) {
        if (this.refreshTokenInProgress) {

            return this.refreshTokenSubject.pipe(
                filter(result => result !== null),
                take(1),
                switchMap(() => next.handle(this.addAuthenticationToken(request)))
            );

        } else {
            this.refreshTokenInProgress = true;
            this.refreshTokenSubject.next(null);


            return this.authService.refreshAccessToken().then((token: any) => {
                this.refreshTokenInProgress = false;
                this.refreshTokenSubject.next(token);

                return next.handle(this.addAuthenticationToken(request));
            }).catch((err: any) => {
                this.refreshTokenInProgress = false;
                return <any>this.authService.signOut();
            }).finally(() => {

            });
        }
    }

    private addAuthenticationToken(request) {

        if (this.authService.isLoggedIn) {
            if (this.authService.auth.token) {

                if (this.utilsService.checkAddHeader(request.url)) {
                    request = request.clone({headers: request.headers.set('Authorization', this.authService.auth.token)});

                }

            }
        }
        return request;
    }
}
