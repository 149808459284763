<div class="form-group {{cssClass}}">
  <label [attr.for]="id">{{label | translate}}<span class="required" *ngIf="isRequired">*</span>
  </label>

  <div *ngIf="iconClass" class="input-group">
      <div class="input-group-prepend" style="cursor: pointer;">
          <span class="input-group-text" (click)="!disabled ? openModal(): ''"
          [ngClass]="{'input-button-disabled': disabled}"><i class="{{iconClass}}"></i></span>
      </div>
      <input
      class="form-control"
      [id]="id"
      (click)="openModal()"
      [(value)]="value"
      [disabled]="disabled"
      [readonly]="true"
      type="text"
      [ngClass]="{ 'is-invalid': formSubmitted && control.invalid, 'is-valid': formSubmitted && !control.invalid }">
  </div>


  <app-form-error-messages [control]="control" [customErrorMessage]="customErrorMessage"></app-form-error-messages>
</div>
