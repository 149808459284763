<div class="modal-header">
  <h3 *ngIf="actionType == ActionConfirmTypesEnum.DELETE" class="modal-title"
      [innerHTML]="'Excluir NAME?'|translate:{name:name}"></h3>
  <h3 *ngIf="actionType == ActionConfirmTypesEnum.DISABLE" class="modal-title"
      [innerHTML]="'Desativar NAME?'|translate:{name:name}"></h3>
  <h3 *ngIf="actionType == ActionConfirmTypesEnum.ENABLE" class="modal-title"
      [innerHTML]="'Ativar NAME?'|translate:{name:name}"></h3>


  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form" class="theme-form" (ngSubmit)="onSubmit()">
    <div class="form-row">

      <p class="action-confirm-modal" *ngIf="actionType == ActionConfirmTypesEnum.DELETE"
         [innerHTML]="'Para confirmar a exclusão, digite TEXT no campo de entrada de texto.'|translate:{text:code}"></p>
      <p class="action-confirm-modal" *ngIf="actionType == ActionConfirmTypesEnum.DISABLE"
         [innerHTML]="'Para confirmar a desativação, digite TEXT no campo de entrada de texto.'|translate:{text:code}"></p>
      <p class="action-confirm-modal" *ngIf="actionType == ActionConfirmTypesEnum.ENABLE"
         [innerHTML]="'Para confirmar a ativação, digite TEXT no campo de entrada de texto.'|translate:{text:code}"></p>

      <app-input-field class="col-md-12 action-confirm-modal" [id]="'userKey'" [placeholder]="code" formControlName="userKey"
                       [formSubmitted]="formSubmitted"
                       [customErrorMessage]="{notSame: 'O valor deve ser o mesmo'}"></app-input-field>

    </div>


  </form>
</div>
<div class="modal-footer">
  <button type="submit" class="btn btn-danger mr-1" ngbAutofocus
          (click)="onSubmit()">

    <ng-container *ngIf="actionType == ActionConfirmTypesEnum.DELETE">
      {{'Excluir' | translate}}
    </ng-container>
    <ng-container *ngIf="actionType == ActionConfirmTypesEnum.DISABLE">
      {{'Desativar' | translate}}
    </ng-container>
    <ng-container *ngIf="actionType == ActionConfirmTypesEnum.ENABLE">
      {{'Ativar' | translate}}
    </ng-container>
  </button>
  <button class="btn btn-secondary" (click)="activeModal.dismiss()">{{'Cancelar' | translate}}</button>
</div>
