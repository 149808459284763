export enum TargetResourceEnum {
  SYSTEM = 'SYSTEM' /*Não é pra ninguem ter, ele só serve para barrar o acesso a aba system*/,
  EMPRESAS = 'EMPRESAS',
  DASHBOARD = 'DASHBOARD',
  CUPONS_DESCONTO = 'CUPONS_DESCONTO',
  VAGAS = 'VAGAS',
  ULTIMOS_PEDIDOS = 'ULTIMOS_PEDIDOS',
  ADVOGADOS = 'ADVOGADOS',
  FORMACOES_ACADEMICAS = 'FORMACOES_ACADEMICAS',
  AREAS_ATUACAO = 'AREAS_ATUACAO',
  IDIOMAS = 'IDIOMAS',
  CANDIDATOS = 'CANDIDATOS',
  CONTROLE_ACESSOS = 'CONTROLE_ACESSOS',
  COMPETENCIAS = 'COMPETENCIAS',
  PERFIL_USUARIO = 'PERFIL_USUARIO',
  USUARIO = 'USUARIO',
  ITEM = 'ITEM',
  GRUPOS_PERMISSAO = 'GRUPOS_PERMISSAO',
  CONFIGURACOES_EMPRESA = 'CONFIGURACOES_EMPRESA',
  USUARIOS_JUDCLASS = 'USUARIOS_JUDCLASS',
  CONTROLE_PLANOS = 'CONTROLE_PLANOS',
  PLANOS_DE_COMPRA = 'PLANOS_DE_COMPRA'
}
