<!-- Loader starts-->
<!--<div class="loader-wrapper" [class.loderhide]="!show">-->
<!--<div class="loader bg-white">-->
<!--<div class="whirly-loader"> </div>-->
<!--</div>-->
<!--</div>-->
<!-- Loader ends-->
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-triangle-path"
             [fullScreen]="true"></ngx-spinner>

