import {AfterViewInit, Component, ElementRef, Input, OnInit, Optional, Self, ViewChild} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NgControl} from '@angular/forms';

export const hasRequiredField = (abstractControl: AbstractControl): boolean => {
  if (abstractControl.validator) {
    const validator = abstractControl.validator({}as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
    return false;
  }
};
@Component({
  selector: 'app-inderterminate-checkbox',
  templateUrl: './inderterminate-checkbox.component.html',
  styleUrls: ['./inderterminate-checkbox.component.scss']
})
export class InderterminateCheckboxComponent implements OnInit, AfterViewInit, ControlValueAccessor {

  @Input() label  = '';
  @Input() cssClass = '';
  @Input() id  = '';
  @Input() customErrorMessage = null;
  @ViewChild('inderterminateCheckbox') inderterminateCheckbox: ElementRef;
  control;
  isRequired;

  checked = false;
  indeterminate = false;
  themeColor = 'color-1';

  value: any;

  emitValue: any;


  public _onChange = (_: any) => {
  }
  public _onTouched = () => {
  }

  constructor(@Self() @Optional() public controlDirective: NgControl) {
    controlDirective.valueAccessor = this;
  }

  ngOnInit() {
    this.control = this.controlDirective.control;
    this.themeColor = localStorage.getItem('color');
    this.isRequired = hasRequiredField(this.control);

    // quando tem qualquer mudança de status verifico se tem campo obrigatorio para trocar a label
    this.control.statusChanges.subscribe(() => {
      this.isRequired = hasRequiredField(this.control);
    });
  }

  ngAfterViewInit() {
    /*Usado pra chamar o WriteValue novamente após a view carregada e já possuir o element ref*/
    this.control.patchValue(this.control.value);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    /*Ver como irá ser feito*/
    // this._renderer.setProperty(this._elementRef.nativeElement.querySelector('.search-input'), 'disabled', isDisabled);
  }

  writeValue(obj: any): void {
    this.value = obj;
    /*Validação feita pois no inicio do componente a view ainda não foi carregada e não possui o elemento*/
    if(this.inderterminateCheckbox) {
      if (this.value) {
        /*Marco como checado*/
        this.inderterminateCheckbox.nativeElement.checked = true;
      } else {
        /*Anteriormente foi checado*/
        this.checked = true;
      }
      this.checkValue();
    }
  }

  checkValue() {


    if (this.value == null) {
      this.checked = false;
      this.indeterminate = false;
      this.inderterminateCheckbox.nativeElement.indeterminate = false;
      this.emitValue = null;
      return;
    }

    /*O input esta checked e anteriormente nao estava*/
    if (this.inderterminateCheckbox.nativeElement.checked && !this.checked) {
      /*Guardo que ele ja foi checado*/
      this.checked = true;

      this.emitValue = true;
    } else {
      /*Estava checado anteriormente e nao era indeterminate*/
      if (this.checked && !this.indeterminate) {
        /*guardo que ele ja foi indeterminate e deixo o input como indeterminate*/
        this.indeterminate = true;
        this.inderterminateCheckbox.nativeElement.indeterminate = true;

        this.emitValue = false;
      } else {
        /*volta a ser uncheck e limpo as variaveis antigas*/
        this.value = null;
        this.checked = false;
        this.indeterminate = false;
        this.emitValue = null;
      }
    }


  }

  emitChange() {

    this.checkValue();

    this._onChange(this.emitValue);

  }

}
