import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SwitchCompanyService {

  show = new BehaviorSubject<boolean>(false);

  constructor() {
  }

  showService() {
    this.show.next(true);
  }

  hideService() {
    this.show.next(false);

  }


}
