import {Component, EventEmitter, Input, OnInit, Optional, Output, Self} from '@angular/core';
import {ControlValueAccessor, NgControl} from '@angular/forms';
import {hasRequiredField} from '../input-field/input-field.component';

@Component({
  selector: 'app-input-select',
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.scss']
})
export class InputSelectComponent implements ControlValueAccessor, OnInit {

  @Input() cssClass = '';
  @Input() id = '';
  @Input() label = '';
  @Input() disable = false;
  // chave referente a option do objeto
  @Input() optionKey = null;
  // chave referente ao value do select
  @Input() valueKey = null;
  // Lista de items do select
  @Input() selectList = [];
  // Caso necessário recebe uma função custom de compareFn
  @Input() compareCustom = null;
  @Input() customErrorMessage = null;

  // para mostrar o primeiro option como null
  @Input() showNullOption = true;
  @Input() showError = true;

  // Icon popover
  @Input() enablePopover = false;
  @Input() contentpopover = '';
  @Input() titlePopover = '';

  control;

  @Output() iconClickEvent = new EventEmitter();
  isRequired = false;
  private innerValue: any;

  // Usado para mostrar se o formulário possui erro ou não
  @Input() formSubmitted = false;

  constructor(
    @Self() @Optional() public controlDirective: NgControl) {
    controlDirective.valueAccessor = this;
  }

  ngOnInit() {
    this.control = this.controlDirective.control;
    this.isRequired = hasRequiredField(this.control);
    // quando tem qualquer mudança de status verifico se tem campo obrigatorio para trocar a label
    this.control.statusChanges.subscribe(() => {
      this.isRequired = hasRequiredField(this.control);
    });
  }

  get value() {
    return this.innerValue;
  }

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCb(v);
    }
  }


  iconClick() {
    this.iconClickEvent.emit('');
  }

  onChangeCb: (_: any) => void = () => {
  };
  onTouchedCb: (_: any) => void = () => {
  };

  writeValue(v: any): void {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCb(v);
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }


}
