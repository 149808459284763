
import {RouteInfo} from '../vertical-menu/vertical-menu.metadata';
import {TargetResourceEnum} from '../enums/targetResourceEnum';

export const HROUTES: RouteInfo[] = [

    // {
    //   path: '', title: 'Dashboard', icon: 'ft-home', class: 'has-sub', badge: '' /*2*/, badgeClass: '' /*badge badge-pill badge-danger float-right mr-1 mt-1*/, isExternalLink: false, submenu: [
    //     { path: '/dashboard/dashboard1', title: 'Dashboard 1', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    //     { path: '/dashboard/dashboard2', title: 'Dashboard 2', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    //   ]
    // },

    // {
    //     path: 'dashboard1',
    //     title: 'Dashboard',
    //     icon: 'ft-grid',
    //     class: 'nav-item',
    //     badge: '' /*2*/,
    //     badgeClass: '' /*badge badge-pill badge-danger float-right mr-1 mt-1*/,
    //     isExternalLink: false,
    //     submenu: []
    // },
    {
      path: 'dashboard',
      title: 'Dashboard',
      icon: 'ft-pie-chart',
      class: 'nav-item',
      badgeClass: '' /*badge badge-pill badge-danger float-right mr-1 mt-1*/,
      isExternalLink: false,
      submenu: [],
      targetResource: TargetResourceEnum.DASHBOARD,
    },
    {
        path: 'cupons-desconto',
        title: 'CUPONS DE DESCONTO',
        icon: 'ft-tag',
        class: 'nav-item',
        badgeClass: '' /*badge badge-pill badge-danger float-right mr-1 mt-1*/,
        isExternalLink: false,
        submenu: [],
        targetResource: TargetResourceEnum.CUPONS_DESCONTO,
    },
    {
        path: 'controle-planos',
        title: 'CONTROLE DE PLANOS',
        icon: 'ft-dollar-sign',
        class: 'nav-item',
        isExternalLink: false,
        submenu: [],
        targetResource: TargetResourceEnum.CONTROLE_PLANOS,
    },
    {
        path: 'planos-de-compra',
        title: 'PLANOS DE COMPRA',
        icon: 'ft-dollar-sign',
        class: 'nav-item',
        isExternalLink: false,
        submenu: [],
        targetResource: TargetResourceEnum.PLANOS_DE_COMPRA,

    },
    {
        path: 'empresas',
        title: 'Empresas',
        icon: 'ft-layers',
        class: 'nav-item',
        badgeClass: '' /*badge badge-pill badge-danger float-right mr-1 mt-1*/,
        isExternalLink: false,
        submenu: [],
        targetResource: TargetResourceEnum.EMPRESAS,
    },
    {
        path: 'advogados',
        title: 'ADVOGADOS',
        icon: 'ft-briefcase',
        class: 'nav-item',
        badgeClass: '' /*badge badge-pill badge-danger float-right mr-1 mt-1*/,
        isExternalLink: false,
        submenu: [],
        targetResource: TargetResourceEnum.ADVOGADOS,
    },
    {
        path: 'vagas',
        title: 'Vagas',
        icon: 'ft-clipboard',
        class: 'nav-item',
        badgeClass: '' /*badge badge-pill badge-danger float-right mr-1 mt-1*/,
        isExternalLink: false,
        submenu: [],
        targetResource: TargetResourceEnum.VAGAS,
    },
    {
        path: 'formacoes-academicas',
        title: 'FORMAÇÃO ACADÊMICA',
        icon: 'ft-award',
        class: 'nav-item',
        badgeClass: '' /*badge badge-pill badge-danger float-right mr-1 mt-1*/,
        isExternalLink: false,
        submenu: [],
        targetResource: TargetResourceEnum.FORMACOES_ACADEMICAS,
    },
    {
        path: 'competencias',
        title: 'Competências',
        icon: 'ft-star',
        class: 'nav-item',
        badgeClass: '' /*badge badge-pill badge-danger float-right mr-1 mt-1*/,
        isExternalLink: false,
        submenu: [],
        targetResource: TargetResourceEnum.COMPETENCIAS,
    },
    {
        path: 'areas-atuacao',
        title: 'ÁREAS DE ATUAÇÃO',
        icon: 'ft-book-open',
        class: 'nav-item',
        badgeClass: '' /*badge badge-pill badge-danger float-right mr-1 mt-1*/,
        isExternalLink: false,
        submenu: [],
        targetResource: TargetResourceEnum.AREAS_ATUACAO,
    },
    {
        path: 'idiomas',
        title: 'IDIOMAS',
        icon: 'ft-globe',
        class: 'nav-item',
        badgeClass: '' /*badge badge-pill badge-danger float-right mr-1 mt-1*/,
        isExternalLink: false,
        submenu: [],
        targetResource: TargetResourceEnum.IDIOMAS,
    },


    {
        path: 'usuarios-judclass',
        title: 'Usuários',
        icon: 'ft-users',
        class: 'nav-item',
        badgeClass: '' /*badge badge-pill badge-danger float-right mr-1 mt-1*/,
        isExternalLink: false,
        submenu: [],
        targetResource: TargetResourceEnum.USUARIOS_JUDCLASS,
    },

    {
        path: 'controle-acessos',
        title: 'Controle de acessos',
        icon: 'ft-sliders',
        class: 'nav-item has-sub',
        badgeClass: '' /*badge badge-pill badge-danger float-right mr-1 mt-1*/,
        isExternalLink: false,
        submenu: [
            {
                path: '/controle-acessos/usuarios',
                title: 'Usuários',
                icon: 'ft-arrow-right submenu-icon',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                targetResource: TargetResourceEnum.USUARIO,
            },
            {
                path: '/controle-acessos/grupos-permissoes',
                title: 'Grupos / Perfis',
                icon: 'ft-arrow-right submenu-icon',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                targetResource: TargetResourceEnum.PERFIL_USUARIO,
            }
        ]
    },
    {
        path: 'system',
        title: 'System',
        icon: 'ft-settings',
        class: 'nav-item has-sub',
        badgeClass: '' /*badge badge-pill badge-danger float-right mr-1 mt-1*/,
        isExternalLink: false,
        submenu: [
            {
                path: 'system/grants',
                title: 'Grants',
                icon: 'ft-arrow-right submenu-icon',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                targetResource: TargetResourceEnum.SYSTEM
            }
        ]
    },
];
