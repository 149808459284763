import {Component, EventEmitter, Input, OnInit, Optional, Output, Self, ViewEncapsulation} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NgControl} from '@angular/forms';

import {NgbDatePtParserFormatterService} from '../../../core/services/ngb-date-pt-parser-formatter.service';
import {UtilsService} from '../../../core/services/utils.service';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';

// const DATE_PICKER_VALUE_ACESSOR: any = {
//   provide: NG_VALUE_ACCESSOR,
//   useExisting: forwardRef(() => DatePickerComponent),
//   multi: true
// };

export const hasRequiredField = (abstractControl: AbstractControl): boolean => {
  if (abstractControl.validator) {
    const validator = abstractControl.validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
    return false;
  }
};

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss', './../../../../assets/sass/libs/datepicker.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DatePickerComponent implements ControlValueAccessor, OnInit {

  @Input() cssClass = '';
  @Input() id = '';
  @Input() label = '';
  @Input() placeholder = '';
  @Input() dateMinDate = null;
  @Input() dateMaxDate = null;
  @Input() readOnly = false;
  @Input() disabled = false;
  @Input() minToday = false;
  @Input() maxToday = false;
  @Input() customErrorMessage = null;
  @Input() placement = 'bottom-left bottom-right top-left top-right';
  @Input() showError = true;
  
  // Função para desabilitar alguns dias especificos da data
  @Input() isDisabled = (date: NgbDate, current: { month: number }) => false;
  @Output() navigate = new EventEmitter<any>();

  control;
  isRequired = false;
  dateModel = {};
  today;

  // Usado para mostrar se o formulário possui erro ou não
  @Input() formSubmitted = false;

  constructor(
    @Self() @Optional() public controlDirective: NgControl,
    public ngbDatePtParserFormatterService: NgbDatePtParserFormatterService,
    private utilsService: UtilsService) {
    controlDirective.valueAccessor = this;
  }

  ngOnInit() {
    this.control = this.controlDirective.control;
    this.setToday();
    this.checkToday();
    this.isRequired = hasRequiredField(this.control);

    /*Para adicionar validadores já existentes*/
    if (this.control.validator) {
      this.control.setValidators([this.utilsService.ptDate.bind(this), this.control.validator]);
    } else {
      this.control.setValidators([this.utilsService.ptDate.bind(this)]);
    }
    this.control.updateValueAndValidity();

    // quando tem qualquer mudança de status verifico se tem campo obrigatorio para trocar a label
    this.control.statusChanges.subscribe(() => {
      this.isRequired = hasRequiredField(this.control);
    });


  }

  onChangeCb: (_: any) => void = () => {
  };
  onTouchedCb: (_: any) => void = () => {
  };

  writeValue(v: any): void {
    if (v) {
      this.dateModel = v;
      // this.dateModel = this.ngbDatePtParserFormatterService.fromModelDate(v, true);
    } else {
      this.dateModel = null;
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  emitDate(event) {
    // console.log(event);
    if (event) {
      this.onChangeCb(event);
      // this.onChangeCb(this.ngbDatePtParserFormatterService.getDate(event));
    } else {
      this.onChangeCb(null);
    }
  }

  checkToday() {
    if (this.minToday) {
      this.dateMinDate = this.today;
    } else if (this.maxToday) {
      this.dateMaxDate = this.today;
    }
  }

  setToday() {
    const today = new Date();
    this.today = this.utilsService.formatToDatePicker(today);
  }

}

