<div class="modal-header">
  <h3 class="modal-title">{{title | translate}}</h3>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
<!--    <app-feather-icons [icon]="'x'"></app-feather-icons>-->
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>{{description | translate}}</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="activeModal.close()">{{textClose | translate}}</button>
  <button type="button" class="btn btn-secondary" *ngIf="textDismiss" (click)="activeModal.dismiss()">{{ textDismiss | translate }}</button>
</div>

