import {Injectable} from '@angular/core';


@Injectable({
  providedIn: 'root'
})

export class I18n {

  language = 'pt';
}
