<div class="card">
	<form [formGroup]="form" class="theme-form">
		<div class="card-content">
			<div class="card-body">
				<div class="mb-5">
					<div class="title mb-4"><h5 [innerHTML]="'Dados gerais' | translate" class="fs-18 text-black font-w600"></h5>
					</div>

					<div class="form-row">
						<app-input-select [selectList]="tiposDePessoa" optionKey="name"
										  valueKey="value"
										  class="col-md-4"
										  [id]="'tipo'"
										  [formSubmitted]="formSubmitted"
										  label="Tipo de pessoa" formControlName="tipo"></app-input-select>
					</div>

					<div class="form-row">
						<app-input-field *ngIf="form.get('tipo').value" class="col-md-4" [id]="'nome'"
										 [label]="form.get('tipo').value == pessoaTipoEnum.PJ ? 'Empresa' : 'Nome completo'"
										 formControlName="nome"
										 [formSubmitted]="formSubmitted"></app-input-field>

						<ng-container *ngIf="form.get('tipo').value == pessoaTipoEnum.PF">
							<app-input-field class="col-md-4" [id]="'cpf'" label="CPF"
											 mask="000.000.000-00"
											 maskValidation="true"
											 [customErrorMessage]="{invalidMask: 'CPF inválido'}"
											 [formSubmitted]="formSubmitted"
											 formControlName="cpf"></app-input-field>
						</ng-container>

						<ng-container *ngIf="form.get('tipo').value == pessoaTipoEnum.PJ">
							<!--<app-input-field class="col-md-4" [id]="'razaoSocial'" label="Razão social" formControlName="razaoSocial"
											 [formSubmitted]="formSubmitted"></app-input-field>-->
							<app-input-field class="col-md-4" [id]="'cnpj'" label="CNPJ"
											 mask="00.000.000/0000-00"
											 maskValidation="true"
											 [customErrorMessage]="{invalidMask: 'CNPJ inválido'}"
											 [formSubmitted]="formSubmitted"
											 formControlName="cnpj"></app-input-field>

						</ng-container>

						<app-input-image
								class="col-md-12"
								[imageSrc]="form.get('logoUrl').value"
								defaultImageSrc="../../assets/img/images/placeholder_logo.png"
								(onFileChange)="onFileChange($event)"
								(onFileReset)="onFileReset()"></app-input-image>

						<app-input-field
								class="col-md-6"
								[id]="'websiteUrl'"
								label="Website"
								formControlName="websiteUrl"
								[customErrorMessage]="{httpValidatorAcceptEmptyString: 'Digite http: // ou https: // no início do campo'}"
								[formSubmitted]="formSubmitted"></app-input-field>

						<app-input-field
								class="col-md-6"
								[id]="'linkedInUrl'"
								label="LinkedIn"
								formControlName="linkedInUrl"
								[customErrorMessage]="{httpValidatorAcceptEmptyString: 'Digite http: // ou https: // no início do campo'}"
								[formSubmitted]="formSubmitted"></app-input-field>

						<app-input-modal-form
								class="col-md-12"
								[selector]="selector"
								label="Endereço"
								[id]="'endereco'"
								[formSubmitted]="formSubmitted"
								formControlName="endereco" iconClass="ft-map-pin"
								[pipe]="addressPipe"></app-input-modal-form>

						<app-input-textarea
								class="col-md-12"
								maxLength="4000"
								formControlName="descricao"
								[id]="'descricao'"
								[formSubmitted]="formSubmitted"
								label="Apresentação"></app-input-textarea>
					</div>

					<div class="title mb-4"><h5 [innerHTML]="'Contato' | translate" class="mt-5 fs-18 text-black font-w600"></h5>
					</div>

					<div class="form-row">
						<!--<div formGroupName="contatos" class="col-md-6 mb-3">
							<div class="form-row box-grid-header">
								<div class="col-11">
									<label style="margin-right: auto">{{'Emails'|translate}}</label>
								</div>

								<div class="col-1">
									<a (click)="addEmail()" class="add" style="cursor: pointer;"><i class="fa fa-plus"></i></a>
								</div>
							</div>

							<div formArrayName="emails" class="form-row box-grid">
								<div class="no-records-founds"
									 *ngIf="form.get('contatos.emails')['controls'].length == 0">
									{{'Nenhum email cadastrado' | translate}}
								</div>

								<div class="input-group box-container"
									 *ngFor="let email of form.get('contatos.emails')['controls']; let i = index">

									<app-input-field
											class="col-11"
											[id]="'email_'+i"
											label="Email"
											[formSubmitted]="formSubmitted"
											[formControlName]="i"></app-input-field>

									<a (click)="deleteEmail(i)" class="col-1 remove" style="cursor: pointer;">
										<i class="fa fa-trash"></i>
									</a>
								</div>
							</div>
						</div>-->

						<div formGroupName="contatos" class="col-md-6 mb-3">
							<div class="form-row box-grid-header">
								<div class="col-11">
									<label style="margin-right: auto">{{'Telefones'|translate}}</label>
								</div>

								<div class="col-1">
									<a (click)="addPhone()" class="add" style="cursor: pointer;"><i class="fa fa-plus"></i></a>
								</div>
							</div>

							<div formArrayName="telefones" class="form-row box-grid">
								<div class="no-records-founds"
									 *ngIf="form.get('contatos.telefones')['controls'].length == 0">
									{{'Nenhum telefone cadastrado' | translate}}
								</div>

								<div class="input-group box-container"
									 *ngFor="let phone of form.get('contatos.telefones')['controls']; let i = index">

									<app-input-field
											class="col-11"
											mask="(00)0000-0000||(00)00000-0000"
											[id]="'phone_'+i"
											label="No. Telefone"
											[formSubmitted]="formSubmitted"
											[formControlName]="i"></app-input-field>

									<a (click)="deletePhone(i)" class="col-1 remove" style="cursor: pointer;"><i
											class="fa fa-trash"></i>
									</a>
								</div>
							</div>
						</div>
					</div>

					<div class="title mb-4"><h5 [innerHTML]="'Acesso ao sistema' | translate"
												class="mt-5 fs-18 text-black font-w600"></h5></div>
					<div class="row">
						<app-input-field
								class="col-md-6"
								[id]="'login'"
								label="Email de acesso"
								[formSubmitted]="formSubmitted"
								formControlName="login"></app-input-field>
					</div>

					<ngb-alert [dismissible]="false" *ngIf="form.get('id').value == null"
							   class="col-md-6">
						<h6 class="modal-title"
							[innerHTML]="'Um email será enviado para cadastro da senha de acesso ao sistema.' | translate"></h6>
					</ngb-alert>
				</div>
			</div>
		</div>

	</form>


	<div class="card-footer d-flex justify-content-end flex-wrap border-0">
		<div class="d-sm-flex d-block justify-content-end">
			<app-default-form-actions-button
					[editing]="form.get('id').value!=null"
					[target]="TargetResourceEnum.CONFIGURACOES_EMPRESA"
          [enableCancelButton] = "false"
					(onSubmit)="onSubmit()"
			></app-default-form-actions-button>
		</div>
	</div>
</div>



