import {Component, Input, OnInit} from '@angular/core';
import {VagaStatusEnumToNameService} from '../../enum-to-name-services/vaga-status-enum-to-name.service';

@Component({
    selector: 'app-vagas-list-status',
    templateUrl: './vagas-list-status.component.html',
    styleUrls: ['./vagas-list-status.component.scss']
})
export class VagasListStatusComponent implements OnInit {

    @Input() status = null;
    enumArray = [];

    constructor(private vagasStatusEnumToNameService: VagaStatusEnumToNameService) {
        this.enumArray = this.vagasStatusEnumToNameService.get()
    }

    ngOnInit(): void {
    }

}
