import {Injectable} from '@angular/core';
import {StorageService} from '../storage/storage.service';
import {FormGroup} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  key;

  constructor(private storageService: StorageService) {
  }

  init(key) {
    this.key = key;
  }

  get(form: FormGroup) {
    let object = form.getRawValue();
    if (this.storageService.check(this.key)) {
      Object.assign(object, this.storageService.get(this.key));
    }
    return object;
  }

  set(filters) {
    this.storageService.set(this.key, filters);
  }

  clear() {
    this.storageService.remove(this.key);
  }
}
