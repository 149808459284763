import {ActivatedRoute, ActivatedRouteSnapshot, Params} from '@angular/router';
import {TargetResourceEnum} from '../../../shared/enums/targetResourceEnum';
import {ActionsEnum} from '../../../shared/enums/actions.enum';
import {CrudService} from '../../../core/services/crud.service';
import {AfterViewInit, Component, Inject, LOCALE_ID, OnInit, TemplateRef, ViewChild} from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  active = 1;
  isDisabled = true;
  ActionsEnum = ActionsEnum;
  TargetResourceEnum = TargetResourceEnum;

  constructor(private route: ActivatedRoute) {
  }






  ngOnInit(): void {
      this.route.params.subscribe((param: Params) => {
          if (param['id']) {
              if (typeof this.route.snapshot.data['vagas/dashboard'] == 'undefined') {
                  this.isDisabled = false;
              }

          }
      });

  }


}
