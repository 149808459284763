import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';

import {Observable} from 'rxjs';
import {AbstractGuard} from '../../core/abstracts/guard.abstract.';
import {PermissionService} from '../../shared/services/permission.service';


@Injectable({
  providedIn: 'root'
})
export class RoleGuard extends AbstractGuard {
  constructor(private permissionService: PermissionService,
              private router: Router) {
    super();
  }


  guard(activatedRouter: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Observable<boolean> | boolean {
    if (activatedRouter.data.actions) {
      if (this.permissionService.hasPermission(activatedRouter.data.targetResource, activatedRouter.data.actions)) {
        return true;
      }
    } else if (this.permissionService.hasPermission(activatedRouter.data.targetResource)) {
      return true;
    }


    this.router.navigate(['/']);
    return false;

  }


}
