<div class="modal-header">
  <h3 class="modal-title"
      [innerHTML]=" title |translate"></h3>

  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <app-feather-icons [icon]="'x'"></app-feather-icons>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form" class="theme-form" (ngSubmit)="onSubmit()">
    <div class="form-row">

      <p class="action-confirm-modal"
         [innerHTML]="confirmText|translate:{CONFIRM_TEXT:code}"></p>


      <app-input-field class="col-md-12 action-confirm-modal" [id]="'userKey'" [placeholder]="code"
                       formControlName="userKey"
                       [formSubmitted]="formSubmitted"
                       [customErrorMessage]="{notSame: 'O valor deve ser o mesmo'}"></app-input-field>

    </div>

  </form>
</div>
<div class="modal-footer">
  <button type="submit" class="btn {{btnClass}} mr-1" ngbAutofocus
          (click)="onSubmit()">
    {{buttonLabel| translate}}
  </button>
  <button class="btn btn-secondary" (click)="activeModal.dismiss()">{{'Cancelar' | translate}}</button>
</div>
