import {Injectable} from '@angular/core';
import {AreaAtuacaoStatusEnum} from '../enums/area-atuacao-status.enum';

@Injectable({
    providedIn: 'root'
})

export class AreaAtuacaoStatusEnumToNameService {
    constructor() {
    }

    get() {
        return [
            {name: 'Habilitada', value: AreaAtuacaoStatusEnum.HABILITADA},
            {name: 'Desabilitada', value: AreaAtuacaoStatusEnum.DESABILITADA},
        ];
    }
}
