import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class BreadcrumbRouteService {
   data = new BehaviorSubject({breadcrumb: [], title: ''});

   setData(data) {
       this.data.next(data);
   }
}
